<div class="min-h-full px-4">
    <header>
        <k5-responsive-title>
            <k5-page-header header="Meldewesen - Konfiguration" icon="fa-duotone fa-sliders-h fg-primary">
            </k5-page-header>
        </k5-responsive-title>
    </header>
    <main>
        <k5-section-header [title]="'Datenmigration'" icon="fa-upload"></k5-section-header>
    </main>
</div>
