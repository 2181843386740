/**
 * Status für die Anzeige des Schrittes im UI
 */
export enum UIWahlstatusStepState {
    // nicht gestartet
    NotStarted = 0,
    // nächster Schritt
    Current = 1,
    // wird aktuell durchgeführt
    InProgress = 2,
    // fehlerhaft durchgeführt
    Failure = 3,
    // fertig
    Completed = 4,
    // Meilenstein in der Abwicklung der Wahlvorbereitung
    Milestone = 5
}

export enum WahlbezugsadresseEnum {
    Hauptwohnsitz = 0,
    Nebenwohnsitz = 1,
    Wahlbezugsadresse = 2,
    Sprengeladresse = 3,
    Obdachlos = 4
}

export enum ZewaerAktionEnum {
    // Klärungsfall übermittelt
    Klaerungsfall = 0,
    // Wahlkarte erstellt oder storniert
    Wahlkarte = 1,
    // Wähler gelöscht aus dem Wählerverzeichnis
    WaehlerGeloescht = 2,
    // Wähler verstorben
    WaehlerVerstorben = 3,
    // Wähler nachträglich ins Wählerverzeichnis reklamiert
    WaehlerHineinreklamiert = 4,
    // Personendaten korrigiert (mit dem ZMR abgeglichen)
    PersonendatenKorrigiert = 5,
    //  Hineinreklamierte Person wurde bei der Neu-Erstellung des Wahlbestandes einsortiert und als 'Amtliche Korrektur' ans ZeWaeR übermittelt
    AmtlicheKorrektur = 6,
    // Korrektur der Sprengelnummer. Wenn sich Sprengelnummern bei den Wählern nach dem Import des
    // Wahlbestandes ändern, müssen die neuen Sprengelnummern an das ZeWaeR übermittelt werden.
    KorrekturSprengelnummer = 7,
    // Laden des QR-Codes für die Hauskundmachung aus dem ZeWaeR
    Hauskundmachung = 8
}

export enum ErledigungscodeEnum {
    // Übermittlung noch nicht erledigt. Die Übertragung kann/muss erneut angestoßen werden.
    Offen = 0,
    // Erfolgreiche Übertragung ans ZeWaeR
    Uebertragen = 1,
    // manuell Erledigt (Übertragung war nicht erfolgreich, wurde von einem Benutzer aber über das UI auf Erledigt gesetzt)
    Manuell = 2
}

/**
 * Filter nach Status für die Abfrage von ZeWaeR-Übermittlungen
 */
export enum ZewaerUebermittlungStatusFilter {
    /**
     * Übermittlung noch nicht erledigt. Die Übertragung kann/muss erneut angestoßen werden
     */
    Offen,

    /**
     * Übermittlung fehlerhaft. Die Übertragung kann/muss erneut angestoßen werden
     */
    Fehlerhaft,

    /**
     * Erfolgreiche Übertragung ans ZeWaeR
     */
    Erfolgreich
}

/**
 * Filter nach Aktion für die Abfrage von ZeWaeR-Übermittlungen
 */
export enum ZewaerUebermittlungAktionFilter {
    /**
     * Wahlkarte erstellt oder storniert
     */
    Wahlkarte,

    /**
     * Korrektur der Sprengelnummer
     * @remarks
     * Wenn sich Sprengelnummern bei den Wählern nach dem Import des Wahlbestandes ändern,
     * müssen die neuen Sprengelnummern an das ZeWaeR übermittelt werden.
     */
    KorrekturSprengelnummer,

    /**
     * Klärungsfall übermittelt
     */
    Klaerungsfall,

    /**
     * Personendaten korrigiert (mit dem ZMR abgeglichen)
     */
    PersonendatenKorrigiert,

    /**
     * Amtliche Korrektur
     * @remarks
     * Hineinreklamierte Person wurde bei der Neu-Erstellung des Wahlbestandes
     * einsortiert und als 'Amtliche Korrektur' ans ZeWaeR übermittelt.
     */
    AmtlicheKorrektur,

    /**
     * Person reklamiert
     * @remarks
     * - Filtert folgende Aktionen:
     *   - {@link ZewaerAktionEnum.WaehlerHineinreklamiert}
     *   - {@link ZewaerAktionEnum.WaehlerGeloescht}
     */
    PersonReklamiert,

    /**
     * Person verstorben
     */
    PersonVerstorben
}

export enum RegisterStatusEnum {
    /**
     * Konfiguration unvollständig (PV-User, GKZ, Behörden-KZ)
     */
    FehlendeDaten,
    /**
     * Fehler vom Portal (z.B. keine Rechte am Portal,..)
     */
    PortalFehler,
    /**
     * Fehler vom aufgerufenen Register
     */
    RegisterFehler,
    /**
     * Technischer Fehler (z.B. Azure Konfiguration passt nicht, Service nicht erreichbar,..)
     */
    TechnischerFehler,
    /**
     * Keine übereinstimmende Wahl im ZeWaeR gefunden
     */
    KeineWahl,
    /**
     * keine Fehler aufgetreten - Zugriff funktioniert
     */
    OK
}

/**
 * Enum mit den möglichen Begründungen für einen Wahlkartenantrag
 */
export enum Begruendung {
    Ortsabwesenheit = 0,

    Gesundheitlich = 1,

    Auslandsaufenthalt = 2,

    UnterbringungHaftanstalt = 4
}

/**
 * Enum mit den möglichen Status eines Wahlkartenantrags
 * Für Auswertungen über einen Filter gilt folgende Einteilung:
 *      Status 0-9: Der Wahlkartenantrag wurde noch nicht entschieden
 *      Status 10-19: Der Wahlkartenantrag wurde positiv entschieden
 *      Status 20-29: Der Wahlkartenantrag wurde negativ entschieden
 */
export enum AntragStatus {
    /**
     * Status für einen neu erstellten Antrag
     */
    Neu = 0,

    /**
     * Status wenn der Antrag vom Bearbeiter als zur Bearbeitung gekennzeichnet wurde
     */
    BearbeitungVorgemerkt = 1,

    /**
     * Status wenn der Antrag genehmigt wurde
     */
    Uebernommen = 10,

    /**
     * Status wenn der Antrag vom Antragsteller zurückgezogen wurde
     */
    Zurueckgezogen = 20,

    /**
     * Status wenn der Antrag abgelehnt wurde
     */
    Abgelehnt = 21,

    /**
     * Status wenn beim Wähler bereits eine Wahlkarte eingetragen ist
     */
    Mehrfach = 22
}

/**
 * Enum für den Status-Filter der Wahlkartenanträge
 */
export enum StatusFilter {
    /**
     * keine Einschränkung
     */
    Alle,

    /**
     * Wahlkartenanträge die noch bearbeitet werden müssen
     */
    Offen,

    /**
     * Genehmigte Wahlkartenanträge
     */
    Genehmigt,

    /**
     * Abgelehnte Wahlkartenanträge
     */
    Abgelehnt,

    /**
     * Zurückgezogene Wahlkartenanträge
     */
    Zurueckgezogen,

    /**
     * Mehrfach Wahlkartenanträge
     */
    Mehrfach
}
