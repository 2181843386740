import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/**
 * Basis Komponente für alle Widgets, inklusive Header und Aktionen (remove, edit)
 */
@Component({
    selector: 'k5-widget',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule],
    templateUrl: './widget.component.html'
})
export class WidgetComponent {
    @Input()
    title: string | null = null;

    @Input()
    showDelete: boolean = false;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }
}
