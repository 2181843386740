<div class="action-item pt-md pb-md pl-sm pr-sm flex h-full flex-col items-center gap-1">
    <div *ngIf="icon && !loading">
        <i class="fa-duotone fg-gray fa-2x action-icon" [ngClass]="icon"></i>
    </div>
    <div *ngIf="(firstName || lastName) && !loading">
        <k5-avatar-photo [firstName]="firstName" [lastName]="lastName"> </k5-avatar-photo>
    </div>
    <div *ngIf="loading">
        <k5-loading [diameter]="32"></k5-loading>
    </div>

    <span class="break-word fs-sm text-center">{{ description }}</span>
</div>
