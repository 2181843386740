import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalEditService } from '@core/services/global-edit.service';
import { Icon } from '@shared/models/icon';

@Component({
    selector: 'k5-editable-list-item',
    templateUrl: './editable-list-item.component.html',
    styleUrls: ['./editable-list-item.component.scss']
})
export class EditableListItemComponent {
    @Input()
    icon = '';

    @Input()
    href: string | null = null;

    @Input()
    tooltip: string | null = null;

    @Input()
    iconTooltip: string = '';

    @Input()
    isStandard = false;

    @Input()
    content = '';

    @Input()
    addon: string | null = null;

    /**
     * Icon, das an den Content angehängt wird.
     */
    @Input()
    iconAfterContent: Icon | null = null;

    // Edit

    @Input()
    showEdit = true;

    @Input()
    disableEdit: boolean = false;

    @Input()
    editTooltip: string = 'Bearbeiten';

    // Info

    @Input()
    showInfo = false;

    @Input()
    disableInfo = false;

    @Input()
    infoTooltip: string = 'Details';

    // Delete

    @Input()
    showDelete = true;

    @Input()
    disableDelete: boolean = false;

    @Input()
    deleteTooltip: string = 'Löschen';

    // Additional Action
    @Input()
    showAdditionalAction: boolean = false;

    @Input()
    disableAdditionalAction: boolean = false;

    @Input()
    additionalActionTooltip: string | null = null;

    @Input()
    additionalActionIcon: string | null = null;

    // Outputs
    @Output()
    editClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    deleteClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    additionalActionClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    infoClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private globalEditService: GlobalEditService) {}

    /**
     * Benachrichtigt Eltern Komponente wenn delete aufgerufen wurde
     */
    deleteItem(): void {
        this.deleteClick.emit(true);
    }

    /**
     * Benachrichtigt Eltern Komponente wenn edit aufgerufen wurde
     */
    editItem(): void {
        this.editClick.emit(true);
    }

    /**
     * Sendet ein Event beim Button Klick auf die zusätzliche Aktion
     */
    additionalAction(): void {
        this.additionalActionClick.emit(true);
    }

    /**
     * Benachrichtigt Eltern Komponente wenn 'Detailinformationen anzeigen' aufgerufen wurde
     */
    clickInfo(): void {
        this.infoClick.emit(true);
    }

    /**
     * Gibt zurück, ob die Action Buttons dargestellt werden sollen
     */
    get actionButtonsVisible() {
        return !this.globalEditService.isEditing;
    }
}
