import { Pipe, PipeTransform } from '@angular/core';
import { WahlkartenStatusEnum } from '@shared/models/enums';

/**
 * Konvertiert die Enum-Werte des Typs WahlkartenStatus
 * in eine passende textuelle Darstellung für das UI.
 */
@Pipe({
    name: 'wahlkartenStatus'
})
export class WahlkartenStatusPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value WahlkartenStatus
     */
    transform(value: WahlkartenStatusEnum): string {
        switch (value) {
            case WahlkartenStatusEnum.Keine:
                return 'Keine Wahlkarte';
            case WahlkartenStatusEnum.Beantragt:
                return 'Wahlkarte beantragt';
            case WahlkartenStatusEnum.WahlkarteGedruckt:
                return 'Wahlkarte gedruckt';
            case WahlkartenStatusEnum.EtikettGedruckt:
                return 'Etikett gedruckt';
            case WahlkartenStatusEnum.RSaEtikettGedruckt:
                return 'RSa-Etikett gedruckt';
            case WahlkartenStatusEnum.RSbEtikettGedruckt:
                return 'RSb-Etikett gedruckt';
            case WahlkartenStatusEnum.Versendet:
                return 'Aufgabeliste Post gedruckt';
        }
    }
}
