import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'k5-dashboard',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent {}
