// @ts-strict-ignore
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProblemResponse } from '@core/models/problemResponse';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { EditSectionConstants, Staatsangehoerigkeiten } from '@shared/constants/shared-constants';
import { DialogResult } from '@shared/models/dialogResult';
import { Staat } from '@shared/models/staat';
import { StaatenResponse } from '@shared/models/staatenResponse';
import { Strasse } from '@shared/models/strasse';
import { StrasseResponse } from '@shared/models/strasseResponse';
import { Zustelladresse } from '@shared/models/zustelladresse';
import { AdressenService } from '@shared/services/adressen.service';
import { FormErrorMessageService } from '@shared/services/form-error-message.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'k5-zustelladresse-dialog',
    templateUrl: './zustelladresse-dialog.component.html'
})
export class ZustelladresseDialogComponent implements OnInit, OnDestroy {
    @Input()
    content: string;

    @Input()
    action: string = 'Hinzufügen';

    @Output()
    dialogAccepted = new EventEmitter<DialogResult<Zustelladresse | null>>();

    isLoading: boolean = true;

    staatList: Staat[] = [];

    strasseList: Strasse[] = [];
    selectedStrasse: Strasse = {};

    /**
     * Die Suche nach Straßen bei einer österreichischen Adresse darf nur mit
     * gewissen vorraussetzungen durchgeführt werden. Das Flag strasseSearchable zeigt
     * an, ob eine Straßensuche erlaubt ist oder nicht
     */
    strasseSearchable: boolean = true;

    public zustelladresseDialogForm: FormGroup;
    public zustelladresseFormAt: FormGroup;
    public zustelladresseForm: FormGroup;

    // Flag, ob weitere Anschriftszeilen angezeigt werden sollen
    weitereAdresszeilen: boolean = false;

    subscriptions = new Subscription();

    editModeOpenBefore = false;

    saveButtonDisabled: boolean = false;
    heading: string = 'Zustelladresse anlegen';

    constructor(
        private adressenService: AdressenService,
        public formErrorMessageService: FormErrorMessageService,
        private alertService: AlertService,
        private globalEditService: GlobalEditService,
        @Inject(MAT_DIALOG_DATA) public data: { zustelladresse: Zustelladresse }
    ) {
        this.editModeOpenBefore = this.globalEditService.isEditing;
        if (!this.globalEditService.isEditing) {
            this.globalEditService.switchToEditMode(EditSectionConstants.SHARED_ZUSTELLADRESSE);
        }
        this.initializeForms();
    }

    ngOnInit(): void {
        /**
         * Konkatenierte Subscriptions
         */
        this.subscriptions.add(
            this.adressenService
                .getStaaten()
                .pipe(
                    // Behandlung der Fehler bei der Abfrage der Staaten
                    catchError((error: ProblemResponse): Observable<StaatenResponse> => {
                        this.alertService.error('Es ist ein Fehler aufgetreten', error?.detail);
                        return of(null);
                    }),
                    // Wechseln vom Staaten-Observable auf die Wertveränderungen der Straße-Hausnr
                    switchMap((staatenResponse: StaatenResponse): Observable<string> => {
                        // Verarbeitung der Staaten
                        if (staatenResponse?.staaten?.length > 0) {
                            // Es werden nur Staaten mit isO3 Eintrag verwendet
                            this.staatList = staatenResponse.staaten.filter((staat) => staat.isO3);
                            this.initializeFormData();
                        }
                        this.isLoading = false;
                        return this.zustelladresseFormAt.get('strasseHausnr').valueChanges;
                    }),
                    debounceTime(250),
                    distinctUntilChanged(),
                    // Ist der Input valide, wird eine österreichweite Strassensuche getriggert
                    filter((input: string) => {
                        this.strasseSearchable = this.validateInput(input);
                        return this.strasseSearchable;
                    }),
                    switchMap((input: string): Observable<StrasseResponse> => {
                        this.selectedStrasse = null;
                        return this.adressenService.getStrassen(input);
                    })
                )
                .subscribe({
                    next: (strasseResponse: StrasseResponse) => {
                        if (strasseResponse?.strassen?.length > 0) {
                            this.strasseList = strasseResponse.strassen;
                        }
                    },
                    error: (error: ProblemResponse) => {
                        this.alertService.error('Es ist ein Fehler aufgetreten', error?.detail);
                    }
                })
        );

        this.subscriptions.add(
            this.zustelladresseFormAt.valueChanges.subscribe({
                next: () => {
                    if (this.saveButtonDisabled && this.zustelladresseFormAt.dirty) {
                        this.saveButtonDisabled = false;
                    }
                }
            })
        );

        this.subscriptions.add(
            this.zustelladresseForm.valueChanges.subscribe({
                next: () => {
                    if (this.saveButtonDisabled && this.zustelladresseForm.dirty) {
                        this.saveButtonDisabled = false;
                    }
                }
            })
        );
    }

    ngOnDestroy(): void {
        if (!this.editModeOpenBefore) {
            this.globalEditService.switchToReadMode();
        }
        this.subscriptions.unsubscribe();
    }

    /**
     * Returniert den aktuell ausgewählten Staat
     */
    get selectedStaat(): Staat {
        return this.zustelladresseDialogForm.get('staatAuswahl').value;
    }

    /**
     * Gibt an, ob ein Staat selektiert ist
     */
    get isStaatSelected(): boolean {
        return !!this.selectedStaat;
    }

    /**
     * Gibt an, ob es sich bei der Adresse um eine Inlandsadresse handelt
     */
    get isInlandAdresse(): boolean {
        return this.isStaatSelected && this.selectedStaat.isO3 === Staatsangehoerigkeiten.OESTERREICH_ISO3;
    }

    /**
     * Initialisiert die benötigten FormGroups
     */
    initializeForms(): void {
        this.zustelladresseDialogForm = new FormGroup({
            staatAuswahl: new FormControl<string>(null, Validators.required)
        });

        this.zustelladresseFormAt = new FormGroup({
            adresszeile1: new FormControl<string>(''),
            strasseHausnr: new FormControl<string>('', Validators.required),
            plzOrt: new FormControl<string>('', Validators.required)
        });

        this.zustelladresseForm = new FormGroup(
            {
                anschriftszeile1: new FormControl<string>(''),
                anschriftszeile2: new FormControl<string>(''),
                anschriftszeile3: new FormControl<string>(''),
                anschriftszeile4: new FormControl<string>(''),
                anschriftszeile5: new FormControl<string>(''),
                anschriftszeile6: new FormControl<string>('')
            },
            {
                validators: this.minimalAdresslinesFilledValidation()
            }
        );
    }

    /**
     * Befüllt die Forms mit den initialen Daten der übergebenen zustelladresse
     */
    private initializeFormData(): void {
        // Gibt es eine Zustelladresse, so wird die Form initial befüllt
        if (this.data?.zustelladresse) {
            // Prüfung auf österreichische Zustelladressen
            if (
                !this.data.zustelladresse.zA_Staat ||
                this.data.zustelladresse.zA_Staat === Staatsangehoerigkeiten.OESTERREICH_ISO3
            ) {
                // Sind nur die ersten zwei Anschriftszeilen befüllt, so wird dies separat behandelt
                if (
                    this.data.zustelladresse.zA_Anschriftszeile1 &&
                    this.data.zustelladresse.zA_Anschriftszeile2 &&
                    !this.data.zustelladresse.zA_Anschriftszeile3
                ) {
                    /**
                     * vorbefüllen, wenn nur 2 Anschriftszeilen befüllt sind und Anschriftszeile 3 leer ist
                     *
                     * Wird eine österreichische Zustelladresse nicht initial vom Bürgerservice erfasst, kann es vorkommen,
                     * dass StrasseHausnr in Anschritszeile1 und PlzOrt in Anschriftszeile 2 gespeichert werden
                     * Bürgerservice erwartet StrasseHausnr in Anschriftszeile2 und PlzOrt in Anschriftszeile3
                     */
                    this.zustelladresseFormAt.patchValue({
                        adresszeile1: '',
                        strasseHausnr: this.data.zustelladresse.zA_Anschriftszeile1,
                        plzOrt: this.data.zustelladresse.zA_Anschriftszeile2
                    });
                } else {
                    // Die ersten drei Anschriftszeilen werden standardmäßig bei österreichischen Adressen verwendet
                    this.zustelladresseFormAt.patchValue({
                        adresszeile1: this.data.zustelladresse.zA_Anschriftszeile1,
                        strasseHausnr: this.data.zustelladresse.zA_Anschriftszeile2,
                        plzOrt: this.data.zustelladresse.zA_Anschriftszeile3
                    });
                }

                // Straße darf nur gesucht werden, wenn noch kein Eintrag für die Anschriftszeile 2 der Zustelladresse vorhanden ist
                this.strasseSearchable = !this.data.zustelladresse.zA_Anschriftszeile2;

                // Wenn die Form gültig ist, kann davon ausgegangen werden, dass es sich um eine bestehende Adresse handelt, welche bearbeitet wird
                if (this.zustelladresseFormAt.valid) {
                    this.saveButtonDisabled = true;
                    this.heading = 'Zustelladresse bearbeiten';
                }
            } else {
                // vorbefüllen Zustelladresse Ausland
                this.zustelladresseForm.patchValue({
                    anschriftszeile1: this.data.zustelladresse.zA_Anschriftszeile1,
                    anschriftszeile2: this.data.zustelladresse.zA_Anschriftszeile2,
                    anschriftszeile3: this.data.zustelladresse.zA_Anschriftszeile3,
                    anschriftszeile4: this.data.zustelladresse.zA_Anschriftszeile4,
                    anschriftszeile5: this.data.zustelladresse.zA_Anschriftszeile5,
                    anschriftszeile6: this.data.zustelladresse.zA_Anschriftszeile6
                });

                // Ist eine Anschriftszeile > 3 befüllt, so müssen alle Adresszeilen angezeigt werden
                if (
                    this.data.zustelladresse.zA_Anschriftszeile4 ||
                    this.data.zustelladresse.zA_Anschriftszeile5 ||
                    this.data.zustelladresse.zA_Anschriftszeile6
                ) {
                    this.weitereAdresszeilenAnzeigen();
                }
            }

            // Wenn die Form gültig ist, kann davon ausgegangen werden, dass es sich um eine bestehende Adresse handelt, welche bearbeitet wird
            if (this.zustelladresseForm.valid) {
                this.saveButtonDisabled = true;
                this.heading = 'Zustelladresse bearbeiten';
            }
        }
        this.zustelladresseDialogForm
            .get('staatAuswahl')
            .setValue(this.getSelectedStaat(this.data?.zustelladresse?.zA_Staat));
    }

    /**
     * Sucht den ausgewählten Staat aus der Liste an Staaten
     * Falls kein Staat übereinstimmt wird defaultmässig nach AUT gesucht
     * @param isO3 isO3 Code eines Staates
     * @returns aktuell selektierter Staat
     */
    getSelectedStaat(isO3: string): Staat {
        let state: Staat;

        if (isO3) {
            state = this.staatList.find((staat: Staat) => staat.isO3 === isO3);
        }
        // Standardmäßig Österreich auswählen
        else {
            state = this.staatList.find((staat: Staat) => staat.isO3 === Staatsangehoerigkeiten.OESTERREICH_ISO3);
        }

        return state;
    }

    /**
     * Befüllt die FormControls strasseHausnr und plzOrt, wenn eine Straße aus der StrassenSuche ausgewählt wird
     * Setzt strasseSearchable auf false, da die Strassensuche deavtiviert ist, wenn bereits eine ausgewählt wurde
     * Leert strasseList, da keine Auswahl mehr benötigt wird bis eine neue Suche getriggert wird
     * @param strasse Selektierte Strasse
     */
    selectStrasse(strasse?: Strasse): void {
        const setStrasse: string = strasse.strassenname;
        const setPlzOrt: string = strasse.plz + ' ' + strasse.zustellort;

        this.zustelladresseFormAt.patchValue({
            strasseHausnr: setStrasse,
            plzOrt: setPlzOrt
        });

        this.strasseSearchable = false;
        this.strasseList = [];
    }

    /**
     * Validiert, ob der Input der Adresse für eine Strassensuche in Österreich gültig ist
     * @param input Eingabe
     * @returns boolean, ob die Eingabe für eine Strassensuche gültig ist
     */
    validateInput(input: string): boolean {
        if (!input) {
            return false;
        }

        // Prüfung, ob der Staat im Form Österreich ist
        const isAUT =
            this.zustelladresseDialogForm.get('staatAuswahl').value.isO3 === Staatsangehoerigkeiten.OESTERREICH_ISO3;
        const splittetInputLeer = input.split(' ');
        const splittetInputBeistrich = input.split(',');
        // Validation: Nach einer Zahl müssen mindestens 2 Buchstaben folgen

        // Sollte hier noch auf 4-stellig bei number geprüft werden, da es ja einen PLZ sein sollte?
        if (
            Number(input) ||
            (Number(splittetInputLeer[0].trim()) && splittetInputLeer[1]?.trim().length < 2) ||
            (Number(splittetInputBeistrich[0].trim()) && splittetInputBeistrich[1]?.trim().length < 2) ||
            // Regex, der Leerzeichen entfernt
            Number(input.replace(/\s/g, ''))
        ) {
            return false;
        }
        return input != this.selectedStrasse?.strassenname && input.length >= 2 && isAUT;
    }

    /**
     * Lest die Forms aus und generiert ein Zustelladresse-Objekt
     */
    readZustelladresseFromForms(): Zustelladresse {
        let zustelladresse: Zustelladresse = null;
        const selectedStaat: Staat = this.selectedStaat;

        if (this.isInlandAdresse) {
            zustelladresse = {
                zA_Anschriftszeile1: this.zustelladresseFormAt.get('adresszeile1').value,
                zA_Anschriftszeile2: this.zustelladresseFormAt.get('strasseHausnr').value,
                zA_Anschriftszeile3: this.zustelladresseFormAt.get('plzOrt').value,
                zA_Anschriftszeile4: '',
                zA_Anschriftszeile5: '',
                zA_Anschriftszeile6: '',
                zA_Staat: selectedStaat?.isO3,
                zA_StaatText: selectedStaat?.name
            };
        } else {
            // check if 2 inputs filled then save
            zustelladresse = {
                zA_Anschriftszeile1: this.zustelladresseForm.get('anschriftszeile1').value,
                zA_Anschriftszeile2: this.zustelladresseForm.get('anschriftszeile2').value,
                zA_Anschriftszeile3: this.zustelladresseForm.get('anschriftszeile3').value,
                zA_Anschriftszeile4: this.zustelladresseForm.get('anschriftszeile4').value,
                zA_Anschriftszeile5: this.zustelladresseForm.get('anschriftszeile5').value,
                zA_Anschriftszeile6: this.zustelladresseForm.get('anschriftszeile6').value,
                zA_Staat: selectedStaat?.isO3,
                zA_StaatText: selectedStaat?.name
            };
        }
        return zustelladresse;
    }

    /**
     * Validator, für das ZustelladressenForm der Auslandsadressen
     * Es müssen mindestens 2 der Anschriftszeilen ausgefüllt sein, allerdings ist es egal welche 2
     * @returns
     */
    minimalAdresslinesFilledValidation(): ValidatorFn {
        return (form: UntypedFormGroup): ValidationErrors | null => {
            let count = 0;

            count = form.get('anschriftszeile1').value ? count + 1 : count;
            count = form.get('anschriftszeile2').value ? count + 1 : count;
            count = form.get('anschriftszeile3').value ? count + 1 : count;
            count = form.get('anschriftszeile4').value ? count + 1 : count;
            count = form.get('anschriftszeile5').value ? count + 1 : count;
            count = form.get('anschriftszeile6').value ? count + 1 : count;

            // Es müssen mindestens zwei Anschriftszeilen befüllt sein
            if (count < 2) {
                return { adresszeilenCount: true };
            }
            return null;
        };
    }

    /**
     * Rückmeldung mit akzeptiert an ParentKomponente
     * Daten werden and die ParentKomponente emittet
     */
    acceptDialog(): void {
        // Validierung der Forms
        if (
            this.zustelladresseDialogForm.invalid ||
            (this.isInlandAdresse && this.zustelladresseFormAt.invalid) ||
            (!this.isInlandAdresse && this.zustelladresseForm.invalid)
        ) {
            this.zustelladresseDialogForm.markAllAsTouched();
            this.zustelladresseFormAt.markAllAsTouched();
            this.zustelladresseForm.markAllAsTouched();
            this.alertService.warning('Bitte geben Sie alle Pflichtfelder an!');
            return;
        }

        const zustelladresse: Zustelladresse = this.readZustelladresseFromForms();
        this.dialogAccepted.emit({ accepted: true, dialogData: zustelladresse });
    }

    /**
     * Rückmeldung mit abgebrochen an ParentKomponente
     */
    cancelDialog(): void {
        this.dialogAccepted.emit({ accepted: false, dialogData: null });
    }

    /**
     * Setzt weitereAdresszeilen auf true, damit im UI weitere Eingabezeilen zur Verfügung stehen
     */
    weitereAdresszeilenAnzeigen(): void {
        this.weitereAdresszeilen = true;
    }
}
