import { Pipe, PipeTransform } from '@angular/core';
import { ZustellartEnum } from '@shared/wahlkarten-enums';

@Pipe({
    name: 'wkZustellart'
})
export class WkZustellartPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte der Wahlkartenzustellart in eine textuelle Form.
     * @param value ZustellartEnum
     */
    transform(value: ZustellartEnum): string {
        switch (value) {
            case ZustellartEnum.Persoenlich:
                return 'Persönlich';
            case ZustellartEnum.Einschreiben:
                return 'Einschreiben';
            case ZustellartEnum.Standardpost:
                return 'Standardpost';
            case ZustellartEnum.AnderePerson:
                return 'Vollmacht';
            case ZustellartEnum.DurchBoten:
                return 'durch Boten';
            case ZustellartEnum.EinschreibenRSb:
                return 'Einschreiben RSb';
            case ZustellartEnum.PerRSbRSa:
                return 'Per RSb/RSa';
            default:
                // Wenn die Zustellart keinem Eintrag entspricht soll eine leerer String im Wahlbestand angezeigt werden
                return '';
        }
    }
}
