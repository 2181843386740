import { Pipe, PipeTransform } from '@angular/core';
import { OffeneBearbeitungen } from '@shared/models/enums';

/**
 * Konvertiert die Enum-Werte des Typs OffeneBearbeitungen
 * in eine passende textuelle Darstellung für das UI.
 */
@Pipe({
    name: 'offeneBearbeitungen'
})
export class OffeneBearbeitungenPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value OffeneBearbeitungen
     */
    transform(value: OffeneBearbeitungen): string {
        switch (value) {
            case OffeneBearbeitungen.Wahlkarten:
                return 'Wahlkartendruck offen';
            case OffeneBearbeitungen.Etiketten:
                return 'Etikettdruck offen';
            case OffeneBearbeitungen.Postaufgabeliste:
                return 'Aufgabeliste Post offen';
        }
    }
}
