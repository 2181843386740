// @ts-strict-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { ElectionTimelineItem } from '@shared/models/wahlkalenderItem';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WahlkalenderService {
    config: AppConfig = null;

    constructor(private httpClient: HttpClient, private configService: ConfigAssetLoaderService) {
        this.config = configService.getConfig();
    }

    /**
     * Abfrage der Daten des Wahlkalenders
     * @param id technische Id der Wahl
     * @returns Wahlkalender-Einträge
     */
    getWahlkalenderEintraege(id: string): Observable<ElectionTimelineItem[]> {
        return this.httpClient.get<ElectionTimelineItem[]>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahlkalender/${id}`
        );
    }
}
