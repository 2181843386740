<k5next-dialog-heading [heading]="data.title" [showClose]="false"></k5next-dialog-heading>

<k5next-dialog-content [ngClass]="{ linebreak: contentLineBreak }" data-cy="confirmation-dialog-content">
    <k5-loading *ngIf="data.isLoading; else content"></k5-loading>
    <ng-template #content>
        <span [innerHtml]="data.content"></span>
    </ng-template>
</k5next-dialog-content>

<k5next-dialog-actions [alignEnd]="true" *ngIf="!data.isLoading">
    <button
        mat-button
        color="primary"
        type="button"
        *ngIf="data.showTertiaryButton"
        (click)="handleButton(ACTION_BUTTON_VALUES.Tertiary)"
        [disabled]="data.tertiaryButtonDisabled"
    >
        {{ tertiaryButtonLabel }}
    </button>
    <button
        mat-stroked-button
        color="primary"
        type="button"
        *ngIf="data.showSecondaryButton === null || undefined || true"
        (click)="handleButton(ACTION_BUTTON_VALUES.Secondary)"
        [disabled]="data.secondaryButtonDisabled"
    >
        {{ secondaryButtonLabel }}
    </button>
    <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="handleButton(ACTION_BUTTON_VALUES.Primary)"
        [disabled]="data.primaryButtonDisabled"
        data-cy="bestaetigen"
    >
        {{ primaryButtonLabel }}
    </button>
</k5next-dialog-actions>
