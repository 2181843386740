export class WahlUiConstants {
    /**
     * Konstanten für das Wahlvorbereitungs-UI
     */

    static readonly ORTSABWESENHEIT = 'Ortsabwesenheit';
    static readonly GESUNDHEITLICH = 'Gesundheitliche Gründe';
    static readonly AUSLANDSAUFENTHALT = 'Auslandsaufenthalt';
    static readonly HAFTANSTALT = 'Unterbringung in Haftanstalt';
    static readonly BEGRUENDUNGEN = [
        WahlUiConstants.ORTSABWESENHEIT,
        WahlUiConstants.GESUNDHEITLICH,
        WahlUiConstants.AUSLANDSAUFENTHALT
    ];

    static readonly SONDERWAHLBEHOERDE_GESUNDHEITLICH = 'Alters-, Krankheits- oder ähnliche Gründe';
    static readonly SONDERWAHLBEHOERDE_BEWEGUNGSFREIHEIT =
        'Behördliche Einschränkung der Bewegungsfreiheit (Pandemiebekämpfung)';
    static readonly SONDERWAHLBEHOERDE_BEGRUENDUNGEN = [
        WahlUiConstants.SONDERWAHLBEHOERDE_GESUNDHEITLICH,
        WahlUiConstants.SONDERWAHLBEHOERDE_BEWEGUNGSFREIHEIT
    ];

    static readonly GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

    static readonly BUNDESLAND_TIR = 'Tirol';
    static readonly BUNDESLAND_STMK = 'Steiermark';
    static readonly BUNDESLAND_SZBG = 'Salzburg';
    static readonly BUNDESLAND_OOE = 'Oberösterreich';
    static readonly BUNDESLAND_KTN = 'Kärnten';
    static readonly BUNDESLAND_VBG = 'Vorarlberg';
    static readonly BUNDESLAND_BGLD = 'Burgenland';
    static readonly BUNDESLAND_NOE = 'Niederösterreich';
}
