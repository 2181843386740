<div class="flex items-center gap-x-2">
    <button
        type="button"
        matTooltip="Zurück"
        aria-label="Zurück"
        mat-icon-button
        (click)="navigateBack()"
        data-cy="zurueck-button"
    >
        <mat-icon fontSet="fa-solid" fontIcon="fa-chevron-left" class="text-gray-500 text-base"></mat-icon>
    </button>
    <div class="flex items-center justify-center">
        <i class="fg-primary fa-duotone fa-lg fa-fw" [ngClass]="icon" aria-hidden="true"></i>
    </div>

    <span class="pl-2 text-xl">{{ header }} </span>
</div>
