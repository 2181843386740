<div class="editable-card p-6">
    <div class="flex-grow">
        <div class="fs-xl">
            <ng-content select="[k5-editable-card-title]"></ng-content>
        </div>
        <div class="fs-lg">
            <ng-content select="[k5-editable-card-subtitle]"></ng-content>
        </div>
        <div class="pt-md">
            <ng-content select="[k5-editable-card-content]"></ng-content>
        </div>
    </div>
    <div class="pl-md action-buttons" [ngClass]="actionButtonsVisible ? '' : 'hide-actions-buttons'">
        <button
            type="button"
            aria-label="Bearbeiten"
            mat-icon-button
            matTooltip="Bearbeiten"
            *ngIf="showEdit"
            [ngClass]="enableEdit ? '' : 'hide-actions-buttons'"
            (click)="editItem()"
        >
            <mat-icon fontSet="fa-duotone" fontIcon="fa-pencil" color="primary" class="text-base"></mat-icon>
        </button>
        <button
            type="button"
            aria-label="Löschen"
            mat-icon-button
            matTooltip="Löschen"
            *ngIf="showDelete"
            [ngClass]="enableDelete ? '' : 'hide-actions-buttons'"
            (click)="deleteItem()"
        >
            <mat-icon fontSet="fa-duotone" fontIcon="fa-trash-alt" class="text-red-500 text-base"></mat-icon>
        </button>
        <button
            type="button"
            [attr.aria-label]="customButtonTooltip"
            mat-icon-button
            [matTooltip]="customButtonTooltip"
            *ngIf="showCustomButton"
            [ngClass]="enableCustomButton ? '' : 'hide-actions-buttons'"
            (click)="customAction()"
        >
            <mat-icon fontSet="fa-duotone" [fontIcon]="customButtonIcon" class="text-base"></mat-icon>
        </button>
    </div>
</div>
