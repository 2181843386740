import { Pipe, PipeTransform } from '@angular/core';
import { GeschlechtEnum } from '@shared/models/enums';

/**
 * Konvertiert Werte des Typs GeschlechtEnum
 * in eine passende textuelle Darstellung.
 */
@Pipe({
    name: 'gender'
})
export class GenderPipe implements PipeTransform {

    /**
     * Übersetzt die Geschlecht-Enum-Werte des Personenservice in eine textuelle Form.
     * @param value GeschlechtEnum -> Personenservice
     * @returns Geschlecht als Text
     */
    transform(value: GeschlechtEnum): string {
        switch (value) {
            case GeschlechtEnum.Maennlich:
                return 'männlich';
            case GeschlechtEnum.Weiblich:
                return 'weiblich';
            default:
                return 'unbekannt';

        }
    }

}
