import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[k5StopEnterPropagation]',
    standalone: true
})
export class StopEnterPropagationDirective {
    @HostListener('keydown.enter', ['$event'])
    onEnterKeydown(event: KeyboardEvent): void {
        event.stopPropagation();
    }
}
