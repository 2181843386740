import { Injectable, inject } from '@angular/core';
import { MandantenService } from './mandanten.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
})
export class K5NextHilfeNavigationService {
    private hilfeToken = '';

    private mandantenService = inject(MandantenService);
    private alertService = inject(AlertService);

    /**
     * Navigiert zur Hilfe-Seite mit dem angegebenen Link.
     * Wenn kein Hilfe-Token vorhanden ist, wird zuerst ein Token abgerufen und dann die Seite geöffnet.
     * Wenn bereits ein Hilfe-Token vorhanden ist, wird die Seite direkt geöffnet.
     * @param link - Der Link zur Hilfe-Seite.
     */
    openk5NextHilfePage(link: string): void {
        if (!this.hilfeToken) {
            this.mandantenService.getK5NextHilfeToken().subscribe({
                next: (token: string) => {
                    this.hilfeToken = token;
                    // Open the page
                    window.open(`${link}?t=${this.hilfeToken}`);
                },
                error: (error: HttpErrorResponse) => {
                    this.alertService.errorResponse(error, 'Fehler bei der Abfrage des k5|Next_Hilfe Access Tokens');
                }
            });
        } else {
            // Open the page
            window.open(`${link}?t=${this.hilfeToken}`);
        }
    }
}
