// @ts-strict-ignore
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { Wahldaten } from '@shared/models/wahldaten';
import { Observable } from 'rxjs';
import { DatenabgleichDaten } from '../models/datenabgleichDaten';
import { KontrolleKontaktdetailsWahl } from '../models/kontrolleKontaktdetails';
import { WahlstatusInfo } from '@shared/models/wahlstatusInfo';
import { DatenabgleicheResponse } from '../models/datenabgleicheResponse';
import { WahlkartenstatistikResponse } from '@shared/models/wahlkartenstatistikResponse';
import { MeineWahlkarteStatistikResponse } from '@shared/models/meineWahlkarteStatistikResponse';
import { WahlberechtigteAktuellStatistikResponse } from '@shared/models/wahlberechtigteAktuellStatistikResponse';
import { MeineWahlkarteIdentitaetsnachweisStatistikResponse } from '@shared/models/meineWahlkarteIdentitaetsnachweisStatistikResponse';
import { UpdateZentraleServicesRequest } from '../models/updateZentraleServicesRequest';
import { WahlkartenAntragsartStatistikResponse } from '@shared/models/wahlkarten-antragsart-statistik-response';

@Injectable({
    providedIn: 'root'
})
export class WahldetailService {
    private sprengelgruppeId: string;

    constructor(
        private http: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {}

    get currentSprengelgruppeId(): string {
        return this.sprengelgruppeId;
    }

    set currentSprengelgruppeId(sprengelgruppeId: string) {
        this.sprengelgruppeId = sprengelgruppeId;
    }

    /**
     * Abfrage der Daten einer Wahl
     * @param id technische Id der Wahl
     */
    getWahldetails(id: string): Observable<Wahldaten> {
        return this.http.get<Wahldaten>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${id}`
        );
    }

    /**
     * Status des Datenabgleiches zu einer Wahl ermitteln
     * @param wahlId Id der aktuellen Wahl
     * @returns
     */
    getDatenabgleichStatus(wahlId: string): Observable<DatenabgleichDaten> {
        return this.http.get<DatenabgleichDaten>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahlId}/Datenabgleich`
        );
    }

    /**
     * Zeitpunkt und Ergebnis der durchgeführten Datenabgleiche ermitteln
     * @param wahlId Id der aktuellen Wahl
     * @returns
     */
    getDatenabgleichProtokoll(wahlId: string): Observable<DatenabgleicheResponse[]> {
        return this.http.get<DatenabgleicheResponse[]>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Datenabgleich/${wahlId}`
        );
    }

    /**
     * Startet den Datenabgleich des Wahlbestandes mit der zentralen Person
     * @param wahlId Id der aktuellen Wahl
     * @param abgleichVerstorbene Verstorben-Kennzeichen wird abgeglichen
     * @param abgleichAdressen Adresse wird abgeglichen
     * @param abgleichName Namen und Titel werden abgeglichen
     * @returns Datenabgleich wurde gestartet
     */
    startDatenabgleich(
        wahlId: string,
        abgleichVerstorbene: boolean,
        abgleichAdressen: boolean,
        abgleichName: boolean
    ): Observable<boolean> {
        return this.http.patch<any>(
            `${
                this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl
            }/Wahl/${wahlId}/Datenabgleich?abgleichVerstorbene=${abgleichVerstorbene}&abgleichAdressen=${abgleichAdressen}&abgleichName=${abgleichName}`,
            {}
        );
    }

    /**
     * Startet die Datenverknüpfung des Wahlbestandes mit der zentralen Person
     * @param wahlId Id der aktuellen Wahl
     * @returns Verknüpfung mit der zentalen Person wurde gestartet
     */
    startDatenverknuepfung(wahlId: string): Observable<boolean> {
        return this.http.patch<any>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahlId}/Datenverknuepfung`,
            {}
        );
    }

    /**
     * Updatet die Daten einer Wahl
     * @param wahldetails Wahl, welche geupdatet wird
     */
    updateWahldetails(wahldetails: Wahldaten): Observable<Wahldaten> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<Wahldaten>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahldetails.id}`,
            wahldetails,
            { headers }
        );
    }

    /**
     * Updatet die zentralen Services einer Wahl
     * @param wahlId Id der Wahl
     * @param updateZentraleServicesRequest Aktualisierungsdaten für die zentralen Services
     * @returns Gespeicherte Wahldaten
     */
    updateZentraleServices(
        wahlId: string,
        updateZentraleServicesRequest: UpdateZentraleServicesRequest
    ): Observable<Wahldaten> {
        return this.http.patch<Wahldaten>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/wahl/${wahlId}/zentraleServices`,
            updateZentraleServicesRequest
        );
    }

    /**
     * Updatet die Kontatkdetails einer Wahl
     * @param id Id der Wahl
     * @param kontaktdetails neue Kontaktdetails
     * @returns HttpResponse
     */
    updateWahlKontaktdetails(id: string, kontaktdetails: KontrolleKontaktdetailsWahl): Observable<void> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put<void>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${id}/updateKontaktdaten`,
            kontaktdetails,
            { headers }
        );
    }

    /**
     * Setzt oder aktualisiert, nach der Kontrolle der Kontakt- und Wahllokaldaten, den Status und das Protokoll der Wahl.
     * @param wahlId ID der Wahl
     * @returns
     */
    setWahlstatusAndWahlProtokollForKontaktUndWahllokaldatenkontrolle(wahlId: string): Observable<WahlstatusInfo> {
        return this.http.post<WahlstatusInfo>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahlId}/Kontaktdatenkontrolle`,
            null
        );
    }

    /**
     * Abfrage der Wahlkartenstatistik
     * @param wahlId Id der Wahl
     * @returns Observable mit der Wahlkartenstatistik
     */
    getWahlkartenstatistik(wahlId: string): Observable<WahlkartenstatistikResponse> {
        return this.http.get<WahlkartenstatistikResponse>(
            `${this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahlId}/wahlkartenstatistik`
        );
    }

    /**
     * Abfrage der Statistik zu Wahlkartenanträgen über Meine Wahlkarte
     * @param wahlId Id der Wahl
     * @returns Observable mit der Wahlkartenantragsstatistik
     */
    getMeineWahlkarteStatistik(wahlId: string): Observable<MeineWahlkarteStatistikResponse> {
        return this.http.get<MeineWahlkarteStatistikResponse>(
            `${
                this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl
            }/Wahl/${wahlId}/meineWahlkarteStatistik`
        );
    }

    /**
     * Abfrage der Statistik zu den wahlberechtigen Personen nach Personentyp
     * @param wahlId Id der Wahl
     * @returns Observable mit der Statistik zu den wahlberechtigten Personen
     */
    getWahlberechtigteAktuellStatistik(wahlId: string): Observable<WahlberechtigteAktuellStatistikResponse> {
        return this.http.get<WahlberechtigteAktuellStatistikResponse>(
            `${
                this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl
            }/Wahl/${wahlId}/wahlberechtigteAktuellStatistik`
        );
    }

    /**
     * Abfrage der Statistik der Wahlkartenanträge nach Identitätsnachweis
     * @param wahlId Id der Wahl
     * @returns Observable mit der Statistik zu den Wahlkartenanträgen nach Identitätsnachweis
     */
    getMeineWahlkarteIdentitaetsnachweisStatistik(
        wahlId: string
    ): Observable<MeineWahlkarteIdentitaetsnachweisStatistikResponse> {
        return this.http.get<MeineWahlkarteIdentitaetsnachweisStatistikResponse>(
            `${
                this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl
            }/Wahl/${wahlId}/meineWahlkarteIdentitaetsnachweisStatistik`
        );
    }

    /**
     * Abfrage der Statistik der Wahlkarten nach Antragsart
     * @param wahlId Id der Wahl
     * @returns Observable mit der Statistik zu den Wahlkarten nach Antragsart
     */
    getWahlkartenAntragsartStatistik(
        wahlId: string
    ): Observable<WahlkartenAntragsartStatistikResponse> {
        return this.http.get<WahlkartenAntragsartStatistikResponse>(
            `${
                this.configService.getConfig().k5civisWv.wahlvorbereitungUiApiUrl
            }/Wahl/${wahlId}/WahlkartenAntragsartStatistik`
        );
    }
}
