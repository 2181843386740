// @ts-strict-ignore
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    computed,
    input,
    signal
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'k5-edit-section',
    templateUrl: './edit-section.component.html',
    styleUrls: ['./edit-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSectionComponent implements OnInit, OnDestroy {
    @Input()
    editSectionId: string = '';

    @Input()
    editSectionForm: FormGroup;

    @Input()
    heading: string = '';

    customIcon = input<string | null>(null);

    customIconClass = input<string>('fg-primary text-base');

    @Input()
    customIconTooltip: string;

    @Input()
    icon: string = '';

    isEditable = input<boolean>(true);

    isAddable = input<boolean>(false);

    isDeleteable = input<boolean>(false);

    @Output()
    editModeEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    addClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    deleteClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    customEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    isEditMode = signal<boolean>(false);
    globalEditServiceIsEditing = signal<boolean>(false);

    subscription: Subscription = new Subscription();

    constructor(
        private readonly globalEditService: GlobalEditService,
        private readonly alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.globalEditService.closeAllEditSections$.subscribe(() => {
                this.switchToReadMode();
            })
        );

        this.subscription.add(
            this.globalEditService.editSectionId$.subscribe((editSectionId: string) => {
                if (editSectionId === this.editSectionId && !this.isEditMode()) {
                    this.isEditMode.set(true);
                    this.editModeEnabled.emit(this.isEditMode());
                } else {
                    this.switchToReadMode();
                }
            })
        );

        this.subscription.add(this.globalEditService.isEditing$.subscribe(this.globalEditServiceIsEditing.set));
        this.globalEditServiceIsEditing.set(this.globalEditService.isEditing);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Gibt an, ob der Bearbeitungsbleistift sichtbar ist
     */
    isEditPencilVisible = computed<boolean>(
        () => this.isEditable() && !this.isEditMode() && !this.globalEditServiceIsEditing()
    );

    /**
     * Gibt an, ob der Plus Button sichtbar ist
     */
    isAddButtonVisible = computed<boolean>(
        () => this.isAddable() && !this.isEditMode() && !this.globalEditServiceIsEditing()
    );

    /**
     * Gibt an, ob der custom Button sichtbar ist
     */
    isCustomButtonVisible = computed<boolean>(
        () => !!this.customIcon() && !this.isEditMode() && !this.globalEditServiceIsEditing()
    );

    /**
     * Gibt an, ob der delete Button sichtbar ist
     */
    isDeleteButtonVisible = computed<boolean>(
        () => this.isDeleteable() && !this.isEditMode() && !this.globalEditServiceIsEditing()
    );

    /**
     * Gibt das Event an die Eltern Komponente weiter
     */
    triggerCustomEvent(): void {
        this.customEvent.emit(true);
    }

    /**
     * Wechelt in den Bearbeitungsmodus wenn der Bereich editiert werden darf
     */
    switchToEditMode(): void {
        if (this.globalEditServiceIsEditing()) {
            this.alertService.error(
                'Es werden bereits Daten bearbeitet',
                'Bitte speichern Sie Ihre Änderungen bevor Sie fortfahren'
            );
            return;
        }

        if (this.isEditable()) {
            this.globalEditService.setEditSectionForm(this.editSectionForm);
            this.globalEditService.switchToEditMode(this.editSectionId);
        }
    }

    /**
     * Benachrichtigt Eltern Komponente, dass ein neues Objekt hinzugefügt werden soll
     */
    switchToAddMode(): void {
        this.addClicked.emit(true);
    }

    /**
     * Wechselt in den Lesemodus
     */
    private switchToReadMode(): void {
        this.isEditMode.set(false);
    }

    /**
     * Emittet einen Event, wenn der Löschen Button geklickt wurde
     */
    onDeleteClicked(): void {
        this.deleteClicked.emit();
    }
}
