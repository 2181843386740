<div class="min-h-full px-4">
    <header>
        <k5-responsive-title>
            <k5-page-header header="Meldewesen - Dashboard" icon="fa-duotone fa-chart-line fg-primary">
            </k5-page-header>
        </k5-responsive-title>
    </header>
    <main>
    </main>
</div>
