// @ts-strict-ignore
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { GlobalEditService } from '@core/services/global-edit.service';
import { EditSectionConstants } from '@shared/constants/shared-constants';
import { Wahldaten } from '@shared/models/wahldaten';
import { WidgetType } from '@shared/models/widgetInfo';
import { FormErrorMessageService } from '@shared/services/form-error-message.service';

/**
 * Informationen über ein Widget
 */
export interface WidgetInfo {
    type: WidgetType;
    title: string;
    rows: number;
    cols: number;
    previewImage?: string;
    description?: string;
    selected?: boolean;
}

/**
 * Konfiguration für den AddWidgetDialog
 * Beinhaltet alle Widgets, die hinzugefügt werden können und
 * die dazugehörigen Wahlen.
 */
export interface AddWidgetDialogData {
    widgets: WidgetInfo[];
    wahlen: Wahldaten[];
}

/**
 * Beinhaltet die Dialogauswahl für das Hinzufügen eines Widgets
 */
export interface AddWidgetDialogResult {
    widget: WidgetInfo;
    wahl: Wahldaten;
}

@Component({
    selector: 'k5-add-widget-dialog',
    templateUrl: './add-widget-dialog.component.html',
    styleUrls: ['./add-widget-dialog.component.scss']
})
export class AddWidgetDialogComponent implements OnDestroy {
    wahlCtrl = new FormControl<Wahldaten | null>(null, [Validators.required]);

    selectedWidget: WidgetInfo | null = null;

    constructor(
        private globalEditService: GlobalEditService,
        public formErrorService: FormErrorMessageService,
        public dialogRef: MatDialogRef<AddWidgetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AddWidgetDialogData
    ) {
        if (data?.widgets && data.widgets.length > 0) {
            this.selectedWidget = data.widgets.find((w) => w.selected);
            if (!this.selectedWidget) {
                this.selectedWidget = data.widgets[0];
                this.selectedWidget.selected = true;
            }
        }

        if (data?.wahlen && data.wahlen.length > 0) {
            this.wahlCtrl.patchValue(data?.wahlen[0]);
        }

        this.globalEditService.switchToEditMode(EditSectionConstants.SHARED_WIDGET_CREATE);
    }

    ngOnDestroy(): void {
        this.globalEditService.switchToReadMode();
    }

    /**
     * Behandelt eine Änderung des selektierten Widgets
     * @param selectionListChange Selektion des Widgets
     */
    handleWidgetSelectionChange(selectionListChange: MatSelectionListChange): void {
        const widgetInfo: WidgetInfo = selectionListChange.options[0].value;
        this.selectedWidget = widgetInfo;
    }

    /**
     * Adds a new widget to the dashboard
     */
    addWidget(): void {
        if (this.wahlCtrl.valid && this.selectedWidget) {
            const dialogResult: AddWidgetDialogResult = {
                widget: this.selectedWidget,
                wahl: this.wahlCtrl.value
            };
            this.dialogRef.close(dialogResult);
        }
    }
}
