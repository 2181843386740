import { Pipe, PipeTransform } from '@angular/core';
import { SchnellsucheKategorieEnum } from '../models/enums';

/**
 * Konvertiert Werte des Typs SchnellsucheKategorieEnum
 * in eine passende textuelle Darstellung.
 */
@Pipe({
    name: 'schnellsucheKategorie'
})
export class SchnellsucheKategoriePipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value SchnellsucheKategorieEnum
     */
    transform(value: SchnellsucheKategorieEnum): string {
        switch (value) {
            case SchnellsucheKategorieEnum.Kontakte:
                return 'Kontakte';
            case SchnellsucheKategorieEnum.Adressen:
                return 'Adressen';
            case SchnellsucheKategorieEnum.Gruppen:
                return 'Gruppen';
            case SchnellsucheKategorieEnum.Listen:
                return 'Listen';
            case SchnellsucheKategorieEnum.Waehler:
                return 'Wähler';
            default:
                return `SchnellsucheKategorieEnum Wert ${value} nicht übersetzbar!`;
        }
    }

}
