import { Pipe, PipeTransform } from '@angular/core';
import { KontaktartEnum } from '@shared/models/enums';

/**
 * Konvertiert Werte des Typs SchnellsucheErgebnisElementTypEnum
 * in eine passende textuelle Darstellung.
 */
@Pipe({
    name: 'contactIcon'
})
export class ContactIconPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value SchnellsucheErgebnisElementTypEnum
     */
    transform(value: KontaktartEnum): string {
        switch (value) {
            case KontaktartEnum.Telefon:
                return '<i class="fa-duotone fa-phone-alt fa-fw"></i>';
            case KontaktartEnum.Mobil:
                return '<i class="fa-duotone fa-mobile-alt fa-fw"></i>';
            case KontaktartEnum.Fax:
                return '<i class="fa-duotone fa-fax fa-fw"></i>';
            case KontaktartEnum.EMail:
                return '<i class="fa-duotone fa-at fa-fw"></i>';
            default:
                return `KontaktartEnum Wert ${value} nicht übersetzbar!`;
        }
    }
}
