// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertType } from '../models/enums';
import { ConfigAssetLoaderService } from './config-asset-loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Problem } from '@core/models/problem';
import { SimpleAlertComponent } from '@core/components/simple-alert/simple-alert.component';
import { SimpleAlert } from '@core/models/simpleAlert';
import { ProblemAlertComponent } from '@core/components/problem-alert/problem-alert.component';
import { ProblemAlert } from '@core/models/problemAlert';
import { ProblemResponseService } from './problem-response.service';
import { NotificationAlert } from '@core/models/notificationAlert';
import { NotificationAlertComponent } from '@core/components/notification-alert/notification-alert.component';
import { UIBenachrichtigung } from '@shared/models/uIBenachrichtigung';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(
        private snackBar: MatSnackBar,
        private configAssetLoader: ConfigAssetLoaderService,
        private problemResponseService: ProblemResponseService
    ) {}

    /**
     * Zeigt einen Fehler in der SnackBar an.
     * @param message Meldung
     */
    public error(titleMessage: string, detail: string = null): void {
        this.alert(AlertType.Error, titleMessage, detail);
    }

    /**
     * Zeigt eine Warnung in der SnackBar an.
     * @param message Meldung
     */
    public warning(titleMessage: string, detail: string = null): void {
        this.alert(AlertType.Warning, titleMessage, detail);
    }

    /**
     * Zeigt eine Information in der SnackBar an.
     * @param message Meldung
     */
    public info(titleMessage: string, detail: string = null): void {
        this.alert(AlertType.Info, titleMessage, detail);
    }

    /**
     * Zeigt eine Erfolgreich-Meldung in der SnackBar an.
     * @param message Meldung
     */
    public success(titleMessage: string, detail: string = null): void {
        this.alert(AlertType.Success, titleMessage, detail);
    }

    public notification(titleMessage: string, notification: UIBenachrichtigung, detail: string = null): void {
        this.alertNotification(AlertType.Info, titleMessage, notification, detail);
    }

    /**
     * Extrahiert die Fehlerinformationen nach einem Http-Aufruf und zeigt
     * diese als Error-Alert an
     * @param response HttpErrorResponse Fehlernachricht
     * @param title optional zum Überschreiben des Titels
     */
    public async errorResponse(response: HttpErrorResponse, titleMessage: string = null): Promise<void> {
        const problem = this.overrideTitle(await this.problemResponseService.parseErrorReponse(response), titleMessage);
        this.alertProblem(AlertType.Error, problem);
    }

    /**
     * Extrahiert die Fehlerinformationen nach einem Http-Aufruf und zeigt
     * diese als Warning-Alert an
     * @param response HttpErrorResponse Fehlernachricht
     * @param titleMessage optional zum Überschreiben des Titels
     */
    public async warningResponse(response: HttpErrorResponse, titleMessage: string = null): Promise<void> {
        const problem = this.overrideTitle(await this.problemResponseService.parseErrorReponse(response), titleMessage);
        this.alertProblem(AlertType.Error, problem);
    }

    /**
     * Öffnet eine Snackbar wo eine Fehlermeldung angezeigt wird.
     * @param message Nachricht, die dem Benutzer in der Snackbar angezeigt wird
     * @param alertType Nachrichtentyp
     */
    private alert(alertType: AlertType, titleMessage: string, detailMessage?: string) {
        const simpleAlert: SimpleAlert = {
            alertType,
            titleMessage,
            detailMessage
        };

        // Eigene View, die in Snackbar angezeigt wird.
        // Dokumentation: https://material.angular.io/components/snack-bar/api
        this.snackBar.openFromComponent(SimpleAlertComponent, {
            duration: alertType === AlertType.Error ? null : this.configAssetLoader.getConfig().env.snackBarDuration,
            data: simpleAlert,
            panelClass: ['k5-snackbar']
        });
    }

    private alertNotification(
        alertType: AlertType,
        titleMessage: string,
        notification: UIBenachrichtigung,
        detailMessage?: string
    ) {
        const notificationAlert: NotificationAlert = {
            alertType,
            titleMessage,
            notification,
            detailMessage
        };

        // Eigene View, die in Snackbar angezeigt wird.
        // Dokumentation: https://material.angular.io/components/snack-bar/api
        this.snackBar.openFromComponent(NotificationAlertComponent, {
            duration:
                alertType === AlertType.Error ? null : this.configAssetLoader.getConfig().env.snackBarDurationError,
            data: notificationAlert,
            panelClass: ['k5-snackbar']
        });
    }

    /**
     * Zeigt alle Informationen des aufgetretenen Fehlers in einem Alert an
     * @param problem Problem
     */
    private alertProblem(alertType: AlertType, problem: Problem) {
        const problemAlert: ProblemAlert = {
            alertType,
            titleMessage: problem?.title,
            problem
        };

        this.snackBar.openFromComponent(ProblemAlertComponent, {
            duration: alertType === AlertType.Error ? null : this.configAssetLoader.getConfig().env.snackBarDuration,
            data: problemAlert,
            panelClass: ['k5-snackbar']
        });
    }

    /**
     * Überschreibt den Titel des Problems
     * @param problem Problem
     * @param titleMessage Tiel
     * @returns Problem mit überschriebenen Titel
     */
    private overrideTitle(problem: Problem, titleMessage: string): Problem {
        if (titleMessage) {
            problem.title = titleMessage;
        }
        return problem;
    }
}
