<div class="flex h-12 flex-row items-center">
    <!-- Bereichsicon -->
    <div *ngIf="icon" class="mr-2 flex h-12 w-12 items-center justify-center">
        <i class="fa-duotone fa-lg fa-fw text-gray-500" [ngClass]="icon" aria-hidden="true"></i>
    </div>

    <!-- Bereichstitel -->
    <span class="text-base"> {{ title }} </span>

    <!-- Variables Addon, dass in der Titelzeile ganz rechts angezeigt wird -->
    <div class="grow">
        <ng-content></ng-content>
    </div>
</div>

<!-- Linie -->
<div class="flex items-center">
    <div class="h-[3px] w-12 bg-gray-500"></div>
    <div class="h-[1px] grow bg-gray-300"></div>
</div>
