import { Pipe, PipeTransform } from '@angular/core';
import { PersonenTyp } from '@shared/models/enums';

@Pipe({
    name: 'personenTyp'
})
export class PersonenTypPipe implements PipeTransform {
    transform(value: PersonenTyp): string {
        switch (value) {
            case PersonenTyp.Oesterreicher:
                return 'Österreicher';
            case PersonenTyp.EUBuerger:
                return 'EU Bürger';
            case PersonenTyp.Auslandsoesterreicher:
                return 'Auslandsösterreicher';
            case PersonenTyp.Obdachlos:
                return 'Obdachlos';
            case PersonenTyp.PersonInHaft:
                return 'Person in Haft'
            default:
                return 'Alle';
        }
    }
}
