/**
 * k5 Bürgerservice - Kontaktmanagement
 * Öffentliche API-Schnittstelle für das Kontaktmanagement vom k5 Bürgerservice
 *
 * OpenAPI spec version: v1
 * Contact: info@programmierfabrik.at
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Personenart = 0 | 1 | 2;

export const Personenart = {
    Natuerlich: 0 as Personenart,
    Unternehmen: 1 as Personenart,
    Alle: 2 as Personenart
};
