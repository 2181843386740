import { Pipe, PipeTransform } from '@angular/core';
import { ZMRAbgleichStatusEnum } from '@shared/models/zMRAbgleichStatusEnum';

@Pipe({
    name: 'zmrAbgleichStatus'
})
export class ZmrAbgleichStatusPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte des ZMRAbgleichStatusEnum in eine textuelle Form.
     * @param value ZMRAbgleichStatusEnum
     */
    transform(value: ZMRAbgleichStatusEnum): string {
        switch (value) {
            case ZMRAbgleichStatusEnum.Durchgefuehrt:
                return 'durchgeführt';
            case ZMRAbgleichStatusEnum.Fehler:
                return 'Fehler';
            case ZMRAbgleichStatusEnum.InArbeit:
                return 'in Arbeit';
            case ZMRAbgleichStatusEnum.TeilweiseDurchgefuhert:
                return 'teilweise durchgeführt';
            default:
                return '';
        }
    }
}
