<div class="flex items-center">
    <div *ngIf="alertType === ALERT_TYPES.Error">
        <i class="fa-duotone fa-2x fa-times-circle alert-icon fa-fw" aria-hidden="true"></i>
    </div>

    <div *ngIf="alertType === ALERT_TYPES.Warning">
        <i class="fa-duotone fa-2x fa-exclamation-triangle warning-icon fa-fw" aria-hidden="true"></i>
    </div>

    <div *ngIf="alertType === ALERT_TYPES.Info">
        <i class="fa-duotone fa-2x fa-info-circle info-icon fa-fw" aria-hidden="true"></i>
    </div>

    <div *ngIf="alertType === ALERT_TYPES.Success">
        <i class="fa-duotone fa-2x fa-check-circle success-icon fa-fw" aria-hidden="true"></i>
    </div>

    <span class="title-message grow pl-2 text-base">
        {{ titleMessage }}
    </span>

    <div class="pl-2">
        <button mat-button color="primary" (click)="closeSnackbar()">
            {{ closeActionText }}
        </button>
    </div>

    <div class="pl-2">
        <button *ngIf="detailsAvailable" mat-button color="primary" (click)="toggleDetails()">
            {{ showDetailsActionText }}
        </button>
    </div>
</div>

<!-- Spezifischer Content für die Detailanzeige -->
<ng-content *ngIf="showDetails" class="text-base"> </ng-content>
