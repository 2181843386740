/**
 * Ids of the widgets
 */
export enum WidgetType {
    Willkommen = 1,
    Betreiber = 2,
    RssFeed = 3,
    GespeicherteSuche = 4,
    GespeicherteGruppen = 5,
    ZmrAbgleich = 6,
    WahlberechtigteAktuell = 20,
    Wahlkartenstatistik = 21,
    MeineWahlkarte = 22,
    MeineWahlkarteAntragsart = 23,
    Timeline = 24,
    WahlkartenAntragsart = 25
}
