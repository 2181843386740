<k5-alert
    [alertType]="notificationAlert.alertType"
    [titleMessage]="notificationAlert.titleMessage"
    [detailsAvailable]="detailsAvailable"
>
    <div class="pt-2">
        {{ notificationAlert.detailMessage }}
    </div>
</k5-alert>

<div class="mb-2 ml-12 mt-2" *ngIf="notificationAlert.notification?.daten.url">
    <button class="primary-link text-sm" (click)="handleNotificationAction()">Anzeigen</button>
</div>
