import { Pipe, PipeTransform } from '@angular/core';
import { JaNeinAlleConstants } from '@shared/constants/shared-constants';
import { NullableBoolean } from '@shared/models/enums';

@Pipe({
    name: 'nullableBoolean'
})
export class NullableBooleanPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * Im UI kann damit auch ein neutraler Wert zur Auswahl gegeben werden
     * @param value SchnellsucheErgebnisElementTypEnum
     */
    transform(value: NullableBoolean): string {
        switch (value) {
            case NullableBoolean.Ja:
                return JaNeinAlleConstants.JA;
            case NullableBoolean.Nein:
                return JaNeinAlleConstants.NEIN;
            default:
                return JaNeinAlleConstants.ALLE;
        }
    }
}
