import { Pipe, PipeTransform } from '@angular/core';
import { Adresstyp } from '../models/adresstyp';

@Pipe({
    name: 'adresstyp'
})
export class AdresstypPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value Adresstyp
     */
    transform(value: Adresstyp): string {
        switch (value) {
            case Adresstyp.ManuellHauptadresse:
                return 'Manuelle Hauptadresse';
            case Adresstyp.ManuellZustelladresse:
                return 'Manuelle Zustelladresse';
            case Adresstyp.ErnPAnschrift:
                return 'ErnP Anschrift';
            case Adresstyp.URFirmensitz:
                return 'UR Firmensitz';
            case Adresstyp.URZustelladresse:
                return 'UR Zustelladresse';
            case Adresstyp.ZMRHauptwohnsitz:
                return 'ZMR Hauptwohnsitz';
            case Adresstyp.ZMRNebenwohnsitz:
                return 'ZMR Nebenwohnsitz';
            default:
                return 'Nicht bekannter Adresstyp';
        }
    }
}
