import { Pipe, PipeTransform } from '@angular/core';
import { WahlkarteUnterschriftEnum } from '@shared/models/enums';

@Pipe({
    name: 'wkUnterschrift'
})
export class WkUnterschriftPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value WahlkarteUnterschriftEnum
     */
    transform(value: WahlkarteUnterschriftEnum): string {
        switch (value) {
            case WahlkarteUnterschriftEnum.Buergermeister:
                return 'Bürgermeister/in';
            case WahlkarteUnterschriftEnum.Wahlleiter:
                return 'Wahlleiter/in';
            case WahlkarteUnterschriftEnum.Sachbearbeiter:
                return 'Sachbearbeiter/in';
        }
    }
}
