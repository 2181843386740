<div class="min-h-full px-4">
    <header>
        <k5-responsive-title>
            <k5-page-header header="Meldewesen - Suche" icon="fa-duotone fa-tasks fg-primary">
            </k5-page-header>
        </k5-responsive-title>
    </header>
    <main>
        <k5-section-header [title]="'Einwohner'" icon="fa-tasks"></k5-section-header>
    </main>
</div>
