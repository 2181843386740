/**
 * Antragsarten für eine Wahlkarte
 */
export enum AntragsartEnum {
    /**
     * Persönliche Beantragung
     */
    Persoenlich = 0,

    /**
     * Schriftlicher Antrag
     */
    Schriftlich = 1,

    /**
     * Elektronischer Antrag über das Portal
     * wird automatisch gesetzt, wenn über das Portal beantragt wird
     */
    ElektPortal = 2,

    /**
     * Abo für Wahlkarten (Auslandsösterreicher oder Bettlägrige)
     * wird nicht über den Wahlkartenantrag gesetzt
     */
    Abo = 3
}

/**
 * Möglichkeiten der Zustellart
 */
export enum ZustellartEnum {
    /**
     * Wird persönlich abgeholt
     */
    Persoenlich = 0,
    /**
     * wird mit Einschreibung per Post versendet
     */
    Einschreiben = 1,
    /**
     * wird per Standardpost versendet
     */
    Standardpost = 2,
    /**
     * Übernahme durch andere Personen (Vollmacht)
     */
    AnderePerson = 3,
    /**
     * wird durch einen Boten überbracht
     */
    DurchBoten = 4,
    /**
     * RSb-Einschreiben, wird für Wahlen in Niederösterreich verwendet
     * Aktuell keine Logik für Wahlunterscheidung vorhanden
     */
    EinschreibenRSb = 5,
    /**
     * Per RSa-RSb, wird für Wahlen in Burgenland verwendet
     */
    PerRSbRSa = 6
}
