import { Pipe, PipeTransform } from '@angular/core';
import { WaehlerHerkunft } from '@shared/models/enums';

@Pipe({
    name: 'waehlerHerkunft'
})
export class WaehlerHerkunftPipe implements PipeTransform {

    // Obsolete wird durch enum PersonenTyp ersetzt
    transform(value: WaehlerHerkunft): string {
        switch (value) {
            case WaehlerHerkunft.Oesterreicher:
                return 'Österreicher';
            case WaehlerHerkunft.EUBuerger:
                return 'EU Bürger';
            case WaehlerHerkunft.Auslandsoesterreicher:
                return 'Auslandsösterreicher';
            default:
                return 'Alle';
        }
    }
}
