import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalEditService } from '@core/services/global-edit.service';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'k5-unsaved-changes-dialog',
    templateUrl: './unsaved-changes-dialog.component.html'
})
export class UnsavedChangesDialogComponent {
    // Gibt an, ob die Seite verlassen werden darf, oder nicht
    private leaveSite = new Subject<boolean>();

    leaveSiteObservable: Observable<boolean>;

    constructor(
        private globalEditService: GlobalEditService,
        private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
    ) {
        this.leaveSiteObservable = this.leaveSite.asObservable();
    }

    /**
     * Schließt den Bearbeitungsmodus und alle Dialoge, wenn Dialog akzeptiert wurde
     */
    handleDialogAction(accepted: boolean) {
        if (accepted) {
            this.globalEditService.switchToReadMode();
            this.leaveSite.next(true);
        } else {
            this.leaveSite.next(false);
        }
        this.dialogRef.close();
    }
}
