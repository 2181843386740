<div class="flex h-full flex-col">
    <!-- drag-handle class defines area which is used for dragging the widget -->
    <div *ngIf="title || showDelete" class="flex h-12 items-center">
        <div class="drag-handler flex h-12 flex-grow items-center pl-4 text-gray-500 text-base">
            {{ title }}
        </div>
        <button
            mat-icon-button
            type="button"
            aria-label="entfernen"
            matTooltip="entfernen"
            *ngIf="showDelete"
            (click)="removeWidget($event)"
            data-cy="widget-delete-button"
        >
            <mat-icon fontSet="fa-solid" fontIcon="fa-xmark" class="text-base fg-gray"></mat-icon>
        </button>
    </div>
    <!-- gridster-item-content class ist nicht dragable -->
    <div class="gridster-item-content flex flex-grow flex-col overflow-auto p-4">
        <ng-content></ng-content>
    </div>
</div>
