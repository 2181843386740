/**
 * k5 Bürgerservice - Kontaktmanagement
 * Öffentliche API-Schnittstelle für das Kontaktmanagement vom k5 Bürgerservice
 *
 * OpenAPI spec version: v1
 * Contact: info@programmierfabrik.at
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Adresstyp = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const Adresstyp = {
    ZMRHauptwohnsitz: 1 as Adresstyp,
    ZMRNebenwohnsitz: 2 as Adresstyp,
    ErnPAnschrift: 3 as Adresstyp,
    URFirmensitz: 4 as Adresstyp,
    URZustelladresse: 5 as Adresstyp,
    ManuellHauptadresse: 6 as Adresstyp,
    ManuellZustelladresse: 7 as Adresstyp
};
