import { Pipe, PipeTransform } from '@angular/core';
import { WaehlerStatus } from '@shared/models/enums';

/**
 * Konvertiert die Enum-Werte des Typs WaehlerStatus
 * in eine passende textuelle Darstellung für das UI.
 */
@Pipe({
    name: 'waehlerStatus'
})
export class WaehlerStatusPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value WaehlerStatus
     */
    transform(value: WaehlerStatus): string {
        switch (value) {
            case WaehlerStatus.Gestrichen:
                return 'Gestrichen';
            case WaehlerStatus.Hineinreklamiert:
                return 'Hineinreklamiert';
            case WaehlerStatus.Verstorben:
                return 'Verstorben';
        }
    }
}
