// @ts-strict-ignore
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalEditService } from '@core/services/global-edit.service';

@Component({
    selector: 'k5-editable-card',
    templateUrl: './editable-card.component.html',
    styleUrls: ['./editable-card.component.scss']
})
export class EditableCardComponent {

    @Input()
    showEdit = true;

    @Input()
    enableEdit = true;

    @Input()
    showDelete = true;

    @Input()
    enableDelete = true;

    @Input()
    showCustomButton = false;

    @Input()
    enableCustomButton = false;

    @Input()
    customButtonTooltip: string;

    @Input()
    customButtonIcon: string;

    @Output()
    editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    deleteClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    customButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private globalEditService: GlobalEditService
    ) { }

    /**
     * Benachrichtigt Eltern Komponente wenn delete aufgerufen wurde
     */
    deleteItem(): void {
        this.deleteClicked.emit(true);
    }

    /**
     * Benachrichtigt Eltern Komponente wenn edit aufgerufen wurde
     */
    editItem(): void {
        this.editClicked.emit(true);
    }

    /**
     * Benachrichtigt Eltern Komponente wenn der custom Button aufgerufen wurde
     */
    customAction(): void {
        this.customButtonClicked.emit(true);
    }

    /**
     * Gibt zurück, ob die Action Buttons dargestellt werden sollen
     */
    get actionButtonsVisible() {
        return !this.globalEditService.isEditing;
    }
}
