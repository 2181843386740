import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogResult } from '@shared/models/dialogResult';

//#region DateTimeDialogData
export interface DateTimeDialogData {
    title: string;
    /**
     * Optionaler Text
     */
    textContent: string | null;
    /**
     * Optionaler Text für den primären Button
     * @remarks defaultwert 'Speichern'
     */
    primaryButtonLabel: string | null;
    /**
     * Optionaler Text für den sekundären Button
     * @remarks defaultwert 'Abbrechen'
     */
    secondaryButtonLabel: string | null;
    /**
     * Optionaler Text für das Label des Inputfelds
     * @remarks label wird nicht angezeigt wenn wert null ist
     */
    inputFieldLabel: string | null;
    /**
     * Flag, ob der primäre Button disabled ist
     */
    primaryButtonDisabled: boolean;
    /**
     * Flag, ob der sekundäre Button disabled ist
     */
    secondaryButtonDisabled: boolean;
    /**
     * Flag, ob der sekundäre Button angezeigt wird
     */
    secondaryButtonHidden: boolean;
    /**
     * Flag, ob beim DateTime picker die Sekunden ausgewählt werden können
     * @remarks wenn false wird das initialDate auf die vorherige Minute **abgerundet**
     */
    showSeconds: boolean;
    /**
     * Optionales Datum, mit welchem der Datetimepicker vorbefüllt wird
     */
    initialDateTime: Date | null;
    /**
     * Optionales Datum, nach welchem die Untergrenze des auswählbaren Datums eingeschränkt wird
     */
    minDateTime: Date | null;
    /**
     * Optionales Datum, nach welchem die Obergrenze des auswählbaren Datums eingeschränkt wird
     */
    maxDateTime: Date | null;
    /**
     * Optionaler Text, welcher bei Verletzung des Minimums angezeigt wird
     * @remarks defaultwert: 'Der Zeitpunkt kann nicht vor dem Minimum liegen.'
     */
    minErrorMessage: string | null;
    /**
     * Optionaler Text, welcher bei Verletzung des Maximums angezeigt wird
     * @remarks defaultwert: 'Der Zeitpunkt kann nicht nach dem Maximum liegen.'
     */
    maxErrorMessage: string | null;
}
//#endregion

@Component({
    selector: 'k5-date-time-dialog',
    templateUrl: './date-time-dialog.component.html',
    styleUrl: './date-time-dialog.component.scss'
})
export class DateTimeDialogComponent implements OnInit {
    // Defaultwerte, wenn optionale properties im DialogData objekt null sind
    primaryButtonLabel: string = 'Speichern';

    secondaryButtonLabel: string = 'Abbrechen';

    showSecondaryButton: boolean = true;

    minDateTime: Date | null = null;

    maxDateTime: Date | null = null;

    minErrorMessage: string = 'Der Zeitpunkt kann nicht vor dem Minimum liegen.';

    maxErrorMessage: string = 'Der Zeitpunkt kann nicht nach dem Maximum liegen.';

    dateParseString: string = 'yyyy-MM-ddTHH:mm:ss';

    /**
     * Event emitter welcher das Ergebnis des Dialogs liefert
     * @emits DialogResult:
     * - Flag accepted: true wenn ein Datum übergeben wird, false wenn abgebrochen wurde
     * - Date oder null
     */
    @Output()
    resultDateTime: EventEmitter<DialogResult<Date | null>> = new EventEmitter<DialogResult<Date | null>>();

    // Form zur validierung der Eingabe als string, da input vom type datetime-local nur strings als value akzeptiert
    dateTimeForm = this.formBuilder.group({
        dateTimeFormControl: new FormControl<string | null>(null, Validators.required)
    });

    // ErrorStateMatcher wird überschrieben, damit Fehlermeldungen auch ohne touched Form angezeigt werden
    matcher: ErrorStateMatcher = { isErrorState: () => this.validateDateTime() };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DateTimeDialogData,
        private readonly formBuilder: FormBuilder,
        private readonly datePipe: DatePipe
    ) {}

    // Übernahme der Daten aus dem injektierten MAT_DIALOG_DATA objekt
    ngOnInit(): void {
        if (this.data) {
            this.primaryButtonLabel = this.data.primaryButtonLabel ?? this.primaryButtonLabel;
            this.secondaryButtonLabel = this.data.secondaryButtonLabel ?? this.secondaryButtonLabel;
            this.showSecondaryButton = this.data.secondaryButtonHidden ?? this.showSecondaryButton;
            this.minDateTime = this.data.minDateTime;
            this.maxDateTime = this.data.maxDateTime;
            this.minErrorMessage = this.data.minErrorMessage ?? this.minErrorMessage;
            this.maxErrorMessage = this.data.maxErrorMessage ?? this.maxErrorMessage;
            this.dateParseString = this.data.showSeconds ? 'yyyy-MM-ddTHH:mm:ss' : 'yyyy-MM-ddTHH:mm';

            // Wenn ein initiales Datum mitgegeben wurde, wird das Form damit vorbefüllt
            if (this.data.initialDateTime) {
                this.dateTimeForm.controls.dateTimeFormControl.patchValue(
                    this.datePipe.transform(this.data.initialDateTime, this.dateParseString)
                );
            }
        }
    }

    /**
     * Validiert Formcontrol bei Eingabe eines Datums
     * @returns true wenn form einen Fehler behinhalted, sonst false
     */
    validateDateTime(): boolean {
        if (this.dateTimeForm.controls.dateTimeFormControl.value) {
            const selectedDateTime = new Date(this.dateTimeForm.controls.dateTimeFormControl.value);
            const minDateTime = new Date(this.minDateTime ?? new Date());
            const maxDateTime = new Date(this.maxDateTime ?? new Date());

            if (this.minDateTime && selectedDateTime < minDateTime) {
                this.dateTimeForm.controls.dateTimeFormControl.setErrors({
                    'invalidDate': this.minErrorMessage
                });
            } else if (this.maxDateTime && selectedDateTime > maxDateTime) {
                this.dateTimeForm.controls.dateTimeFormControl.setErrors({
                    'invalidDate': this.maxErrorMessage
                });
            } else {
                this.dateTimeForm.controls.dateTimeFormControl.setErrors(null);
                return false;
            }
        }
        return true;
    }

    /**
     * Benachrichtigt andere Komponente nach Primary Button Klick
     * @fires resultDateTime mit Date result
     */
    handleConfirmButton() {
        if (
            this.dateTimeForm.controls.dateTimeFormControl.valid &&
            this.dateTimeForm.controls.dateTimeFormControl.value
        ) {
            const dialogResult: DialogResult<Date> = {
                accepted: true,
                dialogData: new Date(this.dateTimeForm.controls.dateTimeFormControl.value)
            };

            this.resultDateTime.emit(dialogResult);
        }
    }

    /**
     * Benachrichtigt andere Komponente nach Secondary Button Klick
     * @fires resultDateTime mit null result
     */
    handleAbortButton() {
        const dialogResult: DialogResult<null> = {
            accepted: false,
            dialogData: null
        };

        this.resultDateTime.emit(dialogResult);
    }
}
