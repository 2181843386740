<div class="edit-section-container">
    <k5-section-header [title]="heading" [icon]="icon">
        <div class="flex">
            <div *ngIf="isEditPencilVisible" class="ml-2">
                <button
                    type="button"
                    aria-label="Bearbeiten"
                    [matTooltip]="heading + ' bearbeiten'"
                    id="editButton"
                    class="actionIcon"
                    mat-icon-button
                    (click)="onEditButtonClicked($event)"
                >
                    <mat-icon fontSet="fa-duotone" fontIcon="fa-pencil" class="fg-primary text-base"></mat-icon>
                </button>
            </div>
        </div>
    </k5-section-header>
</div>
