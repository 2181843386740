import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
    BasicDialogWrapperComponent,
    DialogActionsComponent,
    DialogContentComponent,
    DialogHeadingComponent,
    KontaktpersonArtToStringPipe,
    LoadingComponent,
    RegistergebundenPipe,
    RichTooltipDirective,
    SearchPanelComponent
} from '@k5next/ui-components';
import {
    AddWidgetDialogComponent,
    MeineWahlkarteAntragsartWidgetComponent,
    MeineWahlkarteWidgetComponent,
    TimelineWidgetComponent,
    WahlberechtigteAktuellWidgetComponent,
    WahlkartenAntragsartWidgetComponent,
    WahlkartenstatistikWidgetComponent,
    WidgetComponent
} from '@shared/widgets';
import { NgChartsModule } from 'ng2-charts';
import { AdresstypPipe } from '../buergerservice/kontaktmanagement/pipes/adresstyp.pipe';
import { MaterialModule } from '../material/material.module';
import { AvatarPhotoComponent } from './components/avatar-photo/avatar-photo.component';
import { BegruendungDialogComponent } from './components/begruendung-dialog/begruendung-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DateTimeDialogComponent } from './components/date-time-dialog/date-time-dialog.component';
import { DetailSearchWahlComponent } from './components/detail-search-wahl/detail-search-wahl.component';
import { EditSectionModalComponent } from './components/edit-section-modal/edit-section-modal.component';
import { EditSectionComponent } from './components/edit-section/edit-section.component';
import { EditableCardComponent } from './components/editable-card/editable-card.component';
import { EditableListItemComponent } from './components/editable-list-item/editable-list-item.component';
import { ErrorComponent } from './components/error/error.component';
import { FabMenuComponent } from './components/fab-menu-button/fab-menu.component';
import { FabRightBottomComponent } from './components/fab-right-bottom/fab-right-bottom.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { LoadingComponent as K5LoadingComponent } from './components/loading/loading.component';
import { MandantDarstellungComponent } from './components/mandant-darstellung/mandant-darstellung.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PersonenartTableIconComponent } from './components/personenart-table-icon/personenart-table-icon.component';
import { QuickSearchItemComponent } from './components/quick-search-item/quick-search-item.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { ResponsiveTitleComponent } from './components/responsive-title/responsive-title.component';
import { SearchPanelContainerComponent } from './components/search-panel-container/search-panel-container.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { ShareActionItemComponent } from './components/share-action-item/share-action-item.component';
import { SmallSectionHeaderComponent } from './components/small-section-header/small-section-header.component';
import { UserShareDialogComponent } from './components/user-share-dialog/user-share-dialog.component';
import { ZustelladresseDialogComponent } from './components/zustelladresse-dialog/zustelladresse-dialog.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { StopEnterPropagationDirective } from './directives/stop-enter-propagation.directive';
import { TrackClickDirective } from './directives/track-click.directive';
import { ContactIconPipe } from './pipes/contact-icon.pipe';
import { DatenabgleichPipe } from './pipes/datenabgleich.pipe';
import { ElementTypePipe } from './pipes/element-type.pipe';
import { EnumKeysPipe } from './pipes/enum-keys.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { IdentitaetsNachweisPipe } from './pipes/identitaets-nachweis.pipe';
import { JaNeinEnumPipe } from './pipes/ja-nein-enum.pipe';
import { JaNeinPipe } from './pipes/ja-nein.pipe';
import { MandantServiceUrlPipe } from './pipes/mandant-service-url.pipe';
import { NotificationDatePipe } from './pipes/notification-date.pipe';
import { NullableBooleanPipe } from './pipes/nullable-boolean.pipe';
import { OffeneBearbeitungenPipe } from './pipes/offene-bearbeitungen.pipe';
import { PersonArtPipe } from './pipes/person-art.pipe';
import { KontaktpersonArtToPersonenartPipe, PersonenArtToKontaktpersonArtPipe } from './pipes/personen-art.pipe';
import { PersonenTypPipe } from './pipes/personen-typ.pipe';
import { SchnellsucheKategoriePipe } from './pipes/schnellsuche-kategorie.pipe';
import { ShowBooleanPipe } from './pipes/show-boolean.pipe';
import { SimpleEnumToArrayPipe } from './pipes/simple-enum-to-array.pipe';
import { SortierungWvzPipe } from './pipes/sortierung-wvz.pipe';
import { SuchArtPipe } from './pipes/such-art.pipe';
import { SucheWahlkartenStatusPipe } from './pipes/suche-wahlkarten-status.pipe';
import { TextOverflowPipe } from './pipes/text-overflow.pipe';
import { WaehlerHerkunftPipe } from './pipes/waehler-herkunft.pipe';
import { WaehlerStatusPipe } from './pipes/waehler-status.pipe';
import { WahlkartenStatusPipe } from './pipes/wahlkarten-status.pipe';
import { WidgetTypePipe } from './pipes/widget-type.pipe';
import { WkAntragsartPipe } from './pipes/wk-antragsart.pipe';
import { WkUnterschriftPipe } from './pipes/wk-unterschrift.pipe';
import { WkZustellartPipe } from './pipes/wk-zustellart.pipe';
import { WkaAuthentifizierungsartPipe } from './pipes/wka-authentifizierungsart.pipe';
import { ZmrAbgleichStatusPipe } from './pipes/zmr-abgleich-status.pipe';
import { ZmrAdressTypPipe } from './pipes/zmr-adresstyp.pipe';
import { ZustelladresseVorhandenPipe } from './pipes/zustelladresse.pipe';

@NgModule({
    declarations: [
        QuickSearchComponent,
        HighlightPipe,
        SchnellsucheKategoriePipe,
        QuickSearchItemComponent,
        ElementTypePipe,
        SectionHeaderComponent,
        GenderPipe,
        ContactIconPipe,
        ResponsiveTitleComponent,
        EnumKeysPipe,
        SuchArtPipe,
        FabMenuComponent,
        AutoFocusDirective,
        WidgetTypePipe,
        FabRightBottomComponent,
        TimelineWidgetComponent,
        PageHeaderComponent,
        HeaderSearchComponent,
        DetailSearchWahlComponent,
        WaehlerHerkunftPipe,
        NullableBooleanPipe,
        ListFilterComponent,
        ShowBooleanPipe,
        EnumToArrayPipe,
        WaehlerStatusPipe,
        OffeneBearbeitungenPipe,
        WahlkartenStatusPipe,
        ConfirmationDialogComponent,
        DateTimeDialogComponent,
        ZustelladresseDialogComponent,
        ZustelladresseDialogComponent,
        ErrorComponent,
        BegruendungDialogComponent,
        SimpleEnumToArrayPipe,
        EditSectionComponent,
        SortierungWvzPipe,
        MandantDarstellungComponent,
        PersonArtPipe,
        PersonenartTableIconComponent,
        EditableListItemComponent,
        JaNeinEnumPipe,
        EditableCardComponent,
        WkUnterschriftPipe,
        UserShareDialogComponent,
        AvatarPhotoComponent,
        NotificationDatePipe,
        TrackClickDirective,
        WkaAuthentifizierungsartPipe,
        JaNeinPipe,
        SmallSectionHeaderComponent,
        ShareActionItemComponent,
        TextOverflowPipe,
        PersonenTypPipe,
        IdentitaetsNachweisPipe,
        WahlkartenstatistikWidgetComponent,
        WahlberechtigteAktuellWidgetComponent,
        WahlkartenAntragsartWidgetComponent,
        MeineWahlkarteWidgetComponent,
        SucheWahlkartenStatusPipe,
        MeineWahlkarteAntragsartWidgetComponent,
        DatenabgleichPipe,
        ZmrAbgleichStatusPipe,
        WkAntragsartPipe,
        WkZustellartPipe,
        AddWidgetDialogComponent,
        ZmrAdressTypPipe,
        AdresstypPipe,
        EditSectionModalComponent,
        MandantServiceUrlPipe,
        ZustelladresseVorhandenPipe,
        SearchPanelContainerComponent,
        KontaktpersonArtToPersonenartPipe,
        PersonenArtToKontaktpersonArtPipe,
        K5LoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgChartsModule,
        BasicDialogWrapperComponent,
        DialogHeadingComponent,
        DialogContentComponent,
        DialogActionsComponent,
        LoadingComponent,
        RichTooltipDirective,
        WidgetComponent,
        SearchPanelComponent,
        KontaktpersonArtToStringPipe,
        RegistergebundenPipe,
        StopEnterPropagationDirective
    ],
    exports: [
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        QuickSearchComponent,
        SectionHeaderComponent,
        LoadingComponent,
        GenderPipe,
        ContactIconPipe,
        ResponsiveTitleComponent,
        EnumKeysPipe,
        FabMenuComponent,
        WidgetTypePipe,
        FabRightBottomComponent,
        WidgetComponent,
        TimelineWidgetComponent,
        PageHeaderComponent,
        HeaderSearchComponent,
        DetailSearchWahlComponent,
        ListFilterComponent,
        ShowBooleanPipe,
        NullableBooleanPipe,
        EnumToArrayPipe,
        ConfirmationDialogComponent,
        DateTimeDialogComponent,
        IdentitaetsNachweisPipe,
        ErrorComponent,
        BegruendungDialogComponent,
        SimpleEnumToArrayPipe,
        WaehlerHerkunftPipe,
        NullableBooleanPipe,
        WahlkartenStatusPipe,
        EditSectionComponent,
        EditSectionModalComponent,
        SortierungWvzPipe,
        MandantDarstellungComponent,
        PersonenartTableIconComponent,
        EditableListItemComponent,
        EditableCardComponent,
        WkUnterschriftPipe,
        AvatarPhotoComponent,
        NotificationDatePipe,
        TrackClickDirective,
        JaNeinEnumPipe,
        WkaAuthentifizierungsartPipe,
        JaNeinPipe,
        ShareActionItemComponent,
        SmallSectionHeaderComponent,
        TextOverflowPipe,
        WahlkartenstatistikWidgetComponent,
        K5LoadingComponent,
        WahlberechtigteAktuellWidgetComponent,
        WahlkartenAntragsartWidgetComponent,
        MeineWahlkarteWidgetComponent,
        MeineWahlkarteAntragsartWidgetComponent,
        WkAntragsartPipe,
        WkZustellartPipe,
        AddWidgetDialogComponent,
        ZmrAdressTypPipe,
        MandantServiceUrlPipe,
        ZustelladresseVorhandenPipe,
        AdresstypPipe,
        PersonArtPipe,
        SearchPanelContainerComponent,
        RegistergebundenPipe,
        KontaktpersonArtToPersonenartPipe,
        QuickSearchItemComponent
    ]
})
export class SharedModule {}
