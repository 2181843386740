// @ts-strict-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CapabilitiesService {

    config: AppConfig = null;

    private generalCapabilitiesSubject: BehaviorSubject<GeneralCapabilities | null> = new BehaviorSubject(null);
    generalCapabilities$: Observable<GeneralCapabilities | null> = this.generalCapabilitiesSubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigAssetLoaderService) {
    }

    /**
     * Gibt alle capabilities zurück
     * @returns capabilities
     */
    getGeneralCapabilities(): Observable<GeneralCapabilities> {

        return this.httpClient.get<GeneralCapabilities>(`${this.configService.getConfig().k5civisKm.kontaktmanagementUiApiBaseUrl}/Capabilities`);
    }

    /**
     * Setzt die capabilities
     * @param capabilities capabilities
     */
    setCapabilities(capabilities: GeneralCapabilities): void {

        this.generalCapabilitiesSubject.next(capabilities);
    }
}
