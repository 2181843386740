// @ts-strict-ignore
import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggingConstants } from '@shared/constants/shared-constants';
import { LoggingService } from '@shared/services/logging.service';

@Directive({
  selector: '[k5TrackClick]'
})
export class TrackClickDirective {

    constructor(private loggingService: LoggingService, private route: ActivatedRoute) {
    }

    /**
     * Loggt das ClickEvent mit dem Component- und Elementdaten.
     * @param event Event-Objekt mit den Details
     */
    @HostListener('click', ['$event'])
    public clickEvent(event): void {
        // TODO: Parameter event typisieren, sollte PointerEvent sein (lt. Browser)
        const componentName = this.route.routeConfig.component.name;
        const elementName = event.currentTarget.localName;
        const clickedElementId = event.srcElement.id;
        const clickedElementName = event.srcElement.localName;
        this.loggingService.logEvent(LoggingConstants.UI_ELEMENT_CLICKED, {
            componentName,
            elementName,
            clickedElementId,
            clickedElementName
        });
    }
}
