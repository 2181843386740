<k5-basic-dialog-wrapper [heading]="data.title" [saveDisabled]="saveButtonDisabled" (saveClick)="save()">
    <k5-loading *ngIf="this.loading; else content" message="Benutzer werden geladen ..."></k5-loading>
    <ng-template #content>
        <k5-header-search [centerSearch]="true" (inputChanged)="searchUsers($event)"></k5-header-search>
        <div class="mt-4 flex flex-grow flex-col overflow-auto">
            <div *ngIf="data.allowMultipleSelection" class="select-all-row p-4">
                <!-- Checkbox für eine Selektion aller Zeilen -->
                <mat-checkbox
                    (change)="$event ? selectAll() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                    <i class="fa-duotone fa-users fg-gray ml-3 mr-5"></i>
                    Alle
                </mat-checkbox>
            </div>
            <div *ngFor="let user of displayedUserList; let i = index">
                <div
                    class="pb-2 pt-2 text-lg font-bold"
                    data-cy="user-bereits-geteilt-mit-text"
                    *ngIf="checkIfAlreadySharedShouldBeDisplayed(i)"
                >
                    Bereits geteilt mit:
                </div>

                <!-- Buchstabe für die Sortierung -->
                <div
                    class="pb-2 pt-2 text-lg font-bold"
                    *ngIf="checkIfSortingCharShouldBeDisplayed(i, displayedUserList[i - 1], displayedUserList[i])"
                >
                    {{ displayedUserList[i].data.familienname.charAt(0) }}
                </div>

                <div
                    class="user-container pb-2 pl-4 pr-4 pt-2"
                    (click)="toggleSelection(user)"
                    [ngClass]="userSelected(user) ? 'user-selected' : ''"
                    data-cy="user-option"
                >
                    <!-- Checkbox zur Auswahl des Users -->
                    <mat-checkbox
                        class="pr-4"
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelection(user)"
                        [checked]="userSelected(user)"
                    >
                    </mat-checkbox>

                    <!-- Generierter Avatar des Users -->
                    <k5-avatar-photo
                        [firstName]="user.data.vorname"
                        [lastName]="user.data.familienname"
                    ></k5-avatar-photo>

                    <!-- Name des Users -->
                    <div class="flex flex-col truncate pl-4">
                        <span>
                            {{ user.data.nameSort }}
                        </span>
                        <span class="fg-gray truncate text-sm">
                            {{ user.data.email }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</k5-basic-dialog-wrapper>
