<div class="flex h-12 items-center">
    <!-- Filter Button -->
    <button
        mat-button
        id="list-filter-toggle-btn"
        type="button"
        (click)="toggleFilter()"
        class="flex h-12"
        data-cy="filter-button"
    >
        <i class="fa-solid fa-filter" aria-hidden="true"></i>
        <span class="pl-2 text-base">{{ anzahlFilterAktiv === 0 ? 'Kein' : anzahlFilterAktiv }} Filter aktiv</span>
        <i class="fa-solid pl-2" [ngClass]="filterClicked ? 'fa-chevron-up' : 'fa-chevron-down'" aria-hidden="true"></i>
    </button>
    <!-- Alle Filter deaktivieren Button -->
    <button
        mat-icon-button
        id="list-filter-reset-btn"
        type="button"
        aria-label="Filter deaktivieren"
        (click)="resetFilter()"
        *ngIf="anzahlFilterAktiv > 0"
    >
        <mat-icon fontSet="fa-solid" fontIcon="fa-times" class="text-base"></mat-icon>
    </button>
</div>
<!-- Filteroptionen -->
<div id="list-filter-category-container" class="flex flex-col pl-2 pt-2" *ngIf="filterClicked">
    <div *ngFor="let filterCategory of filterCategories; let filterCategoryIndex = index" class="flex pt-2">
        <div class="pr-4 pt-2">
            {{ filterCategory.name }}
        </div>
        <div class="flex flex-wrap gap-2">
            <button
                type="button"
                class="chip flex items-center px-4 py-2 text-base"
                *ngFor="let filterOption of filterCategory.filterOptions; let filterOptionIndex = index"
                (click)="optionSelected(filterCategoryIndex, filterOptionIndex)"
                [ngClass]="filterOption.selected ? 'chip-selected' : ''"
                data-cy="filter-option"
            >
                <i
                    *ngIf="filterOption.icon"
                    [ngClass]="'fa-duotone fa-' + filterOption.icon + ' fg- pr-2' + filterOption.iconColor"
                    aria-hidden="true"
                ></i>
                <div class="whitespace-nowrap">
                    {{ filterOption.name }}
                </div>
            </button>
        </div>
    </div>
</div>
