// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { ChartLegend } from '@shared/models/chart-legend';
import { DEFAULT_THEME } from '@shared/models/chartColors';
import { Authentifizierungsart } from '@shared/models/enums';
import { MeineWahlkarteIdentitaetsnachweisStatistikResponse } from '@shared/models/meineWahlkarteIdentitaetsnachweisStatistikResponse';
import { WahlkartenantraegeSearchParameters } from '@shared/models/meineWahlkarteSearchParameters';
import { Wahldaten } from '@shared/models/wahldaten';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { ChartOptions } from 'chart.js';
import { WahldetailService } from 'src/app/buergerservice/wahl/services/wahldetail.service';

@Component({
    selector: 'k5-meine-wahlkarte-antragsart-widget',
    templateUrl: './meine-wahlkarte-antragsart-widget.component.html'
})
export class MeineWahlkarteAntragsartWidgetComponent implements OnInit {
    @Input()
    wahl: Wahldaten | null = null;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    meineWahlkarteStatistik: MeineWahlkarteIdentitaetsnachweisStatistikResponse = null;

    dataSource: ChartLegend[] = [];

    // Bar-Chart
    pieChartOptions: ChartOptions<'pie'> = null;
    pieChartLabels = null;
    pieChartDatasets = null;
    pieChartLegend = false;

    colorScheme = {
        domain: DEFAULT_THEME
    };

    error: boolean = false;
    loading: boolean = true;

    constructor(
        private wahlService: WahldetailService,
        private router: Router,
        private detailsucheService: DetailsucheService
    ) {}

    ngOnInit(): void {
        this.loadWidgetData();
    }

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }

    /**
     * Lädt die Daten für die Anzeige im Widget
     */
    loadWidgetData(): void {
        this.loading = true;
        this.error = false;
        this.wahlService.getMeineWahlkarteIdentitaetsnachweisStatistik(this.wahl?.id).subscribe({
            next: (data: MeineWahlkarteIdentitaetsnachweisStatistikResponse) => {
                this.meineWahlkarteStatistik = data;
                this.buildLegend(data);
                this.loading = false;
            },
            error: () => {
                this.error = true;
                this.loading = false;
            }
        });
    }

    /**
     * Baut die Daten für die Anzeige zusammen
     * @param data MeineWahlkarteIdentitaetsnachweisStatistik
     */
    buildLegend(data: MeineWahlkarteIdentitaetsnachweisStatistikResponse): void {
        const legend: ChartLegend[] = [
            {
                id: 1,
                name: 'Antragscode',
                amount: data.antragscode,
                color: DEFAULT_THEME[0]
            },
            {
                id: 2,
                name: 'Reisepassnummer',
                amount: data.reisepassnummer,
                color: DEFAULT_THEME[1]
            },
            {
                id: 4,
                name: 'Gescannter Lichtbildausweis',
                amount: data.lichtbildausweis,
                color: DEFAULT_THEME[2]
            },
            {
                id: 5,
                name: 'Qualifizierte Signatur',
                amount: data.idAustria,
                color: DEFAULT_THEME[3]
            }
        ];

        this.dataSource = legend;

        this.pieChartDatasets = [
            {
                data: legend.map((x) => x.amount),
                backgroundColor: DEFAULT_THEME
            }
        ];
        this.pieChartLabels = legend.map((x) => x.name);

        this.pieChartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            animation: false
        };
    }

    /**
     * Navigiert zu den Wahlkartenanträgen mit den vordefinierten Suchparametern
     */
    navigateToMeineWahlkarte(item: ChartLegend): void {
        const key = this.detailsucheService.generateSessionStorageKey();

        const searchParameters: WahlkartenantraegeSearchParameters = {
            statusFilter: [],
            identitaetsnachweisTypen: []
        };

        switch (item.id) {
            case 1:
                searchParameters.identitaetsnachweisTypen = [Authentifizierungsart.Antragscode];
                break;
            case 2:
                searchParameters.identitaetsnachweisTypen = [Authentifizierungsart.Reisepassnummer];
                break;
            case 4:
                searchParameters.identitaetsnachweisTypen = [Authentifizierungsart.Lichtbildausweis];
                break;
            case 5:
                searchParameters.identitaetsnachweisTypen = [Authentifizierungsart.IdAustria];
                break;
        }

        this.detailsucheService.saveSearchInStorage<WahlkartenantraegeSearchParameters>(searchParameters, key);

        this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id, PathConstants.MEINE_WAHLKARTE], {
            queryParams: { searchKey: key }
        });
    }
}
