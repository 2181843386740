<div class="button-fixed-bottom">
    <button
        mat-fab
        type="button"
        [matTooltip]="tooltip"
        (click)="fabClicked($event)"
        data-cy="fab-right-bottom-component-button-right-bottom-button"
    >
        <i class="fa-solid text-2xl" [ngClass]="icon ? icon : 'fa-plus'"></i>
    </button>
</div>
