// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormErrorMessageService {
    /**
     * Behandelt die Error Nachricht
     * @returns Error Nachricht
     */
    getFormErrorMessage(formcontrolName: string, form: UntypedFormGroup | FormGroup, displayName: string): string {
        if (form.get(formcontrolName).hasError('required')) {
            return displayName + ' wurde nicht angegeben';
        } else if (form.get(formcontrolName).hasError('pattern') && form.get(formcontrolName).hasError('maxlength')) {
            return (
                displayName +
                ' ist im falschen Format und darf maximal ' +
                form.get(formcontrolName).getError('maxlength')?.requiredLength +
                ' Zeichen lang sein'
            );
        } else if (form.get(formcontrolName).hasError('pattern')) {
            return displayName + ' ist im falschen Format';
        } else if (form.get(formcontrolName).hasError('maxlength')) {
            return (
                displayName +
                ' darf maximal ' +
                form.get(formcontrolName).getError('maxlength')?.requiredLength +
                ' Zeichen lang sein'
            );
        } else if (form.get(formcontrolName).hasError('minlength')) {
            return (
                displayName +
                ' muss mindestens ' +
                form.get(formcontrolName).getError('minlength')?.requiredLength +
                ' Zeichen lang sein'
            );
        } else if (form.get(formcontrolName).hasError('max')) {
            return (
                displayName + ' muss kleiner oder gleich ' + form.get(formcontrolName).getError('max')?.max + ' sein'
            );
        } else if (form.get(formcontrolName).hasError('min')) {
            return displayName + ' muss größer oder gleich ' + form.get(formcontrolName).getError('min')?.min + ' sein';
        }

        return displayName + ' muss angegeben werden';
    }

    /**
     * Gibt alle vorhandenen Fehler als string Array zurück
     * @param formControl Form Control
     * @param displayName Name, der im Fehler dargestellt wird
     */
    getFormControlErrorMessages(
        formControl: FormControl | UntypedFormControl | AbstractControl,
        displayName: string
    ): string[] {
        const errorMessages: string[] = [];

        if (formControl.hasError('required')) {
            errorMessages.push(displayName + ' wurde nicht angegeben');
        } else if (formControl.hasError('pattern')) {
            errorMessages.push(displayName + ' ist im falschen Format');
        } else if (formControl.hasError('maxlength')) {
            errorMessages.push(
                displayName +
                    ' darf maximal ' +
                    formControl.getError('maxlength')?.requiredLength +
                    ' Zeichen lang sein'
            );
        } else if (formControl.hasError('minlength')) {
            errorMessages.push(
                displayName +
                    ' muss mindestens ' +
                    formControl.getError('minlength')?.requiredLength +
                    ' Zeichen lang sein'
            );
        } else if (formControl.hasError('max')) {
            errorMessages.push(displayName + ' muss kleiner oder gleich ' + formControl.getError('max')?.max + ' sein');
        } else if (formControl.hasError('min')) {
            errorMessages.push(displayName + ' muss größer oder gleich ' + formControl.getError('min')?.min + ' sein');
        }

        return errorMessages;
    }
}
