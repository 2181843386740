import { Pipe, PipeTransform } from '@angular/core';
import { KontaktSuchTyp } from '@shared/models/enums';

@Pipe({
    name: 'suchArt'
})
export class SuchArtPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value KontaktSuchTyp
     */
    transform(value: KontaktSuchTyp): string {
        switch (value) {
            case KontaktSuchTyp.Alle:
                return 'alle Kontakte';
            case KontaktSuchTyp.NatuerlichePerson:
                return 'Personen';
            default:
                return 'Unternehmen';

        }
    }

}
