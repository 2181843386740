<!-- Progress indicator -->
<mat-spinner [diameter]="diameter" [strokeWidth]="strokeWidth"> </mat-spinner>

<!-- Message -->
<span
    id="loadingMessage"
    class="mt-4 text-gray-700"
    *ngIf="message"
    [ngClass]="{
        'text-xs': fontSize === 'small',
        'text-base': fontSize === 'normal',
        'text-2xl': fontSize === 'large'
    }"
>
    {{ message }}
</span>
