import { Pipe, PipeTransform } from '@angular/core';
import { AdressTypEnum } from '@shared/models/enums';

/**
 * Konvertiert Werte des Typs SchnellsucheErgebnisElementTypEnum
 * in eine passende textuelle Darstellung.
 */
@Pipe({
    name: 'zmrAdressTyp'
})
export class ZmrAdressTypPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value SchnellsucheErgebnisElementTypEnum
     */
    transform(value: AdressTypEnum): string {
        switch (value) {
            case AdressTypEnum.ZMRHauptwohnsitz:
                return 'HWS';
            case AdressTypEnum.ZMRNebenwohnsitz:
                return 'NWS';
            case AdressTypEnum.ZMRObdachlos:
                return 'ODL';
            default:
                return '';
        }
    }
}
