<k5-basic-dialog-wrapper
    heading="Dashboard Wahl - Widget hinzufügen"
    saveTitle="Hinzufügen"
    (saveClick)="addWidget()"
    data-cy="add-widget-dialog"
>
    <div class="h-[1px] w-full bg-gray-200"></div>
    <div class="flex flex-row divide-x overflow-hidden">
        <div class="w-96 overflow-y-auto break-all">
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="handleWidgetSelectionChange($event)"
                data-cy="add-widget-dialog-widget-auswahl"
            >
                <mat-list-option
                    [selected]="widget.selected"
                    [value]="widget"
                    class="border-b border-l-4 border-b-gray-200 border-l-transparent"
                    *ngFor="let widget of data?.widgets"
                >
                    <span class="truncate">{{ widget?.title }}</span></mat-list-option
                >
            </mat-selection-list>
        </div>
        <div class="flex w-[700px] grow flex-col gap-y-2 overflow-y-auto p-4">
            <div>
                <img alt="Widget Vorschau" class="max-h-80" [src]="selectedWidget?.previewImage" />
            </div>
            <span> {{ selectedWidget?.description ?? '' }}</span>
            <span>für folgende Wahl:</span>
            <mat-form-field class="w-full">
                <mat-label>Wahl</mat-label>
                <mat-select matInput [formControl]="wahlCtrl" data-cy="add-widget-dialog-wahl-auswahl">
                    <mat-option *ngFor="let wahl of data?.wahlen" [value]="wahl">{{ wahl.wahlbezeichnung }}</mat-option>
                </mat-select>
                <mat-error>
                    {{ formErrorService.getFormControlErrorMessages(wahlCtrl, 'Wahl') }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="h-[1px] w-full bg-gray-200"></div>
</k5-basic-dialog-wrapper>
