import { Route } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EinwohnerComponent } from './components/einwohner/einwohner.component';
import { SettingsComponent } from './components/settings/settings.component';
import {
    MELDEWESEN_DASHBOARD,
    MELDEWESEN_DASHBOARD_PATH,
    MELDEWESEN_EINWOHNER,
    MELDEWESEN_SETTINGS
} from './constants/meldewesen.constants';

export const MELDEWESEN_ROUTES: Route[] = [
    {
        path: MELDEWESEN_DASHBOARD,
        component: DashboardComponent
    },
    {
        path: MELDEWESEN_EINWOHNER,
        component: EinwohnerComponent
    },
    {
        path: MELDEWESEN_SETTINGS,
        component: SettingsComponent
    },
    {
        path: '**',
        redirectTo: MELDEWESEN_DASHBOARD_PATH
    }
];
