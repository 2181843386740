import { Pipe, PipeTransform } from '@angular/core';
import { Authentifizierungsart } from '../models/enums';

@Pipe({
    name: 'wkaAuthentifizierungsart'
})
export class WkaAuthentifizierungsartPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte der Authentifizierungsart in eine textuelle Form.
     * @param value ZustellartEnum
     */
    transform(value: Authentifizierungsart): string {
        switch (value) {
            case Authentifizierungsart.Antragscode:
                return 'Antragscode';
            case Authentifizierungsart.IdAustria:
                return 'ID-Austria';
            case Authentifizierungsart.Lichtbildausweis:
                return 'gescannter Lichtbildausweis';
            case Authentifizierungsart.Reisepassnummer:
                return 'Reisepass';
            default:
                return '';
        }
    }

}
