import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'k5-settings',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './settings.component.html'
})
export class SettingsComponent {}
