import { Pipe, PipeTransform } from '@angular/core';
import { SchnellsucheErgebnisElementTypEnum } from '../models/enums';

/**
 * Konvertiert Werte des Typs SchnellsucheErgebnisElementTypEnum
 * in eine passende textuelle Darstellung.
 */
@Pipe({
    name: 'elementType'
})
export class ElementTypePipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value SchnellsucheErgebnisElementTypEnum
     */
    transform(value: SchnellsucheErgebnisElementTypEnum): string {
        switch (value) {
            case SchnellsucheErgebnisElementTypEnum.NatuerlichePerson:
                return 'Natürliche Person';
            case SchnellsucheErgebnisElementTypEnum.Unternehmen:
                return 'Unternehmen';
            case SchnellsucheErgebnisElementTypEnum.Adresse:
                return 'Adresse';
            case SchnellsucheErgebnisElementTypEnum.Liste:
                return 'Liste';
            case SchnellsucheErgebnisElementTypEnum.Gruppe:
                return 'Gruppe';
            default:
                return `SchnellsucheErgebnisElementTypEnum Wert ${value} nicht übersetzbar!`;
        }
    }

}
