import { Pipe, PipeTransform } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { DateUtilsService } from '@shared/services/date-utils.service';
import { DateTime } from 'luxon';

@Pipe({
    name: 'notificationDate'
})
export class NotificationDatePipe implements PipeTransform {
    dateUtilsService = DateUtilsService;

    /**
     * Wandelt ein Datum in den gewünschten String um
     * @param notificationDate Datum als Date
     * @returns
     */
    transform(notificationDate: Date): string {
        if (notificationDate) {
            // Da im JSON vom Backend das Datum als String zurückgegeben wird
            // muss zuerst ein JavaScript-Date daraus gemacht werden.
            const date = new Date(notificationDate);
            const dateInput: DateTime = DateTime.fromJSDate(date);

            const dateInputDate = dateInput.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const dateNow = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            const dayDifference: number = Math.ceil(dateInputDate.diff(dateNow, 'days').days);
            if (dayDifference === 0) {
                return 'Heute ' + dateInput.setLocale(UiConstants.LOCALE_AT).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else if (dayDifference === -1) {
                return 'Gestern ' + dateInput.setLocale(UiConstants.LOCALE_AT).toLocaleString(DateTime.TIME_24_SIMPLE);
            }

            return dateInput.setLocale(UiConstants.LOCALE_AT).toLocaleString({
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } else {
            return '';
        }
    }
}
