import { Component, Input } from '@angular/core';

/**
 * Komponente für Bereichsüberschriften
 */
@Component({
    selector: 'k5-section-header',
    templateUrl: './section-header.component.html'
})
export class SectionHeaderComponent {
    /**
     * Titel
     */
    @Input()
    title = '';

    /**
     * FontAwesome icon
     */
    @Input()
    icon = '';
}
