<div class="flex gap-x-2">
    <!-- Icon vor der Schnellsuche -->
    <div class="search-k5next-logo"></div>

    <!-- Suchleiste mit Schnellvorschlägen -->
    <div class="grow">
        <!-- Wrapper der gesamten Suchleiste -->
        <div
            class="search-input-wrapper"
            [ngClass]="
                autocompleteOpen ? 'search-input-wrapper-autocomplete-open' : 'search-input-wrapper-autocomplete-closed'
            "
        >
            <!-- Suchicon -->
            <div class="search-icon-wrapper flex items-center justify-center px-3">
                <i class="fa-duotone fa-lg fa-search" aria-hidden="true"></i>
            </div>

            <!-- Eingabefeld -->
            <div class="relative flex grow">
                <input
                    #searchInput
                    class="search-input flex-1 border-none p-2 text-base focus:outline-none"
                    type="text"
                    placeholder="Suche ..."
                    [(ngModel)]="currentSearchInput"
                    [disabled]="rightSidenavigationService.rightSidenavOpen"
                    name="searchInput"
                    (keyup)="onKeyUp($event)"
                    (keyup.enter)="onEnter($event)"
                    autocomplete="off"
                    data-cy="quick-search-input"
                />
                <div
                    *ngIf="rightSidenavigationService.rightSidenavOpen"
                    class="input-overlay"
                    (click)="inputClick()"
                ></div>
            </div>

            <!-- Zusatzaktion um z.B. die Detailsuche zu öffnen -->
            <div
                matRipple
                class="action-icon-wrapper flex items-center justify-center px-3"
                *ngIf="detailSearchEnabled; else emptyAction"
                [matTooltip]="rightSidenavigationService.rightSidenavOpen ? 'Suche ausblenden' : 'Suche einblenden'"
                [ngClass]="
                    autocompleteOpen
                        ? 'action-icon-wrapper-autocomplete-open'
                        : 'action-icon-wrapper-autocomplete-closed'
                "
                (click)="extendSearch($event)"
            >
                <div *ngIf="!rightSidenavigationService.rightSidenavOpen; else closeRightSidenav">
                    <i class="fa-solid fa-lg fa-chevron-left" aria-hidden="true"></i>
                </div>
                <ng-template #closeRightSidenav>
                    <i class="fa-solid fa-lg fa-chevron-right" aria-hidden="true"></i>
                </ng-template>
            </div>

            <!-- Leere Zusatzaktion -->
            <ng-template #emptyAction>
                <div
                    class="empty-action-wrapper flex items-center justify-center px-3"
                    [ngClass]="
                        autocompleteOpen
                            ? 'empty-action-wrapper-autocomplete-open'
                            : 'empty-action-wrapper-autocomplete-closed'
                    "
                ></div>
            </ng-template>

            <!-- Linie um die Suchleiste von den Vorschlägen abzutrennen -->
            <div class="search-input-separator" *ngIf="autocompleteOpen"></div>
        </div>

        <!-- Vorschlagsliste -->
        <div class="autocomplete-wrapper relative" *ngIf="autocompleteOpen">
            <div
                class="autocomplete-container"
                data-cy="quick-search-autocomplete-container"
                *ngIf="!loading; else loadingIndicator"
            >
                <!-- Text der angezeigt wird falls keine Elemente gefunden wurden -->
                <span
                    id="noItemsFound"
                    *ngIf="!itemsFound"
                    class="grow self-center p-2 text-center text-base text-gray-500"
                    >Keine Suchergebnisse gefunden</span
                >

                <!-- Vorschläge Spalte 1 -->
                <div *ngIf="itemsFound" class="flex flex-1 flex-col">
                    <div class="mb-2" *ngFor="let group of itemsLeft">
                        <div class="autocomplete-group-label">
                            {{ group.kategorie | schnellsucheKategorie }}
                        </div>
                        <k5-quick-search-item
                            [class.selected]="item === activeItem"
                            *ngFor="let item of group.ergebnis"
                            [item]="item"
                            (selected)="navigateToDetailPage(item)"
                            data-cy="quick-search-item"
                        ></k5-quick-search-item>
                        <span class="ml-6 text-sm">{{ group.ergebnis[0].anzahlErgebnisse }} Einträge gefunden</span>
                    </div>
                </div>

                <!-- Abtrennung der Vorschlagsspalten -->
                <div *ngIf="itemsFound" class="flex pl-6">
                    <div class="vertical-separator"></div>
                </div>

                <!-- Vorschläge Spalte 2 -->
                <div *ngIf="itemsFound && itemsRight.length > 0" class="flex flex-1 flex-col">
                    <div class="mb-2" *ngFor="let group of itemsRight">
                        <div class="autocomplete-group-label">
                            {{ group.kategorie | schnellsucheKategorie }}
                        </div>
                        <k5-quick-search-item
                            [class.selected]="item === activeItem"
                            *ngFor="let item of group.ergebnis"
                            [item]="item"
                            (selected)="navigateToDetailPage(item)"
                        ></k5-quick-search-item>
                        <span class="ml-6 text-sm">{{ group.ergebnis[0].anzahlErgebnisse }} Einträge gefunden</span>
                    </div>
                </div>
            </div>

            <ng-template #loadingIndicator>
                <div class="autocomplete-container" data-cy="quick-search-autocomplete-container-loading-indicator">
                    <k5-loading data-cy="quick-search-loading-indicator" class="p-6" message="Suche ..."></k5-loading>
                </div>
            </ng-template>
        </div>
    </div>
</div>
