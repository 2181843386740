/**
 * k5|Next - Personen API
 * Öffentliche API-Schnittstelle zur Verwaltung der Personen in k5|Next.
 *
 * OpenAPI spec version: v1
 * Contact: info@programmierfabrik.at
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status vom Abgleich mit dem ZMR
 */
export type ZMRAbgleichStatusEnum = 'InArbeit' | 'Durchgefuehrt' | 'TeilweiseDurchgefuhert' | 'Fehler';

export const ZMRAbgleichStatusEnum = {
    InArbeit: 'InArbeit' as ZMRAbgleichStatusEnum,
    Durchgefuehrt: 'Durchgefuehrt' as ZMRAbgleichStatusEnum,
    TeilweiseDurchgefuhert: 'TeilweiseDurchgefuhert' as ZMRAbgleichStatusEnum,
    Fehler: 'Fehler' as ZMRAbgleichStatusEnum
};