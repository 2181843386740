import { Pipe, PipeTransform } from '@angular/core';
import { WahlbestandSortierungEnum } from '@shared/models/enums';

@Pipe({
    name: 'sortierungWvz'
})
export class SortierungWvzPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value WahlbestandSortierungEnum
     */
    transform(value: WahlbestandSortierungEnum): string {
        switch (value) {
            case WahlbestandSortierungEnum.SprengelOrtschaftAdresseBisTuerName:
                return 'Sprengel/Ortschaft/Adresse bis Tür/Name';
            case WahlbestandSortierungEnum.SprengelOrtschaftAdresseBisHausnummerName:
                return 'Sprengel/Ortschaft/Adresse bis Hausnummer/Name';
            case WahlbestandSortierungEnum.SprengelAdresseBisTuerName:
                return 'Sprengel/Adresse bis Tür/Name';
            case WahlbestandSortierungEnum.SprengelAdresseBisHausnummerName:
                return 'Sprengel/Adresse bis Hausnummer/Name';
            case WahlbestandSortierungEnum.SprengelName:
                return 'Sprengel/Name';
            default:
                return '-';
        }
    }
}
