import { Pipe, PipeTransform } from '@angular/core';
import { AntragsartEnum } from '@shared/wahlkarten-enums';

@Pipe({
    name: 'wkAntragsart'
})
export class WkAntragsartPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte der Wahlkartenantragsart in eine textuelle Form.
     * @param value AntragsartEnum
     */
    transform(value: AntragsartEnum): string {
        switch (value) {
            case AntragsartEnum.Persoenlich:
                return 'Persönlich';
            case AntragsartEnum.Schriftlich:
                return 'Schriftlicher Antrag';
            case AntragsartEnum.ElektPortal:
                return 'Elektronisch/Portal';
            case AntragsartEnum.Abo:
                return 'Wahlkartenabo';
        }
    }
}
