<k5-widget [title]="wahl?.wahlbezeichnung" [showDelete]="true" (remove)="removeWidget($event)">
    <div class="hover:cursor-pointer" (click)="navigateToWahlDetails()" *ngIf="!error && !loading; else loadingOrError">
        <div class="flex flex-row rounded-md px-4 py-3" *ngFor="let item of timelineEntries">
            <div class="flex flex-col items-center pr-4">
                <div class="flex h-5 items-center">
                    <i class="fa-duotone fa-circle fg-primary" aria-hidden="true"></i>
                </div>
                <div class="mt-2 h-11 w-1 rounded-md bg-gray-400"></div>
            </div>
            <div class="flex flex-col">
                <span class="text-base">{{ item.titel }}</span>
                <span class="text-xs text-gray-500">{{ item.beschreibung }}</span>
                <span class="text-3xl">{{ item.datum | date : "mediumDate" : "MEZ" : "de-AT" }}</span>
            </div>
        </div>
    </div>

    <!-- Ladeansicht und Fehleransicht des Widgets -->
    <ng-template #loadingOrError>
        <k5-loading *ngIf="loading; else error" message="Kalenderdaten werden geladen ..."> </k5-loading>

        <ng-template #error>
            <a target="_blank">
                <img
                    class="h-full max-h-40 w-full object-contain"
                    src="assets/img/k5next_logo_minimal.svg"
                    alt="k5 Next Logo"
                />
            </a>
            <div class="flex items-center pb-2">
                <span class="line-clamp-2 pt-4 text-xl font-bold">Fehler beim Laden des Widgets</span>
            </div>
            <div class="grow"></div>
            <button mat-button color="primary" class="w-fit" (click)="loadWidgetData()">
                <i aria-hidden="true" class="fa-duotone fa-arrows-rotate pr-4"></i>Erneut versuchen
            </button>
        </ng-template>
    </ng-template>
</k5-widget>
