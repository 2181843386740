import { Pipe, PipeTransform } from '@angular/core';
import { WidgetType } from '@shared/models/widgetInfo';

@Pipe({
    name: 'widgetType'
})
export class WidgetTypePipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value WidgetType
     */
    transform(value: WidgetType): string {
        switch (value) {
            case WidgetType.Willkommen:
                return 'Willkommen';
            case WidgetType.Betreiber:
                return 'Betreiber';
            case WidgetType.RssFeed:
                return 'RSS-Feed';
            case WidgetType.GespeicherteGruppen:
                return 'Meine gespeicherten Gruppen';
            case WidgetType.GespeicherteSuche:
                return 'Meine gespeicherten Suchen';
            case WidgetType.WahlberechtigteAktuell:
                return 'Wahlberechtigte aktuell';
            case WidgetType.Wahlkartenstatistik:
                return 'Wahlkartenstatus';
            case WidgetType.MeineWahlkarte:
                return 'Meine Wahlkarte';
            case WidgetType.MeineWahlkarteAntragsart:
                return 'Antragsart Meine Wahlkarte';
            case WidgetType.Timeline:
                return 'Wahlkalender';
            default:
                return 'Undefiniert';
        }
    }
}
