import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumKeys'
})
export class EnumKeysPipe implements PipeTransform {
    /**
     * Wandelt textuelle Werte eines Enum in die numerischen Werte um
     * @param value Textuelle Werte eines Enum
     * @returns Array mit den Zahlenwerten des Enum
     */
    transform(value: string[]): number[] {
        const keys: number[] = [];
        for (const enumMember in value) {
            if (!isNaN(parseInt(enumMember, 10))) {
                keys.push(parseInt(enumMember, 10));
            }
        }
        return keys;
    }
}
