import { Component } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';

/**
 * Responsive Titelzeile, die sich der Navigationsleiste anpasst
 */
@Component({
    selector: 'k5-responsive-title',
    templateUrl: './responsive-title.component.html',
    styleUrls: ['./responsive-title.component.scss']
})
export class ResponsiveTitleComponent {
    constructor(public navigationService: NavigationService) {}
}
