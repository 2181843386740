<k5next-dialog-heading id="dialogHeading" [heading]="data.title" [showClose]="true"></k5next-dialog-heading>

<k5next-dialog-content [ngClass]="{linebreak: false}" data-cy="datetime-dialog-content">
    <span id="dateTimeDialogTextContentSpan" *ngIf="data.textContent">{{ data.textContent }}</span>
    <form [formGroup]="dateTimeForm" class="max-w-[65%] min-h-[13vh]">
        <mat-form-field class="w-full">
            <mat-label id="dateTimeDialogInputLabel" *ngIf="data.inputFieldLabel">{{ data.inputFieldLabel }}</mat-label>
            <input
                id="datetimeDialogPickerInput"
                aria-labelledby="dateTimeDialogInputLabel"
                type="datetime-local"
                [errorStateMatcher]="matcher"
                formControlName="dateTimeFormControl"
                matInput
                autocomplete="off"
                (change)="validateDateTime()"
            />
            @if (dateTimeForm.get('dateTimeFormControl')?.hasError('invalidDate')) {
                <mat-error id="matErrorInvalidDate">
                    {{ dateTimeForm.get('dateTimeFormControl').getError('invalidDate') }}
                </mat-error>
            }
            @if (dateTimeForm.get('dateTimeFormControl')?.hasError('required')) {
                <mat-error id="matErrorRequired">
                    Feld darf nicht leer sein.
                </mat-error>
            }
        </mat-form-field>
    </form>
</k5next-dialog-content>

<k5next-dialog-actions [alignEnd]="true">
    <button
        id="dateTimeDialogSecondaryButton"
        mat-stroked-button
        color="primary"
        type="button"
        *ngIf="!data.secondaryButtonHidden"
        (click)="handleAbortButton()"
        [disabled]="data.secondaryButtonDisabled"
    >
        {{ secondaryButtonLabel }}
    </button>
    <button
        id="dateTimeDialogPrimaryButton"
        mat-raised-button
        color="primary"
        type="button"
        (click)="handleConfirmButton()"
        [disabled]="data.primaryButtonDisabled || !dateTimeForm.valid"
        cdkFocusRegionstart
    >
        {{ primaryButtonLabel }}
    </button>
</k5next-dialog-actions>
