<k5-widget
    [title]="'Meine Wahlkarte - Anträge nach Identitätsnachweis'"
    [showDelete]="true"
    (remove)="removeWidget($event)"
>
    <div class="flex h-full grow flex-col overflow-auto" *ngIf="!error && !loading; else loadingOrError">
        <span class="text-lg">{{ wahl?.wahlbezeichnung }}</span>

        <div class="flex grow gap-x-4 pt-4">
            <div class="relative w-1/3">
                <canvas
                    baseChart
                    [type]="'pie'"
                    [datasets]="pieChartDatasets"
                    [labels]="pieChartLabels"
                    [options]="pieChartOptions"
                    [legend]="pieChartLegend"
                >
                </canvas>
            </div>

            <!-- Legende -->
            <div class="flex w-2/3 flex-col divide-y pt-2" data-cy="wahlkarte-antragsart-widget-div-container">
                <a
                    matRipple
                    *ngFor="let element of dataSource; let i = index"
                    class="p-2 hover:cursor-pointer hover:bg-gray-200"
                    (click)="navigateToMeineWahlkarte(element)"
                    (keydown.enter)="navigateToMeineWahlkarte(element)"
                    tabindex="0"
                >
                    <div class="flex w-full items-center gap-x-4">
                        <i class="fa-solid fa-circle" [ngStyle]="{ color: element.color }" aria-hidden="true"></i>
                        <span class="grow">{{ element.name }}</span>
                        <span data-cy="amount">{{ element.amount }}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <!-- Ladeansicht und Fehleransicht des Widgets -->
    <ng-template #loadingOrError>
        <k5-loading *ngIf="loading; else error" message="Wahlkartenanträge werden geladen ..."> </k5-loading>

        <ng-template #error>
            <a target="_blank">
                <img
                    class="h-full max-h-40 w-full object-contain"
                    src="assets/img/k5next_logo_minimal.svg"
                    alt="k5 Next Logo"
                />
            </a>
            <div class="flex items-center pb-2">
                <span class="line-clamp-2 pt-4 text-xl font-bold">Fehler beim Laden des Widgets</span>
            </div>
            <div class="grow"></div>
            <button mat-button color="primary" class="w-fit" (click)="loadWidgetData()">
                <i aria-hidden="true" class="fa-duotone fa-arrows-rotate pr-4"></i>Erneut versuchen
            </button>
        </ng-template>
    </ng-template>
</k5-widget>
