import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalEditService } from '@core/services/global-edit.service';

@Component({
    selector: 'k5-edit-section-modal',
    templateUrl: './edit-section-modal.component.html',
    styleUrls: ['./edit-section-modal.component.scss']
})
export class EditSectionModalComponent {
    @Input()
    heading: string = '';

    @Input()
    icon: string = '';

    @Input()
    isEditable: boolean = true;

    @Output()
    editButtonClicked: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(private globalEditService: GlobalEditService) {}

    /**
     * Gibt an, ob der Bearbeitungsbleistift sichtbar ist
     */
    get isEditPencilVisible(): boolean {
        return this.isEditable && !this.globalEditService.isEditing;
    }

    /**
     * Emittiert das Click-Event nach außen
     */
    onEditButtonClicked(event: Event): void {
        this.editButtonClicked.emit(event);
    }
}
