import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { KeyValuePair } from '@core/models/key-value';
import { MandantClient } from '@shared/models/mandantenClient';
import { SyncZMRResponse } from '@shared/models/syncZMRResponse';
import { Observable } from 'rxjs';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

/**
 * Service für die Komunikation mit den Schnittstellen des Mandanten Microservice.
 */
@Injectable({
    providedIn: 'root'
})
export class MandantenService {
    constructor(
        private http: HttpClient,
        private configService: ConfigAssetLoaderService
    ) {}

    /**
     * Aufruf um alle Daten eines angemeldeten Benutzers aus dem MandantenService zu laden.
     */
    loadUiIninitialisierung(): Observable<MandantClient.CivisInitialisierung> {
        const url = `${this.configService.getConfig().k5Mandanten.mandantenServiceUiInitializationUrl}`;

        return this.http.get<MandantClient.CivisInitialisierung>(url);
    }

    /**
     * Mandantenwechsel
     * Aufruf um alle Daten eines angemeldeten Benutzers für einen bestimmten Mandanten zu laden.
     * @param mandantId MandantId
     */
    loadUiInitialisierungForMandant(mandantId: string): Observable<MandantClient.CivisInitialisierung> {
        const headers = new HttpHeaders({ [UiConstants.MANDANT_ID_HEADER]: mandantId });
        const url = `${this.configService.getConfig().k5Mandanten.mandantenServiceUiInitializationUrl}`;

        return this.http.get<MandantClient.CivisInitialisierung>(url, { headers });
    }

    /**
     * Gibt eine Benutzereinstellung zurück
     * @param benutzerId Technische BenutzerId
     * @param mandantId Technische MandantId
     * @param einstellungsname Schlüssel der Einstellung
     */
    getBenutzerEinstellung(benutzerId: string, mandantId: string, einstellungsname: string): Observable<string> {
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}&mandantId=${mandantId}&einstellungsname=${einstellungsname}`;

        return this.http.get<string>(url);
    }

    /**
     * Aufruf um die Benutzereinstellungen ohne einen Mandantenbezug zu ändern.
     * z.B.: Startmandant
     * @param benutzerId Technische BenutzerId
     * @param einstellung KeyValuePair
     */
    changeBenutzerEinstellungOhneMandant(benutzerId: string, einstellung: KeyValuePair): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}`;

        return this.http.put(url, JSON.stringify(einstellung), { headers });
    }

    /**
     * Aufruf um die Benutzereinstellungen mit einem Mandantenbezug zu ändern.
     * @param benutzerId Technische BenutzerId
     * @param mandantId Technische MandantId
     * @param einstellung Einstellung als KeyValuePair
     */
    changeBenutzerEinstellungenMitMandant(
        benutzerId: string,
        mandantId: string,
        einstellung: KeyValuePair
    ): Observable<void> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}&mandantId=${mandantId}`;

        return this.http.put<void>(url, JSON.stringify(einstellung), { headers });
    }

    /**
     * Aufruf zum Löschen einer Benutzereinstellung über den Namen der Einstellung.
     * Über die MandantId wird der Mandantenbezug hergestellt,
     * @param benutzerId Technische BenutzerId
     * @param mandantId Technische MandantId
     * @param einstellungName Name der Einstellung
     */
    deleteBenutzerEinstellung(benutzerId: string, mandantId: string, einstellungName: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}&mandantId=${mandantId}&einstellungName=${einstellungName}`;

        return this.http.delete(url, { headers });
    }

    /**
     * Gibt den Mandanten mit der gewünschten Id zurück
     * @param mandantId ID des Mandanten dessen Details gelesen werden sollen
     * @param k5MandantID ID des Mandanten, für den die Operation ausgeführt werden soll
     */
    getMandantById(mandantId: string, k5MandantID: string): Observable<MandantClient.Gemeinde> {
        return this.http.get<MandantClient.Gemeinde>(
            `${
                this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
            }/Gemeinde?gemeindeMandantId=${mandantId}&k5-MandantID=${k5MandantID}`
        );
    }

    /**
     * Abfrage der Gemeinde Einstellungen
     * @param mandantId ID des Mandanten, für den die Einstellungen ausgelesen werden soll
     */
    getMandantEinstellungen(mandantId: string): Observable<KeyValuePair> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'k5-MandantID': mandantId });
        return this.http.get<KeyValuePair>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Mandant/Einstellung/Liste`,
            { headers }
        );
    }

    /**
     * Triggert den ZMR Abgleich
     */
    triggerZMRAbgleich(): Observable<SyncZMRResponse> {
        return this.http.post<SyncZMRResponse>(
            `${
                this.configService.getConfig().k5civisperson.personenApiUrl
            }/personen/v1/natuerlichePersonen/AbgleichMitZMR`,
            {}
        );
    }

    /**
     * Liest die Basisinformationen zu einem Mandant aus dem System
     * @param mandantId des Mandanten
     * @returns Basisinformationen zu einem Mandant
     */
    getBasisInformationenDesMandantenByMandantId(mandantId: string): Observable<MandantClient.UIMandant> {
        return this.http.get<MandantClient.UIMandant>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrlUIApi}/Mandant?mandantId=${mandantId}`
        );
    }

    /**
     * Liefert den Access Token für K5NextHilfe
     * @returns den Token als String
     */
    getK5NextHilfeToken(): Observable<string> {
        return this.http.get<string>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Mandant/K5NextHilfe`
        );
    }
}
