import { Pipe, PipeTransform } from '@angular/core';
import { Datenabgleich } from '@shared/models/enums';

/**
 * Konvertiert die Enum-Werte des Typs Datenabgleich
 * in eine passende textuelle Darstellung für das UI.
 */
@Pipe({
    name: 'datenabgleich'
})
export class DatenabgleichPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * @param value Datenabgleich
     */
    transform(value: Datenabgleich): string {
        switch (value) {
            case Datenabgleich.Verstorben:
                return 'Verstorben';
            case Datenabgleich.Meldeadresse:
                return 'Meldeadresse';
            case Datenabgleich.Name:
                return 'Name';
            default:
                return `Datenablgeich Wert ${value} nicht übersetzbar!`;
        }
    }
}
