// @ts-strict-ignore
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@core/models/dictionary';
import { KeyValuePair } from '@core/models/key-value';
import { AlertService } from '@core/services/alert.service';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { UIDetailsucheRequest } from '@shared/models/uIDetailsucheRequest';
import { WahlbezugsAdresseResponse } from '@shared/models/wahlbezugsAdresseResponse';
import { Wahldaten } from '@shared/models/wahldaten';
import { BehaviorSubject, Observable } from 'rxjs';
import { UIDetailsucheResponse } from 'src/app/buergerservice/kontaktmanagement/models/uIDetailsucheResponse';
import * as uuid from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class DetailsucheService {
    config: AppConfig = null;

    // BehaviorSubject und Observable für eine Detailsuche im Wahlbestand
    private readonly detailsucheParameterSubject = new BehaviorSubject<KeyValuePair>({});
    public detailsucheParameter$ = this.detailsucheParameterSubject.asObservable();

    // Variable um die Anzeige für den gesammelten Wahlkartendruck im Menü der Wahlbestandsliste zu triggern
    private readonly wahlkartendruckOffenSubject = new BehaviorSubject<boolean>(false);
    wahlkartendruckOffen$ = this.wahlkartendruckOffenSubject.asObservable();

    // Variable um die Anzeige für den Nachdruck der Wahlkarten im Menü der Wahlbestandsliste zu triggern
    private readonly wahlkartenNachdruckSubject = new BehaviorSubject<boolean>(false);
    wahlkartenNachdruck$ = this.wahlkartenNachdruckSubject.asObservable();

    // Variable um die Anzeige für den gesammelten Wahlkartendruck im Menü der Wahlbestandsliste zu triggern
    private readonly postaufgabelisteOffenSubject = new BehaviorSubject<boolean>(false);
    postaufgabelisteOffen$ = this.postaufgabelisteOffenSubject.asObservable();

    // Variable um die Anzeige für den Nachdruck der Postaufgabeliste im Menü der Wahlbestandsliste zu triggern
    private readonly postaufgabelisteNachdruckSubject = new BehaviorSubject<boolean>(false);
    postaufgabelisteNachdruck$ = this.postaufgabelisteNachdruckSubject.asObservable();

    // Variable um die Anzeige für den gesammelten Etikettendruck im Menü der Wahlbestandsliste zu triggern
    // Die Unterscheidung zwischen Etiketten und Rsb-Etiketten erfolgt anhand der Landtagswahl NOE
    private readonly etikettendruckOffenSubject = new BehaviorSubject<boolean>(false);
    etikettendruckOffen$ = this.etikettendruckOffenSubject.asObservable();

    // Variable um die Anzeige für den Nachdruck der Etiketten im Menü der Wahlbestandsliste zu triggern
    // Die Unterscheidung zwischen Etiketten und Rsb-Etiketten erfolgt anhand der Landtagswahl NOE
    private readonly etikettenNachdruckSubject = new BehaviorSubject<boolean>(false);
    etikettenNachdruck$ = this.etikettenNachdruckSubject.asObservable();

    private readonly displayedColumnsSubject: BehaviorSubject<string[] | null> = new BehaviorSubject(null);
    displayedColumns$: Observable<string[] | null> = this.displayedColumnsSubject.asObservable();

    private readonly sortingSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
    sortingColumns$: Observable<string | null> = this.sortingSubject.asObservable();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService,
        private readonly alertService: AlertService
    ) {
        this.config = this.configService.getConfig();
    }

    get detailsucheParameter(): Dictionary<string> {
        return this.detailsucheParameterSubject.value;
    }

    /**
     * Liefert die Wahldetails aus dem WV-Service an das UI zurück
     * Darin enthalten, sind die Information, welche Suchkriterien in der Detailsuche verfügbar sind.
     * @param wahlId ID der Wahl
     * @returns Wahldetails
     */
    loadWahldetails(wahlId: string): Observable<Wahldaten> {
        return this.httpClient.get<Wahldaten>(`${this.config.k5civisWv.wahlvorbereitungUiApiUrl}/Wahl/${wahlId}`);
    }

    /**
     * Lädt die verfügbaren Sprengelnummern eines Wahlbestands der entsprechenden Wahlevent Id
     * und befüllt damit das Auswahlfeld für die Sprengelnummer in der Detailsuche
     * @param wahlId Id der Wahl
     * @returns Liste der verfügbaren Sprengelnummern im Wahlbestand
     */
    loadSprengel(wahlId: string): Observable<number[]> {
        return this.httpClient.get<number[]>(
            `${this.config.k5civisWv.wahlvorbereitungUiApiUrl}/Wahlbestand/${wahlId}/Sprengel`
        );
    }

    /**
     * Führt eine Suche nach der Wahlbezugsadressen, für die Autocomplete-Sektion des Eingabefeldes, im Wahlbestand der entsprechenden WahleventId durch.
     * Die verwendeten Suchparameter werden aus dem Eingabefeld ausgelesen.
     * @param wahlId Id der Wahl
     * @param adressQuery Suchparameter
     * @returns WahlbezugsAdresseResponse, welcher die Anzahl und eine Liste der gefundenen Wahlbezugsadressen enthält
     */
    queryWahlbezugsAdressen(wahlId: string, adressQuery: string): Observable<WahlbezugsAdresseResponse> {
        const params = new HttpParams().set('wahlId', wahlId).set('adressQuery', adressQuery);

        return this.httpClient.get<WahlbezugsAdresseResponse>(
            `${this.config.k5civisWv.wahlvorbereitungUiApiUrl}/Wahlbestand/Wahlbezugsadresse`,
            { params }
        );
    }

    /**
     * Updated die Spalten im Behavior Subject
     * @param cols string[]
     */
    updateDisplayedColumns(cols: string[]): void {
        this.displayedColumnsSubject.next(cols);
    }

    /**
     * Gibt die aktuellen Spalten zurück
     */
    getDisplayedColumns(): string[] {
        return this.displayedColumnsSubject.value;
    }

    /**
     * Updated die Sortierung im Behavior Subject
     * @param cols string[]
     */
    updateSorting(sorting: string): void {
        this.sortingSubject.next(sorting);
    }

    /**
     * Gibt die aktuelle Sortierung zurück
     */
    getSorting(): string {
        return this.sortingSubject.value;
    }

    /**
     * Übernimmt die Werte der Detailsuche und sichert diese in einem Subject.
     * @param detailsucheParameter KeyValuePair
     */
    updateDetailsucheInputFields(detailsucheParameter: KeyValuePair): void {
        this.detailsucheParameterSubject.next(detailsucheParameter);
    }

    /**
     * Wird in der Detailsuche in den Offenen Bearbeitungen nach Wahlkartendruck offen gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param wahlkartendruckOffen Offene Bearbeitung in der Detailsuche angewandt
     */
    wahlkartendruckOffen(wahlkartendruckOffen: boolean): void {
        this.wahlkartendruckOffenSubject.next(wahlkartendruckOffen);
    }

    /**
     * Wird in der Detailsuche nach Wahlkarte gedruckt gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param wahlkartenNachdruck Status Wahlkarte - Wahlkarte gedruckt in der Detailsuche angewandt
     */
    wahlkartenNachdruck(wahlkartenNachdruck: boolean): void {
        this.wahlkartenNachdruckSubject.next(wahlkartenNachdruck);
    }

    /**
     * Wird in der Detailsuche in den Offenen Bearbeitungen nach Etikettendruck offen gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param etikettendruckOffen Offene Bearbeitung in der Detailsuche angewandt
     */
    etikettendruckOffen(etikettendruckOffen: boolean): void {
        this.etikettendruckOffenSubject.next(etikettendruckOffen);
    }

    /**
     * Wird in der Detailsuche in den nach Etikett gedruckt gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param etikettenNachdruck Status Wahlkarte - Etikett gedruckt in der Detailsuche angewandt
     */
    etikettenNachdruck(etikettenNachdruck: boolean): void {
        this.etikettenNachdruckSubject.next(etikettenNachdruck);
    }

    /**
     * Wird in der Detailsuche in den Offenen Bearbeitungen nach Postaufgabeliste offen gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param postaufgabelisteOffen Offene Bearbeitung in der Detailsuche angewandt
     */
    postaufgabelisteOffen(postaufgabelisteOffen: boolean): void {
        this.postaufgabelisteOffenSubject.next(postaufgabelisteOffen);
    }

    /**
     * Wird in der Detailsuche in den Offenen Bearbeitungen nach Postaufgabeliste offen gesucht
     * und ist der Wahlstatus gleich oder weiter dem Staus Wahlbestand erstellt,
     * wird das Observable auf true gesetzt, andernfalls false.
     * @param postaufgabelisteNachdruck Status Wahlkarte - Wahlkarte gedruckt in der Detailsuche angewandt
     */
    postaufgabelisteNachdruck(postaufgabelisteNachdruck: boolean): void {
        this.postaufgabelisteNachdruckSubject.next(postaufgabelisteNachdruck);
    }

    /**
     * Sucht Kontaktpersonen im Kontaktmanagement
     * @param suchQuery UIDetailsucheRequest
     */
    searchKontaktPersonen(suchQuery: UIDetailsucheRequest): Observable<UIDetailsucheResponse> {
        return this.httpClient.post<UIDetailsucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/Kontaktpersonen`,
            suchQuery
        );
    }

    /**
     * Sichert die aktuelle Suche im Session Storage
     * @param search T
     * @param key string
     */
    saveSearchInStorage<T>(search: T, key: string): void {
        if (search) {
            const storageItem = JSON.stringify(search);
            // Wenn es nicht möglich ist, die Daten in den Session-Storage zu schreiben, wird in die Konsole
            // eine Warnung ausgegeben.
            try {
                sessionStorage.setItem(key, storageItem);
            } catch {
                this.alertService.warning('Fehler beim Speichern der Suche im Session Storage');
            }
        }
    }

    /**
     * Lädt die Suche aus dem Session Storage und wandelt diese in ein
     * UIDetailsucheRequest-Objekt um.
     * @param key string
     */
    getSearchFromStorage<T>(key: string): T | null {
        const storageItem: string = sessionStorage.getItem(key);
        if (storageItem) {
            return JSON.parse(storageItem) as T;
        }
        return null;
    }

    /**
     * Generiert eine uuid
     * @returns uuid
     */
    generateSessionStorageKey(): string {
        return uuid.v4();
    }

    /**
     * Setzt die Observables für die offenen Bearbeitungen und für den Nachdruck zurück.
     */
    resetOffeneBearbeitungenObservables(): void {
        // Sammeldruck für Wahlkarten und Etiketten aus dem Menü der Wahlbestandsliste über das Observable entfernen
        this.wahlkartendruckOffen(false);
        this.etikettendruckOffen(false);
        this.postaufgabelisteOffen(false);

        // Nachdruck-Observables zurücksetzen
        this.wahlkartenNachdruck(false);
        this.etikettenNachdruck(false);
        this.postaufgabelisteNachdruck(false);
    }
}
