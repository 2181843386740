<div [ngClass]="centerSearch ? 'search-header-addon-center' : 'search-header-addon-right'">
    <form class="h-full w-full">
        <div class="search-input-wrapper">
            <i aria-hidden="true" class="fa-duotone fa-lg fa-search icon px-4"></i>
            <input
                placeholder="Suche ..."
                [formControl]="searchFormControl"
                class="search-input"
                name="search"
                data-cy="input-suche"
            />
            <div
                *ngIf="showOptionButton"
                matRipple
                class="detail-action-wrapper flex"
                [matTooltip]="detailActionTooltip"
                (click)="emitToggleOptions()"
            >
                <i *ngIf="optionsShown" aria-hidden="true" class="fa-solid fa-lg fa-chevron-right icon px-4"></i>
                <i *ngIf="!optionsShown" aria-hidden="true" class="fa-solid fa-lg fa-chevron-left icon px-4"></i>
            </div>
        </div>
    </form>
</div>
