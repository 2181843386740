<div class="w-full h-full" [ngSwitch]="darstellungData?.darstellungUi">
    <!-- Nur Logo -->
    <div *ngSwitchCase="UI_DARSTELLUNG.Logo">
        <div *ngIf="!displaySmall; else logoSmall" class="flex items-center">
            <img alt="Mandant Logo" class="h-14 w-full object-contain" [src]="imageSrc" />
        </div>

        <ng-template #logoSmall>
            <div
                class="flex h-14 w-14 items-center justify-center rounded-[50%] bg-[--color-primary] text-2xl/7 text-white"
            >
                {{ avatarText }}
            </div>
        </ng-template>
    </div>

    <!-- Text mit Wappen -->
    <div *ngSwitchCase="UI_DARSTELLUNG.TextMitWappen">
        <div *ngIf="!displaySmall; else textMitWappenSmall" class="flex items-center justify-between">
            <div class="flex flex-col min-w-0">
                <span class="overflow-hidden text-ellipsis whitespace-nowrap text-2xl/7 text-gray-600">
                    {{ darstellungData?.nameZeile1 }}
                </span>
                <span class="overflow-hidden text-ellipsis whitespace-nowrap text-base/5 text-gray-600">
                    {{ darstellungData?.nameZeile2 }}
                </span>
            </div>

            <img alt="Mandant Wappen" class="h-14 w-16 object-contain pl-2" [src]="imageSrc" />
        </div>

        <ng-template #textMitWappenSmall>
            <img alt="Mandant Wappen" class="h-14 w-full object-contain" [src]="imageSrc" />
        </ng-template>
    </div>

    <!-- Default - Nur Text -->
    <div *ngSwitchDefault>
        <div *ngIf="!displaySmall; else textSmall" class="flex flex-col min-w-0">
            <span class="overflow-hidden text-ellipsis whitespace-nowrap text-2xl/7 text-gray-600">
                {{ darstellungData?.nameZeile1 }}
            </span>
            <span class="overflow-hidden text-ellipsis whitespace-nowrap text-base/5 text-gray-600">
                {{ darstellungData?.nameZeile2 }}
            </span>
        </div>

        <ng-template #textSmall>
            <div
                class="flex h-14 w-14 items-center justify-center rounded-[50%] bg-[--color-primary] text-2xl/7 text-white"
            >
                {{ avatarText }}
            </div>
        </ng-template>
    </div>
</div>
