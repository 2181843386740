/**
 * Enthält verschiedene Enums die in der Applikation verwendet werden.
 */

/**
 * Art der Meldung an den Benutzer im AlertService
 */
export enum AlertType {
    Info,
    Success,
    Warning,
    Error
}

