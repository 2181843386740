import { Injectable } from '@angular/core';
import { AvatarPhotoConstants } from '@shared/constants/shared-constants';

@Injectable({
    providedIn: 'root'
})
export class AvatarService {
    /**
     * Extrahiert die Initialen aus dem Namen
     * @param firstname Vorname
     * @param lastname Familienname
     * @returns Initialen
     */
    generateInitials(firstname: string, lastname: string): string {
        let initials: string[] = [firstname, lastname];

        initials = initials
            .filter((x) => !!x)
            .map((name) => name.charAt(0))
            .slice(0, 2);

        if (!initials || initials.length === 0) {
            return '';
        }

        return initials.reduce((x, y) => x + y, '').toUpperCase();
    }

    /**
     * Berechnet zu den Initialen eine Farbe aus vorgegebenen Farbkonstanten
     * @param initials Initialen
     */
    generateColor(initials: string): string {
        if (!initials) {
            return AvatarPhotoConstants.BACKGROUND_COLORS[0];
        }

        let charSum: number = 0;

        for (let i = 0; i < initials.length; i++) {
            charSum += initials.charCodeAt(i);
        }

        const colorIndex = charSum % AvatarPhotoConstants.BACKGROUND_COLOR_AMOUNT;
        return AvatarPhotoConstants.BACKGROUND_COLORS[colorIndex];
    }
}
