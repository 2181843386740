// @ts-strict-ignore
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfig, ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import {
    ConfirmationDialogComponent,
    ConfirmationDialogData
} from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ChangeGespeicherteSucheRequest } from '@shared/models/changeGespeicherteSucheRequest';
import { CreateGespeicherteSucheRequest } from '@shared/models/createGespeicherteSucheRequest';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { ShareGespeicherteSucheRequest } from '@shared/models/shareGespeicherteSucheRequest';
import { Observable } from 'rxjs';
import { KontaktpersonAdresseCapabilities } from '../models/kontaktpersonAdresseCapabilities';
import { KontaktpersonAdresseDaten } from '../models/kontaktpersonAdresseDaten';
import { KontaktpersonErreichbarkeitCapabilities } from '../models/kontaktpersonErreichbarkeitCapabilities';
import { KontaktpersonErreichbarkeitDaten } from '../models/kontaktpersonErreichbarkeitDaten';
import { ShareSuchergebnisRequest } from '../models/shareSuchergebnisRequest';
import { TagList } from '../models/tags';
import { UIDetailsucheResponse } from '../models/uIDetailsucheResponse';
import { UISuchergebnissucheRequest } from '../models/uISuchergebnissucheRequest';
import { UiSuchergebnisExportAsExcelRequest } from '../models/uiSuchergebnisExportAsExcelRequest';

@Injectable({
    providedIn: 'root'
})
export class KontaktmanagementService {
    config: AppConfig = null;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigAssetLoaderService,
        private readonly dialog: MatDialog
    ) {
        this.config = this.configService.getConfig();
    }

    //#region Allgemein

    /**
     * Teilt ein Suchergebnis mit Benutzern
     */
    shareSuchergebnisWithBenutzer(request: ShareSuchergebnisRequest): Observable<any> {
        return this.httpClient.post<any>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/Ergebnisse/Share`,
            request
        );
    }

    /**
     * Gibt eine Unternehmen anhand der Id zurück
     */
    getTags(): Observable<TagList> {
        return this.httpClient.get<TagList>(`${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Tag`);
    }

    /**
     * Gibt ein Suchergebnis anhand der Id zurück
     */
    getSuchergebnisById(id: string, request: UISuchergebnissucheRequest): Observable<UIDetailsucheResponse> {
        return this.httpClient.post<UIDetailsucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/Ergebnisse/${id}`,
            request
        );
    }

    /**
     * Gibt eine Suche anhand der Id zurück
     */
    getSucheById(id: string): Observable<KontaktmanagementClient.GespeicherteSucheResponse> {
        return this.httpClient.get<KontaktmanagementClient.GespeicherteSucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/${id}`
        );
    }

    /**
     * Gibt alle Suchen zurück
     */
    getSuchen(): Observable<KontaktmanagementClient.GespeicherteSuchenResponse> {
        return this.httpClient.get<KontaktmanagementClient.GespeicherteSuchenResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche`
        );
    }

    /**
     * Speichert eine neue Suche
     */
    createSuche(
        sucheDaten: CreateGespeicherteSucheRequest
    ): Observable<KontaktmanagementClient.GespeicherteSucheResponse> {
        return this.httpClient.post<KontaktmanagementClient.GespeicherteSucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche`,
            sucheDaten
        );
    }

    /**
     * Updated eine Suche
     */
    updateSuche(
        sucheDaten: ChangeGespeicherteSucheRequest,
        id: string
    ): Observable<KontaktmanagementClient.GespeicherteSucheResponse> {
        return this.httpClient.put<KontaktmanagementClient.GespeicherteSucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/${id}`,
            sucheDaten
        );
    }

    /**
     * Löscht eine Suche
     */
    deleteSuche(id: string): Observable<any> {
        return this.httpClient.delete<any>(`${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Suche/${id}`);
    }

    /**
     * Teilt eine Suche mit einer Liste von Benutzern
     */
    shareSucheWithBenutzer(
        request: ShareGespeicherteSucheRequest,
        sucheId: string
    ): Observable<KontaktmanagementClient.GespeicherteSucheResponse> {
        return this.httpClient.post<KontaktmanagementClient.GespeicherteSucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/suche/${sucheId}/share`,
            request
        );
    }

    /**
     * Gibt Informationen über eine gespeicherte Suche zurück
     */
    getSucheInfo(sucheId: string): Observable<KontaktmanagementClient.GespeicherteSucheResponse> {
        return this.httpClient.get<KontaktmanagementClient.GespeicherteSucheResponse>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/suche/${sucheId}`
        );
    }

    /**
     * Gibt alle capabilities zurück
     * @returns capabilities
     */
    getCapabilities(): Observable<GeneralCapabilities> {
        return this.httpClient.get<GeneralCapabilities>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Capabilities`
        );
    }

    /**
     * Gibt die Suchergebnisse als Excel zurück
     * @param request Request für die Excel Exportierung
     * @returns Ein XLSX File
     */
    excelExport(request: UiSuchergebnisExportAsExcelRequest): Observable<any> {
        return this.httpClient.post(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/suche/export/excel`,
            request,
            {
                observe: 'response',
                responseType: 'blob'
            }
        );
    }

    /**
     * Ruft alle natürlichen Kontaktpersonen ab, bei denen Adressenclearing gemacht werden kann.
     * @returns Ein Observable, das ein Ergebnisobjekt vom Typ GetAdressClearingKontaktpersonenQueryResult zurückgibt.
     */
    getKontaktpersonenForAdressenClearing(): Observable<KontaktmanagementClient.AdressClearingKontaktpersonDaten[]> {
        return this.httpClient.get<KontaktmanagementClient.AdressClearingKontaktpersonDaten[]>(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/Clearing/Adressen`
        );
    }

    /**
     * Fügt eine Bankverbindung für einen Kontakt hinzu.
     *
     * @param personId - Die ID des Kontaktes.
     * @param personenArt - Die Art des Kontaktes (Natuerliche Person oder Unternehmen).
     * @param bankverbindung - Die hinzuzufügende Bankverbindung.
     * @returns Response als Observable mit den aktuellen Daten der natürlichen Person oder des Unternehmens und dessen Capabilities.
     */
    addBankverbindung(
        personId: string,
        personenArt: KontaktmanagementClient.Personenart,
        bankverbindung: KontaktmanagementClient.AddBankverbindungRequest
    ): Observable<
        | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
        | KontaktmanagementClient.UnternehmensKontaktpersonResponse
    > {
        return this.httpClient.post<
            | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
            | KontaktmanagementClient.UnternehmensKontaktpersonResponse
        >(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/` +
                (personenArt === KontaktmanagementClient.Personenart.Natuerlich
                    ? 'NatuerlicheKontaktperson'
                    : 'UnternehmensKontaktperson') +
                `/${personId}/bankverbindung`,
            bankverbindung
        );
    }

    /**
     * Aktualisiert die Bankverbindung für einen Kontakt.
     *
     * @param personId - Die ID des Kontaktes.
     * @param personenArt - Die Art des Kontaktes (Natuerliche Person oder Unternehmen).
     * @param bankverbindungId - Die ID der Bankverbindung.
     * @param bankverbindung - Die zu aktualisierenden Bankverbindungsinformationen.
     * @returns Response als Observable mit den aktuellen Daten der natürlichen Person oder des Unternehmens und dessen Capabilities.
     */
    changeBankverbindung(
        personId: string,
        personenArt: KontaktmanagementClient.Personenart,
        bankverbindungId: string,
        bankverbindung: KontaktmanagementClient.ChangeBankverbindungRequest
    ): Observable<
        | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
        | KontaktmanagementClient.UnternehmensKontaktpersonResponse
    > {
        return this.httpClient.put<
            | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
            | KontaktmanagementClient.UnternehmensKontaktpersonResponse
        >(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/` +
                (personenArt === KontaktmanagementClient.Personenart.Natuerlich
                    ? 'NatuerlicheKontaktperson'
                    : 'UnternehmensKontaktperson') +
                `/${personId}/bankverbindung/${bankverbindungId}`,
            bankverbindung
        );
    }

    /**
     * Löscht eine Bankverbindung für eine Person.
     *
     * @param personId - Die ID der Person.
     * @param personenArt - Die Art des Kontaktes (Natuerliche Person oder Unternehmen).
     * @param bankverbindungId - Die ID der zu löschenden Bankverbindung.
     * @param requestBody - Der Body zum Löschen der Bankverbindung.
     * @returns Response als Observable mit den aktuellen Daten der natürlichen Person oder des Unternehmens und dessen Capabilities.
     */
    deleteBankverbindung(
        personId: string,
        personenArt: KontaktmanagementClient.Personenart,
        bankverbindungId: string,
        requestBody: KontaktmanagementClient.DeleteRequest
    ): Observable<
        | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
        | KontaktmanagementClient.UnternehmensKontaktpersonResponse
    > {
        return this.httpClient.delete<
            | KontaktmanagementClient.NatuerlicheKontaktpersonResponse
            | KontaktmanagementClient.UnternehmensKontaktpersonResponse
        >(
            `${this.config.k5civisKm.kontaktmanagementUiApiBaseUrl}/` +
                (personenArt === KontaktmanagementClient.Personenart.Natuerlich
                    ? 'NatuerlicheKontaktperson'
                    : 'UnternehmensKontaktperson') +
                `/${personId}/bankverbindung/${bankverbindungId}`,
            { body: requestBody }
        );
    }

    //#endregion Allgemein

    //#region Helper Methoden

    /**
     * Öffnet den Verwendungsbereich Dialog und gibt die Referenz zurück
     * @returns MatDialogRef
     */
    openVerwendungsbereichDeleteConfirmationDialog(): MatDialogRef<ConfirmationDialogComponent> {
        const confimationDialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData>(
            ConfirmationDialogComponent,
            {
                data: {
                    title: 'Kontakt löschen',
                    content:
                        'Wollen Sie den gesamten Kontakt löschen, da er in keiner weiteren Anwendung mehr benötigt wird?',
                    primaryButtonLabel: 'Löschen',
                    secondaryButtonLabel: 'Abbrechen'
                }
            }
        );
        return confimationDialogRef;
    }

    /**
     * Kopiert den Briefkopf
     */
    copyBriefkopf(briefkopf: KontaktmanagementClient.KontaktpersonBriefkopfDaten): void {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', this.buildBriefkopfString(briefkopf));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    }

    /**
     * Baut String für das Kopieren des Briefkopfs
     * @returns String für Briefkopf
     */
    buildBriefkopfString(briefkopf: KontaktmanagementClient.KontaktpersonBriefkopfDaten): string {
        return (
            (briefkopf.zeile1 ? briefkopf.zeile1 + '\n' : '') +
            (briefkopf.zeile2 ? briefkopf.zeile2 + '\n' : '') +
            (briefkopf.adresszeile1 ? briefkopf.adresszeile1 + '\n' : '') +
            (briefkopf.adresszeile2 ? briefkopf.adresszeile2 + '\n' : '') +
            (briefkopf.staat ? briefkopf.staat + '\n' : '')
        );
    }

    /**
     * Gibt zurück, ob das bearbeiten der Adresse erlaubt ist
     */
    editAdresseAllowed(
        adresse: KontaktpersonAdresseDaten,
        kontaktpersonAdresseCapabilities: KontaktpersonAdresseCapabilities
    ): boolean {
        return kontaktpersonAdresseCapabilities.existing.find(
            (adressCapabilities) => adressCapabilities.adresseId === adresse.id
        ).canChange;
    }

    /**
     * Gibt zurück, ob das löschen der Adresse erlaubt ist
     */
    deleteAdresseAllowed(
        adresse: KontaktpersonAdresseDaten,
        kontaktpersonAdresseCapabilities: KontaktpersonAdresseCapabilities
    ): boolean {
        return kontaktpersonAdresseCapabilities.existing.find(
            (adressCapabilities) => adressCapabilities.adresseId === adresse.id
        ).canDelete;
    }

    /**
     * Gibt zurück, ob das setzen einer Standard Adresse erlaubt ist
     * @param adresse Die Adresse der Kontaktperson
     */
    setStandardAdresseAllowed(
        adresse: KontaktpersonAdresseDaten,
        kontaktpersonAdresseCapabilities: KontaktpersonAdresseCapabilities
    ): boolean {
        return kontaktpersonAdresseCapabilities.existing.find(
            (adressCapabilities) => adressCapabilities.adresseId === adresse.id
        ).canSetAsStandardadresse;
    }

    /**
     * Gibt zurück, ob das bearbeiten der Erreichbarkeit erlaubt ist
     */
    editErreichbarkeitAllowed(
        erreichbarkeit: KontaktpersonErreichbarkeitDaten,
        kontaktpersonErreichbarkeitCapabilities: KontaktpersonErreichbarkeitCapabilities
    ): boolean {
        return kontaktpersonErreichbarkeitCapabilities.existing.find(
            (capabilities) => capabilities.erreichbarkeitId === erreichbarkeit.id
        ).canChange;
    }

    /**
     * Gibt zurück, ob das löschen der Erreichbarkeit erlaubt ist
     */
    deleteErreichbarkeitAllowed(
        erreichbarkeit: KontaktpersonErreichbarkeitDaten,
        kontaktpersonErreichbarkeitCapabilities: KontaktpersonErreichbarkeitCapabilities
    ): boolean {
        return kontaktpersonErreichbarkeitCapabilities.existing.find(
            (capabilities) => capabilities.erreichbarkeitId === erreichbarkeit.id
        ).canDelete;
    }

    //#endregion Helper Methoden
}
