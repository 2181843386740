import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    inject,
    signal
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UiConstants } from '@core/constants/ui-constants';
import { AlertService } from '@core/services/alert.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { K5NextHilfeNavigationService } from '@core/services/k5NextHilfe-navigation.service';
import { RightSidenavigationService } from '@core/services/right-sidenavigation.service';
import { UserInformationService } from '@core/services/user-information.service';
import {
    CreateOrEditGespeicherteSucheDialogComponent,
    DetailSuchkriterienDaten,
    GruppeDaten,
    JaNeinEnum,
    KontaktpersonArt,
    SaveGespeicherteSucheDialogResponseData,
    SelectableKriterium,
    SucheDaten
} from '@k5next/ui-components';
import {
    EditSectionConstants,
    KontaktmanagementDetailsuche,
    LoggingConstants
} from '@shared/constants/shared-constants';
import { CreateGespeicherteSucheRequest } from '@shared/models/createGespeicherteSucheRequest';
import { DetailsucheAdditionalSearchCriteria } from '@shared/models/detailsucheAdditionalSearchCriteria';
import { DetailsuchkriterienDaten } from '@shared/models/detailsuchkriterienDaten';
import { ActionButtonEnum } from '@shared/models/enums';
import { GeneralCapabilities } from '@shared/models/generalCapabilities';
import { KontaktmanagementClient } from '@shared/models/kontaktmanagementClient';
import { Personenart } from '@shared/models/personenart';
import { UIDetailsucheRequest } from '@shared/models/uIDetailsucheRequest';
import { UITeilenMitBenutzer } from '@shared/models/uiTeilenMitBenutzer';
import { KontaktpersonArtToPersonenartPipe, PersonenArtToKontaktpersonArtPipe } from '@shared/pipes/personen-art.pipe';
import { CapabilitiesService } from '@shared/services/capabilities.service';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { GruppenService } from '@shared/services/gruppen.service';
import { LoggingService } from '@shared/services/logging.service';
import { SchnellsucheService } from '@shared/services/schnellsuche.service';
import {
    BehaviorSubject,
    Observable,
    Subscription,
    catchError,
    combineLatest,
    distinctUntilChanged,
    of,
    startWith,
    switchMap
} from 'rxjs';
import { TagList } from 'src/app/buergerservice/kontaktmanagement/models/tags';
import { KontaktmanagementService } from 'src/app/buergerservice/kontaktmanagement/services/kontaktmanagement.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserShareDialogComponent, UserShareDialogData } from '../user-share-dialog/user-share-dialog.component';

@Component({
    selector: 'k5-search-panel-container',
    standalone: false,
    templateUrl: './search-panel-container.component.html',
    styleUrl: './search-panel-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPanelContainerComponent implements OnInit, OnDestroy {
    private readonly subscription = new Subscription();
    private readonly kontaktpersonArtToPersonenArtPipe = new KontaktpersonArtToPersonenartPipe();
    private readonly personenArtToKontaktpersonArtPipe = new PersonenArtToKontaktpersonArtPipe();

    /**
     * Allgemein
     */
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | null = null;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    loading = signal(true);
    initStarted = signal(false);
    pageSize = 50;
    currentParams: ParamMap | null = null;
    generalCapabilities: GeneralCapabilities | null = null;
    gespeicherteSucheExecuted: boolean = false;
    currentGespeicherteSuche: SucheDaten | null = null;
    confirmationDialogData = {
        title: 'Gespeicherte Suche löschen',
        content: 'Sind Sie sicher, dass Sie die gespeicherte Suche löschen wollen?',
        primaryButtonLabel: 'Gespeicherte Suche löschen',
        secondaryButtonLabel: 'Abbrechen'
    };
    /**
     * Suche
     */
    executedSuche: KontaktmanagementClient.GespeicherteSucheResponse | null = null;
    /**
     * Gespeicherte Suche
     */
    detailsucheData: UIDetailsucheRequest | null = null;
    selectedGespeicherteSucheSubject = new BehaviorSubject<SucheDaten | null>(null);
    /**
     * Die Form
     */
    suchkriterienForm = new FormGroup({
        art: new FormControl<KontaktpersonArt>(KontaktpersonArt.Alle, { nonNullable: true }),
        istRegistergebunden: new FormControl<JaNeinEnum>(JaNeinEnum.Alle, { nonNullable: true }),
        notiz: new FormControl<string | null>(''),
        // Kontakt
        name: new FormControl<string | null>(''),
        geburtsdatum: new FormControl<string | null>(''),
        erreichbarkeit: new FormControl<string | null>(''),
        gruppe: new FormControl<GruppeDaten | null>(null),
        firmenbuchNr: new FormControl<string | null>(''),
        uidNummer: new FormControl<string | null>(''),
        ansprFunktion: new FormControl<string | null>(''),
        tag: new FormControl<string | null>(''),
        // Adresse
        strasse: new FormControl<string | null>(''),
        orientierungsnummer: new FormControl<string | null>(''),
        ort: new FormControl<string | null>(''),
        postleitzahl: new FormControl<string | null>(''),
        gemeindekennziffer: new FormControl<string | null>(''),
        staatISO3: new FormControl<string | null>('')
    });
    /**
     * Gruppen
     */
    gruppenFromRequest: KontaktmanagementClient.GruppeResponse[] = [];
    gruppenSubject = new BehaviorSubject<GruppeDaten[]>([]);
    gruppen$: Observable<GruppeDaten[]> = this.gruppenSubject.asObservable();
    /**
     * Tags
     */
    allTags: string[] = [];
    selectableTagsSubject = new BehaviorSubject<string[]>([]);
    selectableTags$: Observable<string[]> = this.selectableTagsSubject.asObservable();
    selectedTags: string[] = [];
    /**
     * Gespeicherte Suchen
     */
    gespeicherteSuchenFromRequest: KontaktmanagementClient.GespeicherteSucheResponse[] = [];
    gespeicherteSuchenSubject = new BehaviorSubject<SucheDaten[]>([]);
    gespeicherteSuchen$: Observable<SucheDaten[]> = this.gespeicherteSuchenSubject.asObservable();
    /**
     * Suchkriterien
     */
    addtionalSuchkriterienFromRequest: DetailsucheAdditionalSearchCriteria[] = [];
    additionalSuchkriterien: SelectableKriterium[] = [
        {
            name: 'Registergeprüft',
            key: KontaktmanagementDetailsuche.IST_REGISTERGEBUNDEN,
            selected: false,
            display: true
        },
        {
            name: 'Notiz',
            key: KontaktmanagementDetailsuche.NOTIZ,
            selected: false,
            display: true
        },
        {
            name: 'Firmenbuchnummer',
            key: KontaktmanagementDetailsuche.FIRMENBUCH_NR,
            selected: false,
            display: true
        },
        {
            name: 'UID-Nummer',
            key: KontaktmanagementDetailsuche.UID_NUMMER,
            selected: false,
            display: true
        },
        {
            name: 'Ansprechpartner Funktion',
            key: KontaktmanagementDetailsuche.ANSPRECHPARTNER_FUNKTION,
            selected: false,
            display: true
        },
        {
            name: 'Postleitzahl',
            key: KontaktmanagementDetailsuche.POSTLEITZAHL,
            selected: false,
            display: true
        },

        {
            name: 'Gemeindekennziffer',
            key: KontaktmanagementDetailsuche.GKZ,
            selected: false,
            display: true
        },
        {
            name: 'Staat',
            key: KontaktmanagementDetailsuche.STAAT,
            selected: false,
            display: true
        },
        {
            name: 'Tag',
            key: KontaktmanagementDetailsuche.TAG,
            selected: false,
            display: true
        },
        {
            name: 'Gruppe',
            key: KontaktmanagementDetailsuche.GRUPPE,
            selected: false,
            display: true
        }
    ];
    additionalSuchkriterienSubject = new BehaviorSubject<SelectableKriterium[]>([]);
    additionalSuchkriterien$: Observable<SelectableKriterium[]> = this.additionalSuchkriterienSubject.asObservable();

    /**
     * Services
     */
    private readonly rightSideNavigationService = inject(RightSidenavigationService);
    private readonly k5NextHilfeNavigationService = inject(K5NextHilfeNavigationService);
    private readonly kontaktmanagementService = inject(KontaktmanagementService);
    private readonly userInformationService = inject(UserInformationService);
    private readonly capabilitiesService = inject(CapabilitiesService);
    private readonly gruppenService = inject(GruppenService);
    private readonly route = inject(ActivatedRoute);
    private readonly detailSucheService = inject(DetailsucheService);
    private readonly alertService = inject(AlertService);
    private readonly dialog = inject(MatDialog);
    private readonly schnellsucheService = inject(SchnellsucheService);
    private readonly loggingService = inject(LoggingService);
    private readonly router = inject(Router);
    private readonly globalEditService = inject(GlobalEditService);

    /**
     * Initialisiert den Komponentenstatus und setzt notwendige Subscriptions.
     * - Lädt die Daten der Detailsuche neu, wenn das rechte Seitenpanel geöffnet wird und das Formular keine Änderungen beinhaltet.
     * - Setzt eine Subscription auf die Route-Parameter, um neue Parameter zu speichern und bei Bedarf die Detailsuche-Daten zu laden oder den Suchstatus zu evaluieren.
     * - Initialisiert die `selectableTags$` Observable, um die Liste der auswählbaren Tags basierend auf den Formularwerten zu filtern.
     */
    ngOnInit(): void {
        // Lädt die Daten der Detailsuche neu, wenn diese wieder geöffnet wird
        this.subscription.add(
            this.rightSideNavigationService.rightSidenavOpen$.subscribe({
                next: (open: boolean) => {
                    // Wenn, das Panel geöffnet ist und die Form keine änderungen beinhaltet, dann wird das gesamte Panel neu initialisiert
                    if (open && !this.initStarted()) {
                        this.initStarted.set(true);
                        this.loadDetailSucheData();
                    }
                }
            })
        );

        // Subscription auf die Route-Parameter
        // Neue Parametern werden gespeichert
        this.subscription.add(
            this.route.queryParamMap.subscribe({
                next: (params: ParamMap) => {
                    if (params.keys.length > 0 && this.currentParams != params) {
                        this.currentParams = params;
                        if (!this.initStarted()) {
                            this.initStarted.set(true);
                            if (this.isLoadDataRequired()) {
                                this.loadDetailSucheData();
                            } else {
                                this.evaluateSucheState();
                            }
                        }
                    }
                }
            })
        );

        // Subscription auf die Tags in die Form
        // Bei Input Änderung wird die Autocomplete-Liste aktualisiert
        // Entfernen oder hinzugfügen von Tags wird die Subscription triggern und die Liste aktualisieren
        this.subscription.add(
            this.suchkriterienForm.controls.tag.valueChanges.pipe(distinctUntilChanged(), startWith(null)).subscribe({
                next: (tag: string | null) => {
                    let filteredList: string[];
                    if (tag) {
                        filteredList = this.filterTagList(tag);
                    } else {
                        filteredList = this.notSelectedTags;
                    }
                    this.selectableTagsSubject.next(filteredList);
                }
            })
        );
    }

    /**
     * Beendet die Komponente und gibt alle Ressourcen frei.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Liefert die Flag für die Anzeige der gespeicherte Suchen.
     * @returns true oder false.
     */
    get hasKMLizens(): boolean {
        return this.generalCapabilities?.menue?.hasKontaktmanagement ?? false;
    }

    /**
     * Liefert die aktuall nicht ausgewählten Tags.
     * @returns Die Liste der nicht ausgewählten Tags als string-Array.
     */
    get notSelectedTags(): string[] {
        return this.allTags.filter((tag: string) => !this.selectedTags.includes(tag));
    }

    /**
     * Beschafft alle Daten, die für die Detailsuche benötigt werden können.
     * @remarks
     * - Lädt die Capabilities und setzt eine Collection von Observables zusammen, abhängig von den erhaltenen Capabilities.
     * - Wenn alle Observables die Daten erfolgreich liefern könnte, werden die Daten aufgearbeitet und die State des UIs verwaltet.
     */
    loadDetailSucheData(): void {
        this.loading.set(true);
        this.capabilitiesService
            .getGeneralCapabilities()
            .pipe(
                catchError(() => {
                    this.alertService.error('Fehler bei der Abfrage der Capabilities');
                    return of(null);
                }),
                switchMap(
                    (
                        capabilities: GeneralCapabilities | null
                    ): Observable<
                        [
                            TagList,
                            DetailsucheAdditionalSearchCriteria[] | null,
                            KontaktmanagementClient.GruppeResponse[],
                            KontaktmanagementClient.GespeicherteSuchenResponse
                        ]
                    > => {
                        // Setzt die generellen Capabilities und stellt eine Collection von Requests zusammen
                        this.generalCapabilities = capabilities;
                        return combineLatest([
                            this.kontaktmanagementService.getTags(),
                            this.userInformationService.getBenutzerEinstellungFromService<
                                DetailsucheAdditionalSearchCriteria[]
                            >(UiConstants.KONTAKT_SEARCH_CRITERIA),
                            this.generalCapabilities?.gruppe?.canRead ? this.gruppenService.getGruppen() : of([]),
                            this.generalCapabilities?.gespeicherteSuche?.canRead
                                ? this.kontaktmanagementService.getSuchen()
                                : of({} as KontaktmanagementClient.GespeicherteSuchenResponse)
                        ]).pipe(
                            catchError((error: HttpErrorResponse) => {
                                this.alertService.errorResponse(error, 'Fehler bei Abfrage der Daten');
                                return of([
                                    { tags: [] } as TagList,
                                    null,
                                    [],
                                    {} as KontaktmanagementClient.GespeicherteSuchenResponse
                                ] as [
                                    TagList,
                                    DetailsucheAdditionalSearchCriteria[] | null,
                                    KontaktmanagementClient.GruppeResponse[],
                                    KontaktmanagementClient.GespeicherteSuchenResponse
                                ]);
                            })
                        );
                    }
                )
            )
            .subscribe({
                next: ([tagList, suchkriterien, gruppen, suchen]) => {
                    /**
                     * Tags
                     */
                    if (tagList) {
                        this.allTags = tagList?.tags ?? [];
                        this.selectableTagsSubject.next(this.allTags);
                    }

                    /**
                     * Suchkriterien
                     */
                    if (suchkriterien) {
                        this.updateAdditionalSuchkriterien(suchkriterien);
                    }
                    this.addtionalSuchkriterienFromRequest = suchkriterien ?? [];
                    this.additionalSuchkriterienSubject.next(this.additionalSuchkriterien);
                    if (this.generalCapabilities) {
                        this.setSearchCriteriaWithCapabilities(this.generalCapabilities, this.additionalSuchkriterien);
                    }

                    /**
                     * Gruppen
                     */
                    if (gruppen) {
                        this.gruppenFromRequest = gruppen;
                        // Gruppen auf das View-Model ummappen
                        this.gruppenSubject.next(this.mapGruppeResponeIntoGruppeDaten(gruppen));
                    }

                    /**
                     * Suchen
                     */
                    if (suchen?.responses) {
                        // Gespeicherte Suchen laden
                        this.gespeicherteSuchenFromRequest = suchen.responses;
                        // Gespeicherte Suchen auf das View-Model ummappen
                        const tempGespeicherteSuchen: SucheDaten[] = [];
                        this.gespeicherteSuchenFromRequest.forEach(
                            (sourceSuche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                                if (sourceSuche.suche) {
                                    tempGespeicherteSuchen.push(
                                        this.mapGespeicherteSucheIntoSucheDaten(sourceSuche.suche)
                                    );
                                }
                            }
                        );
                        this.selectedGespeicherteSucheSubject.next(null);
                        // Gespeicherte Suchen View-Model in das Observable schreiben
                        this.gespeicherteSuchenSubject.next(tempGespeicherteSuchen);
                    }

                    this.evaluateSucheState();
                    this.loading.set(false);
                }
            });
    }

    /**
     * Evaluiert den Status der Detailsuche anhand der URL-Parameter.
     */
    evaluateSucheState(): void {
        this.initStarted.set(true);
        if (this.currentParams) {
            // Wenn die Parameter den Search Key beinhalten, wurde eine Suche durchgeführt
            if (this.currentParams.has(KontaktmanagementDetailsuche.SEARCH_KEY)) {
                this.evaluateSucheWithSearchKey();
                // Wenn die Parameter den Gruppe Key beinhalten, wurde nach Kontakten von einer Gruppe gesucht
            } else if (this.currentParams.has(KontaktmanagementDetailsuche.GRUPPE_KEY)) {
                this.evaluateSucheWithGruppeKey();
            }
        }
        this.initStarted.set(false);
    }

    /**
     * Wertet die Suche nach 'SEARCH_KEY' Suchschlüssel aus.
     * @remarks
     * Zuerst wird der aktuelle Parameterwert für den Suchschlüssel ausgelesen.
     * Dann wird die Suchdaten aus dem Storage anhand des Suchschlüssels zu laden.
     * - Wenn die Suchdaten im Storage gefunden werden, werden diese in das Formular geschrieben.
     * - Wenn die Suchdaten nicht im Storage gefunden werden, wird eine gespeicherte Suche anhand des aktuellen Parameterwerts zu laden.
     *   - Wenn eine gespeicherte Suche gefunden wird:
     *     - Alle Datenquellen werden aktualisiert.
     *     - Die selektierte gespeicherte Suche wird gesetzt.
     *   - Wenn keine gespeicherte Suche gefunden wird, werden neue Suchen abgefragt, da in der Zwischenzeit Suchen mit dem Benutzer geteilt worden sein könnten.
     */
    evaluateSucheWithSearchKey(): void {
        const currentParamValue = this.currentParams?.get(KontaktmanagementDetailsuche.SEARCH_KEY);
        // Suche aus dem Storage nach dem Key laden.
        this.detailsucheData = this.getSucheByKeyFromStorage(KontaktmanagementDetailsuche.SEARCH_KEY) ?? null;
        // Die Suche wurde im Storage gefunden --> hier handelt es sich um eine normalle Suche
        if (this.detailsucheData?.suchkriterien) {
            // SucheDaten in das Formular schreiben
            this.setSucheDatenIntoForm(this.detailsucheData.suchkriterien);
        }
        // Die Suche wurde im Storage nicht gefunden --> hier handels es sich um eine gespeicherte Suche
        else {
            // gespeicherte Suche aus der Liste laden
            const gespeicherteSuche: KontaktmanagementClient.GespeicherteSucheResponse | undefined =
                this.findGespeicherteSuche(currentParamValue);

            if (gespeicherteSuche?.suche?.suchkriterien) {
                // Alle Sources Aktualisieren
                this.updateDataSources(gespeicherteSuche.suche.suchkriterien);
                // Selektierte gespeicherte Suche setzen
                this.selectedGespeicherteSucheSubject.next(
                    this.mapGespeicherteSucheIntoSucheDaten(gespeicherteSuche.suche)
                );
            } else {
                // Wennn die Suche nicht gefunden wurde, werden neue Suchen abgefragt, da mit dem Benutzer in der Zwischenzeit Suchen geteilt werden könnten
                this.getSuchen();
            }
        }
    }

    /**
     * Wertet die Suche nach 'GRUPPE_KEY' Schlüssel aus.
     * @remarks
     * Diese Methode liest den aktuellen Parameterwert aus, identifiziert die Gruppe anhand der Parameter-ID
     * und aktualisiert die Datenquellen, wenn die Gruppe gefunden wird. Falls die Gruppe nicht gefunden wird,
     * wird die Methode `getGruppen` aufgerufen, um die Gruppen neu zu laden.
     */
    evaluateSucheWithGruppeKey(): void {
        // Param Value auslesen
        const currentParamValue = this.currentParams?.get(KontaktmanagementDetailsuche.GRUPPE_KEY);
        // Gruppe anhand der Param Value/Id identifizieren
        if (currentParamValue) {
            let gruppe = null;
            gruppe = this.getGruppeDatenById(currentParamValue);
            if (gruppe) {
                const daten: KontaktmanagementClient.DetailsuchkriterienDaten = {
                    gruppeId: gruppe.id
                };
                this.updateDataSources(daten);
            }
            // Gruppe nicht gefunden, muss neu geladen werden
            else {
                this.getGruppen();
            }
        }
    }

    //#region Gruppen

    /**
     * Fragt die Liste aller Suchen ab und initialisiert die Detailsuche.
     */
    getGruppen(): void {
        this.gruppenService.getGruppen().subscribe({
            next: (gruppen: KontaktmanagementClient.GruppeResponse[]) => {
                if (gruppen) {
                    this.gruppenFromRequest = gruppen;
                    this.gruppenSubject.next(this.mapGruppeResponeIntoGruppeDaten(gruppen));
                }
                const daten: KontaktmanagementClient.GespeicherteSucheDaten = {
                    suchkriterien: {
                        gruppeId: this.currentParams?.get(KontaktmanagementDetailsuche.GRUPPE_KEY)
                    }
                };
                if (daten) {
                    const gruppedaten = this.mapGespeicherteSucheIntoSucheDaten(daten);
                    this.setSucheDatenIntoForm(gruppedaten.suchkriterien as DetailsuchkriterienDaten);
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der Gruppen');
            }
        });
    }

    /**
     * Öffnet den Dialog für das Teilen einer Suche.
     * @param search ausgewählte Suche
     */
    shareSuche(search: SucheDaten): void {
        this.kontaktmanagementService.getSucheInfo(search.id).subscribe({
            next: (suche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                const users: string[] =
                    suche.suche?.geteiltMitBenutzern
                        ?.map((benutzer: KontaktmanagementClient.BenutzerDaten) => benutzer.id)
                        .filter((id): id is string => !!id) || [];
                const userShareDialogRef = this.dialog.open<
                    UserShareDialogComponent,
                    UserShareDialogData,
                    UITeilenMitBenutzer[]
                >(UserShareDialogComponent, {
                    width: '25rem',
                    data: {
                        title: 'Gespeicherte Suche teilen',
                        allowMultipleSelection: true,
                        selectedUsers: users,
                        saveDisabled: false
                    }
                });

                userShareDialogRef.componentInstance.saveSelection
                    .pipe(
                        switchMap(
                            (
                                userList: UITeilenMitBenutzer[]
                            ): Observable<KontaktmanagementClient.GespeicherteSucheResponse | null> => {
                                userShareDialogRef.componentInstance.saveButtonDisabled = true;
                                if (suche.suche?.id) {
                                    return this.kontaktmanagementService
                                        .shareSucheWithBenutzer(
                                            {
                                                benutzerIds: userList.map(
                                                    (user: UITeilenMitBenutzer) => user.data?.id ?? ''
                                                )
                                            },
                                            suche.suche?.id
                                        )
                                        .pipe(
                                            catchError((error: HttpErrorResponse) => {
                                                userShareDialogRef.componentInstance.saveButtonDisabled = false;
                                                this.alertService.errorResponse(
                                                    error,
                                                    'Fehler beim Teilen einer Suche'
                                                );
                                                return of(null);
                                            })
                                        );
                                }
                                return of(null);
                            }
                        )
                    )
                    .subscribe({
                        next: (response: KontaktmanagementClient.GespeicherteSucheResponse | null) => {
                            if (response) {
                                userShareDialogRef.componentInstance.saveButtonDisabled = false;
                                this.alertService.success('Suche erfolgreich geteilt');
                                userShareDialogRef.close();
                            } else {
                                this.alertService.error('Fehler beim Teilen einer Suche');
                                userShareDialogRef.componentInstance.saveButtonDisabled = false;
                            }
                        }
                    });
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der Suche');
            }
        });
    }
    //#endregion

    //#region Suche

    /**
     * Ruft gespeicherte Suchanfragen vom Kontaktmanagement-Service ab und verarbeitet die erhaltenen Daten.
     * Diese Methode führt eine Anfrage an den `kontaktmanagementService` aus, um gespeicherte Suchanfragen zu erhalten.
     * Die erhaltenen Daten werden in ein View-Model umgewandelt und in ein Observable geschrieben.
     * Wenn eine gespeicherte Suche identifiziert wird, wird diese ins Observable geschrieben und die Datenquellen werden aktualisiert.
     * @remarks
     * - Die Methode verwendet den `kontaktmanagementService`, um die Daten abzurufen.
     * - Die erhaltenen Daten werden in das `gespeicherteSuchenSubject` Observable geschrieben.
     * - Wenn eine gespeicherte Suche gefunden wird, wird diese in das `selectedGespeicherteSucheSubject` Observable geschrieben und die Datenquellen werden aktualisiert.
     */
    getSuchen(): void {
        this.kontaktmanagementService.getSuchen().subscribe({
            next: (sucheDaten: KontaktmanagementClient.GespeicherteSuchenResponse) => {
                if (sucheDaten) {
                    this.gespeicherteSuchenFromRequest = sucheDaten.responses ?? [];
                    // Gespeicherte Suchen auf das View-Model ummappen
                    const gespeicherteSuchen: SucheDaten[] = [];
                    sucheDaten.responses?.forEach((sourceSuche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                        if (sourceSuche.suche) {
                            gespeicherteSuchen.push(this.mapGespeicherteSucheIntoSucheDaten(sourceSuche.suche));
                        }
                    });
                    // Gespeicherte Suchen View-Model in das Observable schreiben
                    this.gespeicherteSuchenSubject.next([...gespeicherteSuchen]);
                    // gespeicherte Suche identifizieren
                    const gespeicherteSuche: KontaktmanagementClient.GespeicherteSucheResponse | undefined =
                        this.findGespeicherteSuche(this.currentParams?.get(KontaktmanagementDetailsuche.SEARCH_KEY));
                    if (gespeicherteSuche?.suche) {
                        this.detailsucheData = {
                            suchkriterien: gespeicherteSuche?.suche?.suchkriterien,
                            sucheId: gespeicherteSuche?.suche?.id
                        } as UIDetailsucheRequest;
                        // Gespeicherte Suche ins Observable schreiben
                        this.selectedGespeicherteSucheSubject.next(
                            this.mapGespeicherteSucheIntoSucheDaten(gespeicherteSuche.suche)
                        );
                        // Daten aktualisieren
                        this.updateDataSources(gespeicherteSuche?.suche);
                    }
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler bei der Abfrage der gespeicherten Suchen');
            }
        });
    }

    /**
     * Setzt die Suche-Panel zurück.
     */
    resetSuche(): void {
        // Form zurücksetzen
        this.suchkriterienForm.reset();
        // selektierte gespeicherte Suche zurücksetzen
        this.selectedGespeicherteSucheSubject.next(null);
        // Tags zurücksetzen
        this.selectedTags = [];
        this.suchkriterienForm.controls.tag.setValue('');
        // gespeicherte Route Params werden zurückgesetzt,
        // sonst wird bei öffnen des Panels wieder eine Suche laut die URL Param geladen
        this.currentParams = null;
        // Standard suchkriterien setzen
        this.updateAdditionalSuchkriterien(this.addtionalSuchkriterienFromRequest);
        this.additionalSuchkriterienSubject.next(this.additionalSuchkriterien);
    }

    /**
     * Handhabt die Suchparameter und navigiert entsprechend.
     * Diese Methode wird aufgerufen, um die Suchparameter zu verarbeiten und eine Navigation basierend auf diesen Parametern durchzuführen.
     * @param triggeredFromGespeicherteSuche - Gibt an, ob die Suche durch die Auswahl einer gespeicherten Suche getriggert wurde.
     * - Wenn `triggeredFromGespeicherteSuche` false ist, wird die aktuell ausgewählte gespeicherte Suche zurückgesetzt.
     * - Wenn das Suchformular gültig ist, werden die Suchkriterien aus dem Formular extrahiert und in ein `UIDetailsucheRequest`-Objekt übertragen.
     * - Bestimmte Felder werden basierend auf ihren Werten im Formular gesetzt oder auf null gesetzt.
     * - Die Suchkriterien werden geloggt und leere Strings werden durch null ersetzt.
     * - Wenn die Suche durch eine gespeicherte Suche getriggert wurde, wird die ID der gespeicherten Suche als Query-Parameter übergeben.
     * - Wenn die Suche manuell getriggert wurde, wird ein neuer Schlüssel für den SessionStorage generiert und die Suchkriterien werden dort gespeichert.
     * - Die Methode navigiert dann zur aktuellen Route mit den entsprechenden Query-Parametern.
     * - Wenn keine Suchparameter eingegeben wurden, wird eine Informationsmeldung angezeigt.
     */
    executeSucheAndNavigate(triggeredFromGespeicherteSuche: boolean): void {
        // Schnellsuche Input muss gecleart werden
        this.schnellsucheService.emitClearSearchInput();
        if (this.suchkriterienForm.valid) {
            // Query erstellen anhand der Formulardaten
            const query = this.createSucheQuery();

            if (query.suchkriterien) {
                // Die Suchkriterien loggen
                this.trackUseSuchkriterien(query.suchkriterien);
                // Ersetzt alle leeren Strings mit null
                Object.keys(query.suchkriterien).forEach((kriteriumKey: string) => {
                    return query.suchkriterien?.[kriteriumKey as keyof DetailsuchkriterienDaten] ?? null;
                });
            }

            if (query) {
                let guid: string;
                // Suche wurde aus einer gespeicherten Suche getriggert --> nicht in den SessionStorage speichern
                // Die Id der gespeicherten Suche wird als Query-Parameter übergeben
                if (triggeredFromGespeicherteSuche && this.currentGespeicherteSuche) {
                    guid = this.currentGespeicherteSuche.id;
                }
                // Die Suche wurde manuell getriggert --> in den SessionStorage speichern
                else {
                    guid = this.detailSucheService.generateSessionStorageKey();
                    // Daten im Storage speichern, wird bei Initializierung der Komponente (z.B.: F5) wieder ausgelesen
                    this.detailSucheService.saveSearchInStorage(query, guid);
                    // Wenn eine Suche nicht durch die Auswahl einer gespeicherte Suche getriggert wurde, muss die selektierte Suche zurückgesetzt werden
                    this.currentGespeicherteSuche = null;
                    // selektierte gespeicherte Suche zurücksetzen, wenn vorhanden ist
                    if (this.selectableTagsSubject.value) {
                        this.selectedGespeicherteSucheSubject.next(null);
                    }
                }
                this.router.navigate([], { relativeTo: this.route, queryParams: { searchKey: guid } });
            } else {
                this.router.navigate([], { relativeTo: this.route, queryParams: {} });
                this.alertService.info('Keine Suchparameter eingegeben.');
            }
        }
    }

    /**
     * Erstellt eine Suche-Query basierend auf den Werten des Forms.
     * @returns  Die erstellte Suche-Query.
     */
    createSucheQuery(): UIDetailsucheRequest {
        const query: UIDetailsucheRequest = { suchkriterien: {} };
        let registerGebundenValue: boolean | undefined;
        switch (this.suchkriterienForm.value.istRegistergebunden) {
            case JaNeinEnum.Ja:
                registerGebundenValue = true;
                break;
            case JaNeinEnum.Nein:
                registerGebundenValue = false;
                break;
            default:
                registerGebundenValue = undefined;
                break;
        }
        query.suchkriterien = {
            istRegistergebunden: registerGebundenValue,
            ansprFunktion: this.suchkriterienForm.value.ansprFunktion ?? undefined,
            art:
                this.kontaktpersonArtToPersonenArtPipe.transform(this.suchkriterienForm.value.art ?? null) ?? undefined,
            erreichbarkeit: this.suchkriterienForm.value.erreichbarkeit ?? undefined,
            firmenbuchNr: this.suchkriterienForm.value.firmenbuchNr ?? undefined,
            geburtsdatum: this.suchkriterienForm.value.geburtsdatum ?? undefined,
            gemeindekennziffer: this.suchkriterienForm.value.gemeindekennziffer ?? undefined,
            name: this.suchkriterienForm.value.name ?? undefined,
            notiz: this.suchkriterienForm.value.notiz ?? undefined,
            orientierungsnummer: this.suchkriterienForm.value.orientierungsnummer ?? undefined,
            ort: this.suchkriterienForm.value.ort ?? undefined,
            postleitzahl: this.suchkriterienForm.value.postleitzahl ?? undefined,
            staatISO3: this.suchkriterienForm.value.staatISO3 ?? undefined,
            strasse: this.suchkriterienForm.value.strasse ?? undefined,
            tag: this.selectedTags?.join(','),
            uidNummer: this.suchkriterienForm.value.uidNummer ?? undefined,
            gruppeId: this.suchkriterienForm.value.gruppe?.id ?? undefined,
            ansprName: undefined,
            suchstring: undefined
        };

        query.pageSize = this.pageSize;
        query.sortBy = [];
        query.pageNumber = 0;
        return query;
    }

    /**
     * Öffnet einen Dialog zum Speichern einer Suche und behandelt die Interaktionen im Dialog.
     * - Öffnet den `CreateOrEditGespeicherteSucheDialogComponent` Dialog mit den aktuellen Suchdaten.
     * - Abonniert auf Änderungen im Dialog, um den Edit-Modus zu setzen.
     * - Abonniert auf das Speichern-Ereignis im Dialog, um die Suchanfrage zu erstellen und zu speichern.
     * - Loggt die Zeit und Ereignisse beim Speichern der Suche.
     * - Fügt die neue Suche zur Liste der gespeicherten Suchen hinzu.
     * - Wechselt in den Read-Modus und schließt den Dialog bei erfolgreichem Speichern.
     * - Zeigt Erfolgsmeldungen oder Fehlermeldungen basierend auf dem Ergebnis des Speichervorgangs an.
     */
    openSaveSucheDialog(): void {
        // Dialog öffnen
        const dialogRef = this.dialog.open(CreateOrEditGespeicherteSucheDialogComponent, {
            data: {
                title: 'Suche speichern',
                sucheDaten: this.selectedGespeicherteSucheSubject.value
            },
            width: '38%',
            height: 'auto'
        });

        // Wenn im Dialog Veränderung passiert, Edit-Mode setzen
        this.subscription.add(
            dialogRef.componentInstance.setEditMode.subscribe(() => {
                this.globalEditService.switchToEditMode(EditSectionConstants.KONTAKTE_SUCHE_SPEICHERN);
            })
        );

        this.subscription.add(
            dialogRef.componentInstance.saveSearch.subscribe((response: SaveGespeicherteSucheDialogResponseData) => {
                // Request erstellen
                const request: CreateGespeicherteSucheRequest = this.createSearchRequest(
                    response.beschreibung ?? '',
                    response.name
                );
                // Speichern existierender Suche
                if (response.editExisting && this.selectedGespeicherteSucheSubject.value) {
                    this.saveExistingSearch(this.selectedGespeicherteSucheSubject.value.id, request);
                }
                // Speichern eine neue Suche
                else if (request.spalten && request.suchkriterien) {
                    this.saveNewSearch(request);
                } else {
                    this.alertService.warning('Bitte führen Sie vorher eine Suche aus');
                }
                this.dialog.closeAll();
            })
        );
    }

    /**
     * Speichert eine neue Suche.
     * @remarks
     * Nach dem Speichern der Suche wird die Zeit, die für das Speichern benötigt wurde, geloggt.
     * Die gespeicherte Suchen werden aktualisiert.
     * Der Edit-Modus wird in den Read-Modus gewechselt.
     * @param request - Die Anfrage zum Speichern der Suche.
     */
    saveNewSearch(request: CreateGespeicherteSucheRequest): void {
        const startFrom: number = new Date().getTime();
        this.kontaktmanagementService.createSuche(request).subscribe({
            next: (response: KontaktmanagementClient.GespeicherteSucheResponse) => {
                if (response) {
                    // Loggt die Zeit, die für das Speichern einer Suche benötigt wurde
                    this.loggingService.logMetricWithElapsedTime(
                        LoggingConstants.KM_DETAILSUCHE_SPEICHERN,
                        startFrom,
                        new Date().getTime()
                    );
                    // Loggt ein Event, wenn eine Suche gespeichert wird
                    this.loggingService.logEvent(LoggingConstants.KM_DETAILSUCHE_SPEICHERN);
                    // Die neue Suche in die gespeicherte Suchen Liste hinzufügen
                    this.gespeicherteSuchenFromRequest.unshift(response);
                    //this.gespeicherteSuchenSubject.next(suchen);
                    const tempGespeicherteSuchen: SucheDaten[] = [];

                    this.gespeicherteSuchenFromRequest.forEach(
                        (sourceSuche: KontaktmanagementClient.GespeicherteSucheResponse) => {
                            if (sourceSuche.suche) {
                                tempGespeicherteSuchen.push(this.mapGespeicherteSucheIntoSucheDaten(sourceSuche.suche));
                            }
                        }
                    );
                    this.gespeicherteSuchenSubject.next([...tempGespeicherteSuchen]);
                    // Ins Read-Mode wechseln
                    this.globalEditService.switchToReadMode();
                    this.alertService.success('Suche erfolgreich gespeichert');
                }
            },
            error: (error: HttpErrorResponse) => {
                this.alertService.errorResponse(error, 'Fehler beim Speichern einer Suche');
            }
        });
    }

    /**
     * Speichert eine bestehende Suche.
     * @param search - Die Daten der gespeicherten Suche, die aktualisiert werden sollen.
     * Diese Methode erstellt eine Anfrage zur Änderung der gespeicherten Suche und schließt alle offenen Dialoge.
     * Sie wechselt den globalen Bearbeitungsmodus in den Lesemodus und überprüft, ob die Anfrage gültige Spalten und Suchkriterien enthält.
     * Wenn die Anfrage gültig ist, wird die gespeicherte Suche aktualisiert und die entsprechenden Subjekte werden benachrichtigt.
     * Bei Erfolg wird eine Erfolgsmeldung angezeigt und alle Dialoge werden geschlossen.
     * Bei einem Fehler wird eine Fehlermeldung angezeigt.
     * Wenn die Anfrage keine gültigen Spalten und Suchkriterien enthält, wird eine Warnung angezeigt.
     * Wenn keine Suche ausgewählt wurde, wird eine Informationsmeldung angezeigt.
     */
    saveExistingSearch(searchId: string, request: CreateGespeicherteSucheRequest): void {
        if (searchId) {
            this.dialog.closeAll();
            this.globalEditService.switchToReadMode();
            if (request.spalten && request.suchkriterien) {
                this.kontaktmanagementService.updateSuche(request, searchId).subscribe({
                    next: (sucheDatenResponse: KontaktmanagementClient.GespeicherteSucheResponse) => {
                        if (sucheDatenResponse.suche) {
                            const index = this.gespeicherteSuchenSubject.value.findIndex(
                                (suche) => suche.id === (sucheDatenResponse.suche?.id ?? '')
                            );
                            const sucheRespone = this.mapGespeicherteSucheIntoSucheDaten(sucheDatenResponse.suche);
                            const suchen = this.gespeicherteSuchenSubject.value;
                            // neue Suche austauschen
                            suchen.splice(index, 1, sucheRespone);
                            // gespeicherte Suchen aktualisieren
                            this.gespeicherteSuchenSubject.next(suchen);
                            // selektierte gespeicherte Suche aktualisieren
                            this.selectedGespeicherteSucheSubject.next(sucheRespone);
                            this.alertService.success('Suche erfolgreich gespeichert');
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.alertService.errorResponse(error, 'Fehler beim Speichern einer Suche');
                    }
                });
            } else {
                this.alertService.warning('Bitte führen Sie vorher eine Suche aus');
            }
        } else {
            this.alertService.info('Bitte vorher eine Suche auswählen');
        }
    }

    /**
     * Ruft eine Suche basierend auf einem Schlüssel aus dem Speicher ab.
     * @param searchkey - Der Schlüssel, der verwendet wird, um die Suche aus dem Speicher abzurufen.
     * @returns Ein `UIDetailsucheRequest`-Objekt, das die Details der abgerufenen Suche enthält.
     */
    getSucheByKeyFromStorage(searchkey: string): UIDetailsucheRequest | undefined | null {
        if (this.currentParams && searchkey) {
            return this.detailSucheService.getSearchFromStorage(this.currentParams.get(searchkey) ?? '');
        }
        return undefined;
    }

    /**
     * Findet eine gespeicherte Suche anhand der übergebenen Suche-ID.
     * @param sucheId - Die ID der zu findenden gespeicherten Suche.
     * @returns Die gefundene gespeicherte Suche als `KontaktmanagementClient.GespeicherteSucheResponse` oder `undefined`, wenn keine Suche mit der angegebenen ID gefunden wurde.
     */
    findGespeicherteSuche(
        sucheId: string | null | undefined
    ): KontaktmanagementClient.GespeicherteSucheResponse | undefined {
        if (!sucheId) {
            return undefined;
        }
        return this.gespeicherteSuchenFromRequest.find(
            (storedSuche: KontaktmanagementClient.GespeicherteSucheResponse) => storedSuche?.suche?.id === sucheId
        );
    }

    //#endregion

    //#region Tags

    /**
     * Filtert die Liste der verfügbaren Tags basierend auf dem angegebenen Wert.
     * @remarks Diese Methode wird verwendet, um die Tags zu filtern, die in der Autocomplete-Liste angezeigt werden.
     * Erst wird alle Tags aus der source 'allTags' die nicht selektiert sind 'selectedTags' wieder in die Observable geschrieben.
     * Dann wird die Liste der auswählbaren Tags gefiltert und zurückgegeben.
     * @param value - Der Wert, nach dem die Tags gefiltert werden sollen.
     * @returns Eine Liste der gefilterten Tags, die den angegebenen Wert enthalten.
     */
    filterTagList(value: string): string[] {
        const filterValue = value?.toLowerCase();
        return this.notSelectedTags.filter((tag) => tag?.toLowerCase().includes(filterValue)) ?? [];
    }

    /**
     * Fügt einen Tag zur selectedTags hinzu.
     * Entfernt den Tag aus der selectableTags, wenn er bereits existiert.
     * Aktualisiert die Observable.
     * @param event - Das MatChipInputEvent-Objekt, das den Event-Parameter enthält.
     */
    addTagToChipList(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Add Tag
        if (value && !this.selectedTags.find((tag: string) => tag?.toLowerCase() === value?.toLowerCase())) {
            this.selectedTags.push(value);
            // Filtert die selektierte Tags aus der selectableTags aus
            let notSelectedTags: string[] = [];
            notSelectedTags = this.selectableTagsSubject.value.filter(
                (tag) => tag.toLowerCase() !== value?.toLowerCase()
            );
            // Auswählbare Tags aktualisieren
            this.selectableTagsSubject.next(notSelectedTags);
        }
        // Clear den Tag-Input
        event.chipInput.clear();
        // Subscription auf den Form-Value triggern, dass die Autocomplete Liste aktualisiert wird
        this.suchkriterienForm.controls.tag.setValue(null);
    }

    /**
     * Entfernt den Tag aus der selectableTags.
     * Fügt ihn zur selectedTags hinzu und aktualisiert die Observable.
     * @param event - Das MatAutocompleteSelectedEvent-Objekt, das den ausgewählten Tag enthält.
     */
    selectTag(event: MatAutocompleteSelectedEvent): void {
        // Input clearen
        if (this.tagInput) {
            this.tagInput.nativeElement.value = '';
        }
        // Ausgewählten Tag in die selectedTags schreiben
        this.selectedTags.push(event.option.value);

        // Subscription auf den Form-Value triggern, dass die Autocomplete Liste aktualisiert wird
        this.suchkriterienForm.controls.tag.setValue(null);
    }

    /**
     * Entfernt einen Tag aus den selectedTags und aktualisiert das Observable.
     * @param tagToRemove - Der zu entfernende Tag.
     */
    removeTagFromChipList(tagToRemove: string): void {
        // Tag aus der selectedTags entfernen
        this.selectedTags = this.selectedTags.filter((tag: string) => tag !== tagToRemove);
        // Subscription auf den Form-Value triggern, dass die Autocomplete Liste aktualisiert wird
        this.selectableTagsSubject.next(this.notSelectedTags);
    }

    //#endregion

    //#region Gespeicherte Suche

    /**
     * Löscht eine gespeicherte Suche und aktualisiert die entsprechenden Listen und View-Modelle.
     * @param sucheToDelete - Die zu löschende Suche.
     * @param dialogRef - Referenz auf den Bestätigungsdialog.
     * Diese Methode führt die folgenden Schritte aus:
     * 1. Sendet eine Anfrage an den `kontaktmanagementService`, um die Suche zu löschen.
     * 2. Schließt alle offenen Dialoge.
     * 3. Entfernt die gelöschte Suche aus der gespeicherten Suchen Source Liste.
     * 4. Entfernt die gelöschte Suche aus dem gespeicherten Suchen View-Modell.
     * 5. Zeigt eine Erfolgsmeldung an, wenn die Suche erfolgreich gelöscht wurde.
     * 6. Bei einem Fehler wird der Bestätigungsdialog nicht geschlossen und eine Fehlermeldung angezeigt.
     */
    executeDeleteGespeicherteSuche(
        sucheToDelete: SucheDaten,
        dialogRef: MatDialogRef<ConfirmationDialogComponent>
    ): void {
        this.kontaktmanagementService.deleteSuche(sucheToDelete.id).subscribe({
            next: () => {
                this.dialog.closeAll();
                // Gelöschte Suche aus der gespeicherte Suchen Source Liste entfernen
                this.gespeicherteSuchenFromRequest = this.gespeicherteSuchenFromRequest.filter(
                    (sucheRespone: KontaktmanagementClient.GespeicherteSucheResponse) =>
                        sucheRespone.suche?.id !== sucheToDelete.id
                );
                // Gelöschte Suche aus der gespeicherte Suchen entfernen
                const alleSuche = this.gespeicherteSuchenSubject.value.filter(
                    (gespeicherteSuche: SucheDaten) => gespeicherteSuche.id !== sucheToDelete.id
                );
                this.gespeicherteSuchenSubject.next(alleSuche);
                this.resetSuche();
                this.alertService.success('Suche erfolgreich gelöscht');
            },
            error: (error: HttpErrorResponse) => {
                dialogRef.componentInstance.data.primaryButtonDisabled = false;
                this.alertService.errorResponse(error, 'Fehler beim Löschen einer gespeicherten Suche');
            }
        });
    }

    /**
     * Handhabt das Löschen einer gespeicherten Suche.
     * Öffnet einen Bestätigungsdialog, um die Löschung zu bestätigen.
     * Wenn die primäre Schaltfläche im Dialog betätigt wird, wird die gespeicherte Suche gelöscht.
     * Falls die gelöschte Suche aktuell im Formular geladen ist, wird das Formular zurückgesetzt.
     * @param sucheToDelete - Die zu löschende gespeicherte Suche.
     */
    deleteGespeicherteSuche(sucheToDelete: SucheDaten): void {
        const confimationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: this.confirmationDialogData
        });
        confimationDialogRef.componentInstance.dialogAction.subscribe({
            next: (actionButtonValue: ActionButtonEnum) => {
                if (actionButtonValue === ActionButtonEnum.Primary) {
                    confimationDialogRef.componentInstance.data.primaryButtonDisabled = true;
                    // Löschvorgang starten
                    this.executeDeleteGespeicherteSuche(sucheToDelete, confimationDialogRef);
                    // Die Formularansicht zurückgesetzt, wenn die gespeicherte Suche vorher in die Form geladen wurde
                    if (
                        this.selectedGespeicherteSucheSubject.value?.id &&
                        sucheToDelete.id === this.selectedGespeicherteSucheSubject.value.id
                    ) {
                        this.selectedGespeicherteSucheSubject.next(null);
                    }
                } else {
                    confimationDialogRef.close();
                }
            }
        });
    }

    /**
     * Führt eine gespeicherte Suche aus.
     * @param geklickteSuche - Die Daten der geklickten gespeicherten Suche.
     * Diese Methode speichert den Zustand der ausgeführten gespeicherten Suche,
     * aktualisiert die Sichtbarkeit zusätzlicher Suchkriterien, setzt die ausgewählten Tags,
     * entfernt die eingesetzten Tags aus der Autocomplete-Liste, speichert die selektierte
     * gespeicherte Suche zwischen und führt die Suche aus.
     */
    executeGespeicherteSuche(geklickteSuche: SucheDaten): void {
        // Source der Ausführung speichern
        this.gespeicherteSucheExecuted = true;
        // Wenn die gespeicherte Suche 2 mal nacheinander ausgeführt wird, wird die Form bei nicht Wegnavigierung nicht neu befüllt, da die Url nicht verändert wurde
        if (this.currentGespeicherteSuche?.id === geklickteSuche.id) {
            // Panel mit der Suchedaten befüllen
            this.setSucheDatenIntoForm(
                this.mapDetailSuchkriterienDatenIntoDetailsuchkriterienDaten(geklickteSuche.suchkriterien)
            );
        }
        this.updateAdditionalSuchkriterienVisibility(geklickteSuche.suchkriterien);
        // Selektierte Tags setzen und die Autokomplete aktualisieren Subscription triggern
        if (geklickteSuche.suchkriterien.tag) {
            this.selectedTags = geklickteSuche.suchkriterien.tag.split(',');
            this.selectableTagsSubject.next(this.notSelectedTags);
        } else {
            this.selectedTags = [];
        }
        // die geklickte Suche zwischenspeichern
        this.currentGespeicherteSuche = geklickteSuche;
        this.selectedGespeicherteSucheSubject.next(geklickteSuche);
        // Suche ausführen
        this.executeSucheAndNavigate(true);
    }

    /**
     * Mapped die Daten von DetailSuchkriterienDaten in ein neues Objekt vom Typ DetailsuchkriterienDaten.
     * @param source - Das Quellobjekt vom Typ DetailSuchkriterienDaten, das die zu mappenden Daten enthält.
     * @returns Ein neues Objekt vom Typ DetailsuchkriterienDaten, das die gemappten Daten enthält.
     */
    mapDetailSuchkriterienDatenIntoDetailsuchkriterienDaten(
        source: DetailSuchkriterienDaten
    ): DetailsuchkriterienDaten {
        return {
            suchstring: source.suchstring ?? undefined,
            art: this.kontaktpersonArtToPersonenArtPipe.transform(source.art ?? null) ?? undefined,
            name: source.name ?? undefined,
            geburtsdatum: source.geburtsdatum ?? undefined,
            erreichbarkeit: source.erreichbarkeit ?? undefined,
            tag: source.tag ?? undefined,
            strasse: source.strasse ?? undefined,
            orientierungsnummer: source.orientierungsnummer ?? undefined,
            ort: source.ort ?? undefined,
            postleitzahl: source.postleitzahl ?? undefined,
            gemeindekennziffer: source.gemeindekennziffer ?? undefined,
            staatISO3: source.staatISO3 ?? undefined,
            istRegistergebunden: source.istRegistergebunden ?? undefined,
            notiz: source.notiz ?? undefined,
            firmenbuchNr: source.firmenbuchNr ?? undefined,
            uidNummer: source.uidNummer ?? undefined,
            ansprName: source.ansprName ?? undefined,
            ansprFunktion: source.ansprFunktion ?? undefined,
            gruppeId: source.gruppeId ?? undefined
        } as DetailsuchkriterienDaten;
    }

    //#region Mappings
    /**
     * Mapped die Suchkriterien aus dem Quellobjekt in ein DetailSuchkriterienDaten-Objekt.
     * @param sourceSuckriterien - Die Suchkriterien aus dem KontaktmanagementClient.DetailsuchkriterienDaten.
     * @returns Ein neues DetailSuchkriterienDaten-Objekt mit den gemappten Werten.
     */
    mapSuchkriterienIntoDetailSuchkriterienDaten(
        sourceSuckriterien: KontaktmanagementClient.DetailsuchkriterienDaten
    ): DetailSuchkriterienDaten {
        return {
            suchstring: sourceSuckriterien?.suchstring,
            art: this.mapToKontaktpersonArt(sourceSuckriterien?.art),
            name: sourceSuckriterien?.name,
            geburtsdatum: sourceSuckriterien?.geburtsdatum,
            erreichbarkeit: sourceSuckriterien?.erreichbarkeit,
            tag: sourceSuckriterien?.tag,
            strasse: sourceSuckriterien?.strasse,
            orientierungsnummer: sourceSuckriterien?.orientierungsnummer,
            ort: sourceSuckriterien?.ort,
            postleitzahl: sourceSuckriterien?.postleitzahl,
            gemeindekennziffer: sourceSuckriterien?.gemeindekennziffer,
            staatISO3: sourceSuckriterien?.staatISO3,
            istRegistergebunden: sourceSuckriterien?.istRegistergebunden,
            notiz: sourceSuckriterien?.notiz,
            firmenbuchNr: sourceSuckriterien?.firmenbuchNr,
            uidNummer: sourceSuckriterien?.uidNummer,
            ansprName: sourceSuckriterien?.ansprName,
            ansprFunktion: sourceSuckriterien?.ansprFunktion,
            gruppeId: sourceSuckriterien?.gruppeId
        };
    }

    /**
     * Mappt die Grupperesponse in GruppeDaten.
     * @param source von GruppeResponse.
     * @returns ein Array von GruppeDaten.
     */
    mapGruppeResponeIntoGruppeDaten(source: KontaktmanagementClient.GruppeResponse[]): GruppeDaten[] {
        const gruppen = source?.map((gruppeResponse: KontaktmanagementClient.GruppeResponse) => {
            return {
                id: gruppeResponse.daten?.id,
                name: gruppeResponse.daten?.name
            };
        });
        return gruppen ?? [];
    }

    /**
     * Mappt die DetailsucheAdditionalSuchkriterien in SelectableKriterium.
     * @param source von DetailsucheAdditionalSuchkriterien.
     */
    updateAdditionalSuchkriterien(source: DetailsucheAdditionalSearchCriteria[]): void {
        this.additionalSuchkriterien.forEach((kriterium: SelectableKriterium) => {
            source.forEach((newKriterium: DetailsucheAdditionalSearchCriteria) => {
                if (kriterium.key === newKriterium.key) {
                    kriterium.selected = newKriterium.selected ?? false;
                }
            });
        });
    }

    /**
     * Konvertiert eine `KontaktmanagementClient.Personenart` in eine `KontaktpersonArt`.
     * @param source - Die zu konvertierende `KontaktmanagementClient.Personenart` oder `null`.
     * @returns Die entsprechende `KontaktpersonArt`. Wenn `source` `null` ist oder keinen passenden Fall hat, wird `KontaktpersonArt.Alle` zurückgegeben.
     */
    mapToKontaktpersonArt(source: KontaktmanagementClient.Personenart | null | undefined): KontaktpersonArt {
        switch (source) {
            case KontaktmanagementClient.Personenart.Natuerlich:
                return KontaktpersonArt.Natuerlich;
            case KontaktmanagementClient.Personenart.Unternehmen:
                return KontaktpersonArt.Unternehmen;
            default:
                return KontaktpersonArt.Alle;
        }
    }

    /**
     * Konvertiert ein Objekt vom Typ `GespeicherteSucheDaten` in ein Objekt vom Typ `SucheDaten`.
     * @param source - Das `GespeicherteSucheDaten`-Objekt, das konvertiert werden soll.
     * @returns Ein neues `SucheDaten`-Objekt, das die konvertierten Daten enthält.
     */
    mapGespeicherteSucheIntoSucheDaten(source: KontaktmanagementClient.GespeicherteSucheDaten): SucheDaten {
        return {
            id: source.id ?? '',
            name: source.name ?? '',
            beschreibung: source.beschreibung,
            besitzer: source.besitzer,
            spalten: source.spalten,
            suchkriterien: this.mapSuchkriterienIntoDetailSuchkriterienDaten(source?.suchkriterien ?? {}),
            istEigeneSuche: source.istEigeneSuche,
            istNeueSuche: source.istNeueSuche,
            zuletztAusgefuehrt: source.zuletztAusgefuehrt,
            geaendertVon: source.geaendertVon,
            geteiltMitBenutzern: source.geteiltMitBenutzern,
            letzteAenderung: source.letzteAenderung
        };
    }
    //#endregion

    //#region Helper Methoden

    /**
     * Überprüft, ob das Laden von Daten erforderlich ist.
     * @remarks
     * Diese Methode prüft verschiedene Berechtigungen und den Zustand der Daten,
     * um zu bestimmen, ob ein erneutes Laden der Daten notwendig ist.
     * @returns true oder false.
     */
    isLoadDataRequired(): boolean {
        if (this.generalCapabilities?.gruppe) {
            // Berechtigung auf die Gruppen
            if (this.generalCapabilities.gruppe.canRead) {
                // Berechtigung auf die gespeicherte Suche
                if (this.generalCapabilities.gespeicherteSuche?.canRead) {
                    // Daten vorhanden
                    if (this.gruppenFromRequest.length > 0 && this.gespeicherteSuchenSubject.value.length > 0) {
                        return false;
                    }
                    // Daten fehlen --> reload notwending
                    return true;
                }
                // Daten vorhanden
                if (this.gruppenFromRequest.length > 0) {
                    return false;
                }
                // Daten fehlen --> reload notwending
                return true;
            } else {
                // Daten vorhanden
                if (this.allTags.length > 0 && this.additionalSuchkriterienSubject.value.length > 0) {
                    return false;
                }
                return true;
            }
        }
        return true;
    }

    /**
     * Aktualisiert die Datenquellen basierend auf den angegebenen Suchkriterien.
     * @param suchkriterien - Die Suchkriterien, die zur Aktualisierung der Datenquellen verwendet werden.
     * Diese Methode führt zwei Hauptaktionen aus:
     * 1. Aktualisiert das Formular mit den angegebenen Suchkriterien.
     * 2. Aktualisiert die Sichtbarkeit zusätzlicher Suchkriterien basierend auf den Suchkriterien.
     */
    updateDataSources(suchkriterien: KontaktmanagementClient.DetailsuchkriterienDaten): void {
        // Die Form aktualisieren
        this.setSucheDatenIntoForm(suchkriterien as DetailsuchkriterienDaten);
        this.selectedTags = suchkriterien.tag ? suchkriterien.tag.split(',') : [];
        this.suchkriterienForm.controls.tag.setValue('');
        // Suckriterien visibility aktualisieren
        this.updateAdditionalSuchkriterienVisibility(this.mapSuchkriterienIntoDetailSuchkriterienDaten(suchkriterien));
    }

    /**
     * Protokolliert die Verwendung von Suchkriterien.
     * Diese Methode überprüft die verschiedenen Eigenschaften des übergebenen
     * `DetailSuchkriterienDaten`-Objekts und protokolliert die Verwendung der
     * jeweiligen Suchkriterien, indem sie entsprechende Ereignisse an den
     * `loggingService` sendet.
     * @param suchkriterien - Die Suchkriterien, die protokolliert werden sollen.
     */
    trackUseSuchkriterien(suchkriterien: DetailSuchkriterienDaten): void {
        if (suchkriterien.ansprFunktion) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ANSPRFUNKTION
            });
        }

        if (suchkriterien.ansprName) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ANSPRNAME
            });
        }

        if (suchkriterien.art) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ART
            });
        }

        if (suchkriterien.erreichbarkeit) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ERREICHBARKEIT
            });
        }

        if (suchkriterien.firmenbuchNr) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.FIRMENBUCHNUMMER
            });
        }

        if (suchkriterien.geburtsdatum) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.GEBURTSDATUM
            });

            // Loggt die erweiterte Suchriterien vom Geburtsdatum Property
            this.trackUsedErweiterteSuchkriterium(suchkriterien.geburtsdatum);
        }

        if (suchkriterien.gemeindekennziffer) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.GEMEINDEKENNZIFFER
            });
        }

        if (suchkriterien.name) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.NAME
            });
        }

        if (suchkriterien.istRegistergebunden !== null && suchkriterien.istRegistergebunden !== undefined) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ISTREGISTERGEBUNDEN
            });
        }

        if (suchkriterien.notiz) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.NOTIZ
            });
        }

        if (suchkriterien.orientierungsnummer) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ORIENTIERUNGSNUMMER
            });
        }

        if (suchkriterien.ort) {
            this.loggingService.logEvent(LoggingConstants.KM_SEARCHCRITERIA_USED, {
                [LoggingConstants.KEY_NAME]: LoggingConstants.ORT
            });
        }
    }

    /**
     * Aktualisiert die Auswahl der Suchkriterien und speichert diese in den Benutzereinstellungen.
     * @param kriterien - Eine Liste von auswählbaren Kriterien.
     * Diese Methode führt folgende Schritte aus:
     * 1. Extrahiert die Schlüssel der veränderten Kriterien.
     * 2. Aktualisiert die Auswahlstatus der gespeicherten Kriterien basierend auf den extrahierten Schlüsseln.
     * 3. Aktualisiert das Observable `additionalSuchkriterien$` mit den neuen Kriterien.
     * 4. Speichert die aktualisierten Suchkriterien in den Benutzereinstellungen des Benutzers.
     */
    suchkriteriumSelectionChange(kriterien: SelectableKriterium[]): void {
        // Keys aus der veränderten Kriterien auslesen
        const selectionKeys = kriterien.map((selection) => selection.key);
        //
        this.additionalSuchkriterien.forEach((storedKriterium: SelectableKriterium) => {
            storedKriterium.selected = selectionKeys.includes(storedKriterium.key);
        });
        // Observable updaten
        this.additionalSuchkriterienSubject.next(this.additionalSuchkriterien);
        const kriterienJson = JSON.parse(JSON.stringify(this.additionalSuchkriterien));
        // Suchkriterien in der Benutzereinstellungen speichern
        this.userInformationService
            .saveBenutzereinstellung(kriterienJson, UiConstants.KONTAKT_SEARCH_CRITERIA)
            .subscribe();
    }

    /**
     * Aktualisiert die Sichtbarkeit der zusätzlichen Suchkriterien basierend auf den übergebenen Kriteriendaten.
     * Aktualisiert die Observable.
     * @param kriterien - Die DetailSuchkriterienDaten, die verwendet werden, um die Sichtbarkeit der Kriterien zu aktualisieren.
     */
    updateAdditionalSuchkriterienVisibility(kriterien: DetailSuchkriterienDaten): void {
        // Kriterien durchiterieren
        this.additionalSuchkriterien.forEach((kriterium: SelectableKriterium) => {
            // Wert des Kriterium aus den übergebenen Kriteriendaten mit dem Key auslesen
            const kriteriumValue = kriterien[kriterium.key as keyof DetailSuchkriterienDaten];
            // Zusätzliche Prüfung für die Gruppen:
            // Die DetailSuchkriterienDaten Key hat gruppeId als Referenz für die Gruppen aber in der Form wird 'gruppe' verwendet.
            // Deswegen wird die Gruppe separat, manuell behandelt.
            if (kriterium.key === 'gruppe') {
                kriterium.selected = !!kriterien['gruppeId'];
            } else {
                // Wenn Daten vorhanden sind, wird das Kriterium sichtbar gemacht
                kriterium.selected = kriteriumValue !== null && kriteriumValue !== undefined && kriteriumValue !== '';
            }
        });
        // Lifecycle hook does not detect changes within the internal state of an object, such as a BehaviorSubject.
        // It only detects changes to the reference of the input property itself.
        this.additionalSuchkriterienSubject.next(new Array(...this.additionalSuchkriterien));
    }

    /**
     * Loggt ein Event, wenn der User erweiterte Suchkriterien verwendet.
     * @param geburtsdatum Gebrutsdatum als String.
     */
    trackUsedErweiterteSuchkriterium(geburtsdatum: string): void {
        // Alle Parameter durchiterieren
        for (const key in LoggingConstants.ERWEITERTE_SUCHPARAMETERN) {
            const value = LoggingConstants.ERWEITERTE_SUCHPARAMETERN[key];
            const anzahl = this.getUsedAnzahlvonParameterimGeburtsdatum(geburtsdatum, value);
            if (anzahl > 0) {
                this.loggingService.logEvent(LoggingConstants.KM_SUCHKRITERIUM_ERWEITERT_USED, { [value]: anzahl });
            }
        }
    }

    /**
     * Wenn das Geburtsdatum den Value enthält, wird die Anzahl der Fälle retourniert.
     * @param geburtsdatum - Das Geburtsdatum als String.
     * @param value - Der Value, der im Geburtsdatum gesucht wird.
     * @returns Anzahl von Paramtern im Geburtsdatum als Number.
     */
    getUsedAnzahlvonParameterimGeburtsdatum(geburtsdatum: string, value: string): number {
        if (geburtsdatum.includes(value)) {
            return geburtsdatum.split(value).length - 1;
        }
        return 0;
    }

    /**
     * Setzt die DetailsuchkriterienDaten in das Formular.
     * @param suche - Die DetailsuchkriterienDaten, die in das Formular gesetzt werden sollen.
     */
    setSucheDatenIntoForm(suche: DetailsuchkriterienDaten): void {
        this.suchkriterienForm.patchValue({
            art: this.personenArtToKontaktpersonArtPipe.transform(suche.art ?? null) ?? undefined,
            ansprFunktion: suche.ansprFunktion,
            erreichbarkeit: suche.erreichbarkeit,
            firmenbuchNr: suche.firmenbuchNr,
            geburtsdatum: suche.geburtsdatum,
            gruppe: this.getGruppeDatenById(suche.gruppeId ?? ''),
            orientierungsnummer: suche.orientierungsnummer,
            gemeindekennziffer: suche.gemeindekennziffer,
            istRegistergebunden: this.evaluateRegistergebundenAsBoolean(suche.istRegistergebunden),
            name: suche.name,
            notiz: suche.notiz,
            ort: suche.ort,
            postleitzahl: suche.postleitzahl,
            staatISO3: suche.staatISO3,
            strasse: suche.strasse,
            tag: suche.tag,
            uidNummer: suche.uidNummer
        });
        // Die Form muss als dirty markiert werden, damit die Änderungen der Form bei closing nicht verloren gehen
        this.suchkriterienForm.markAsDirty();
    }

    /**
     * Gibt die Gruppe basierend auf der Gruppen-ID zurück.
     * @param gruppeId Die ID der Gruppe.
     * @returns Die Gruppe oder ein leeres Objekt, wenn keine Gruppe mit der angegebenen ID gefunden wurde.
     */
    getGruppeDatenById(gruppeId: string): GruppeDaten | undefined {
        return this.gruppenSubject.value.find((gruppe: GruppeDaten) => gruppe.id === gruppeId);
    }

    /**
     * Wandelt einen booleschen Wert in ein JaNeinEnum um.
     * @param value - Der zu evaluierende boolesche Wert.
     * @returns Das entsprechende JaNeinEnum.
     */
    evaluateRegistergebundenAsBoolean(value: boolean | null | undefined): JaNeinEnum {
        switch (value) {
            case true:
                return JaNeinEnum.Ja;
            case false:
                return JaNeinEnum.Nein;
            default:
                return JaNeinEnum.Alle;
        }
    }

    /**
     * Schließt das Detailsuche Panel
     */
    closeDetailsuchePanel(): void {
        this.rightSideNavigationService.closeRightSidenav();
    }

    /**
     * Überprüft, ob ein Kriterium mit dem angegebenen Schlüssel ausgewählt ist.
     * @param key - Der Schlüssel des Kriteriums, das überprüft werden soll.
     * @returns true, wenn das Kriterium ausgewählt ist, andernfalls false.
     */
    isKriteriumSelected(key: string): boolean {
        let result: SelectableKriterium | undefined = undefined;
        result = this.additionalSuchkriterien.find((kriterium: SelectableKriterium) => kriterium.key === key);
        return result ? result.selected : false;
    }

    /**
     * Setzt die Darstellung von Suchkriterien anhand der Capabilities
     * @param generalCapabilities Capabilities
     * @param searchCriteriaList Suchkriterien
     */
    setSearchCriteriaWithCapabilities(
        generalCapabilities: GeneralCapabilities,
        searchCriteriaList: SelectableKriterium[]
    ) {
        if (generalCapabilities.detailsuche) {
            this.setDisplayOfSearchCritera(
                searchCriteriaList,
                KontaktmanagementDetailsuche.NOTIZ,
                generalCapabilities.detailsuche.canSeeNotizCriteria ?? true
            );

            this.setDisplayOfSearchCritera(
                searchCriteriaList,
                KontaktmanagementDetailsuche.TAG,
                generalCapabilities.detailsuche.canSeeTagCriteria ?? true
            );

            this.setDisplayOfSearchCritera(
                searchCriteriaList,
                KontaktmanagementDetailsuche.GRUPPE,
                generalCapabilities.detailsuche.canSeeGruppeCriteria ?? true
            );
        }
    }

    /**
     * Behandelt den Klick auf einen Tooltip-Link.
     * @param link - Der Link, auf den geklickt wurde.
     */
    tooltipLinkClick(link: string): void {
        this.k5NextHilfeNavigationService.openk5NextHilfePage(link);
    }

    /**
     * Setzt die Anzeige eines weiteren Suchkriteriums
     * @param searchCriteria Suchkriterium
     * @param key Key
     * @param selected Ob das Suchkriterium angezeigt werden soll
     */
    setDisplayOfSearchCritera(searchCriteria: SelectableKriterium[], key: string, display: boolean): void {
        const foundSearchCriteria = searchCriteria?.find((criteria) => criteria.key === key);
        if (foundSearchCriteria) {
            foundSearchCriteria.display = display;
        }
    }

    /**
     * Setzt die Daten des Requests beim Speichern einer Suche.
     * @param beschreibung Beschreibung der Suche
     * @param name Name der Suche
     * @returns Befüllter request
     */
    createSearchRequest(beschreibung: string, name: string): CreateGespeicherteSucheRequest {
        const request: CreateGespeicherteSucheRequest = {
            beschreibung: beschreibung,
            name: name
        };

        if (this.currentParams?.has(KontaktmanagementDetailsuche.SEARCH_KEY)) {
            request.spalten = [];
            const cols = this.detailSucheService.getDisplayedColumns();
            this.detailsucheData = this.detailSucheService.getSearchFromStorage(
                this.currentParams.get(KontaktmanagementDetailsuche.SEARCH_KEY) ?? ''
            );
            if (!this.detailsucheData) {
                this.detailsucheData = {
                    sucheId: this.selectedGespeicherteSucheSubject.value?.id,
                    suchkriterien: this.selectedGespeicherteSucheSubject.value
                        ?.suchkriterien as DetailsuchkriterienDaten
                };
                // Suchedaten Art ins PersonenArt ummappen
                const personenArt: Personenart | null = this.kontaktpersonArtToPersonenArtPipe.transform(
                    this.selectedGespeicherteSucheSubject.value?.suchkriterien.art ?? KontaktpersonArt.Alle
                );

                if (this.detailsucheData.suchkriterien) {
                    this.detailsucheData.suchkriterien.art = personenArt ?? undefined;
                }
            }
            this.detailsucheData.sortBy = [this.detailSucheService.getSorting()];
            request.suchkriterien = this.detailsucheData.suchkriterien;

            for (const column of cols) {
                request.spalten.push({
                    breite: undefined,
                    key: column,
                    sortierung: this.getSortingForColumn(column) ?? undefined
                });
            }
        }
        return request;
    }

    /**
     * Gibt die Sortierung für eine Spalte formatiert zurück.
     * @param column die Spalte.
     * @returns die Sortierung für die Spalte oder null.
     */
    getSortingForColumn(column: string): string | null {
        if (!this.detailsucheData?.sortBy) {
            return null;
        } else if (this.detailsucheData.sortBy[0]?.split(' ')[0].toLowerCase() === column.toLowerCase()) {
            return this.detailsucheData.sortBy[0].split(' ')[1].toLowerCase();
        } else {
            return null;
        }
    }
    //#endregion
}
