// @ts-strict-ignore
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

/**
 * Komponente für den Title einer Seite mit Zurückbutton
 */
@Component({
    selector: 'k5-page-header',
    templateUrl: './page-header.component.html'
})
export class PageHeaderComponent {
    @Input()
    header: string;

    @Input()
    icon: string;

    @Input()
    backNavigationUrl: string;

    @Input()
    backNavigationParameters: NavigationExtras;

    @Output()
    backClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private location: Location,
        private router: Router
    ) {}

    /**
     * Navigiert zurück
     */
    navigateBack(): void {
        this.backClick.emit(true);
        if (this.backNavigationUrl) {
            this.router.navigate([this.backNavigationUrl], this.backNavigationParameters);
        } else {
            this.location.back();
        }
    }
}
