// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { Dictionary } from '@core/models/dictionary';
import { ChartLegend } from '@shared/chart-legend';
import { WahlDetailsuche } from '@shared/constants/shared-constants';
import { WAHLKARTENSTATISTIK_THEME } from '@shared/models/chartColors';
import { OffeneBearbeitungen, SucheWahlkartenStatusEnum } from '@shared/models/enums';
import { WahlbestandSuchkriterien } from '@shared/models/wahlbestandSuchkriterien';
import { Wahldaten } from '@shared/models/wahldaten';
import { WahlkartenstatistikResponse } from '@shared/models/wahlkartenstatistikResponse';
import { DetailsucheService } from '@shared/services/detailsuche.service';
import { ChartOptions } from 'chart.js';
import { WahldetailService } from 'src/app/buergerservice/wahl/services/wahldetail.service';

@Component({
    selector: 'k5-wahlkartenstatistik-widget',
    templateUrl: './wahlkartenstatistik-widget.component.html'
})
export class WahlkartenstatistikWidgetComponent implements OnInit {
    @Input()
    wahl: Wahldaten | null = null;

    @Output()
    remove: EventEmitter<Event> = new EventEmitter<Event>();

    wahlkartenstatistik: WahlkartenstatistikResponse = null;

    dataSource: ChartLegend[] = [];

    // Bar-Chart
    barChartOptions: ChartOptions<'bar'> = null;
    barChartLabels = ['Wahlkarten'];
    barChartDatasets = null;
    barChartLegend = false;

    colorScheme = {
        domain: WAHLKARTENSTATISTIK_THEME
    };

    error: boolean = false;
    loading: boolean = true;

    constructor(
        private router: Router,
        private wahlService: WahldetailService,
        private detailsucheService: DetailsucheService
    ) {}

    ngOnInit(): void {
        this.loadWidgetData();
    }

    /**
     * Emmitiert ein Event nach Klick auf den Remove Button
     * @param event ClickEvent
     */
    removeWidget(event: Event): void {
        this.remove.emit(event);
    }

    /**
     * Lädt die Daten für die Anzeige im Widget
     */
    loadWidgetData(): void {
        this.loading = true;
        this.error = false;
        this.wahlService.getWahlkartenstatistik(this.wahl?.id).subscribe({
            next: (data: WahlkartenstatistikResponse) => {
                this.wahlkartenstatistik = data;
                this.buildLegend(data);
                this.loading = false;
            },
            error: () => {
                this.error = true;
                this.loading = false;
            }
        });
    }

    /**
     * Baut die Daten für die Anzeige zusammen
     * @param wahlkartenstatistik Wahlkartenstatistik
     */
    buildLegend(wahlkartenstatistik: WahlkartenstatistikResponse): void {
        const legend: ChartLegend[] = [
            {
                id: 1,
                name: 'Wahlkarten ausgestellt',
                amount: wahlkartenstatistik.wahlkartenAusgestellt,
                color: WAHLKARTENSTATISTIK_THEME[0]
            },
            {
                id: 2,
                name: 'Wahlkartendruck offen',
                amount: wahlkartenstatistik.wahlkartendruckOffen,
                color: WAHLKARTENSTATISTIK_THEME[1]
            },
            {
                id: 3,
                name: 'Etikettdruck offen',
                amount: wahlkartenstatistik.etikettdruckOffen,
                color: WAHLKARTENSTATISTIK_THEME[2]
            },
            {
                id: 4,
                name: 'Aufgabeliste Post offen',
                amount: wahlkartenstatistik.aufgabelistePostOffen,
                color: WAHLKARTENSTATISTIK_THEME[3]
            }
        ];

        this.dataSource = legend;

        const totalAmount = wahlkartenstatistik.wahlkartenGesamt;

        this.barChartDatasets = legend.map((x) => {
            return {
                label: [x.name],
                data: [x.amount],
                backgroundColor: x.color,
                maxBarThickness: 24
            };
        });

        this.barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            indexAxis: 'y',
            scales: {
                x: {
                    max: totalAmount,
                    stacked: true,
                    display: false,
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    display: false,
                    grid: {
                        display: false
                    }
                }
            }
        };
    }

    /**
     * Navigiert in die Detailsuche des Wahlbestands mit den vordefinierten Suchparametern
     */
    navigateToWahlbestand(item: ChartLegend): void {
        const suchkriterien: Dictionary<string> = {};

        switch (item.id) {
            case 1:
                suchkriterien[WahlDetailsuche.WK_STATUS] = SucheWahlkartenStatusEnum.Ausgestellt.toString();
                break;
            case 2:
                suchkriterien[WahlDetailsuche.OFFENE_BEARBEITUNGEN] = OffeneBearbeitungen.Wahlkarten.toString();
                break;
            case 3:
                suchkriterien[WahlDetailsuche.OFFENE_BEARBEITUNGEN] = OffeneBearbeitungen.Etiketten.toString();
                break;
            case 4:
                suchkriterien[WahlDetailsuche.OFFENE_BEARBEITUNGEN] = OffeneBearbeitungen.Postaufgabeliste.toString();
                break;
        }

        const guid = this.detailsucheService.generateSessionStorageKey();

        const wahlbestandSuchkriterien: WahlbestandSuchkriterien = {
            suchkriterien,
            schnellsucheQuery: null,
            sort: null
        };
        this.detailsucheService.saveSearchInStorage(wahlbestandSuchkriterien, guid);
        this.router.navigate([RouterLinkConstants.WAHL, this.wahl?.id, PathConstants.WAHLBESTAND], {
            queryParams: { searchKey: guid }
        });
    }
}
