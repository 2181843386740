import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textOverflow'
})
export class TextOverflowPipe implements PipeTransform {
    /**
     * Gibt einen Text ab einer maximalen Länge mit drei Punkten zurück
     * Ansonsten wird der Text ausgeschrieben
     */
    transform(text: string, maxLength: number): string {
        if (!text || !maxLength) {
            return '';
        }
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    }
}
