import { Pipe, PipeTransform } from '@angular/core';
import { JaNeinAlleConstants } from '@shared/constants/shared-constants';
import { JaNeinEnum } from '@shared/models/jaNeinEnum';

@Pipe({
    name: 'jaNeinEnum'
})
export class JaNeinEnumPipe implements PipeTransform {

    /**
     * Übersetzt Enum-Werte in eine textuelle Form.
     * Im UI kann damit auch ein neutraler Wert zur Auswahl gegeben werden
     * @param value JaNeinEnum
     */
    transform(value: JaNeinEnum): string {
        switch (value) {
            case JaNeinEnum.Ja:
                return JaNeinAlleConstants.JA;
            case JaNeinEnum.Nein:
                return JaNeinAlleConstants.NEIN;
            case JaNeinEnum.Alle:
                return JaNeinAlleConstants.ALLE;
            default:
                return '-';
        }
    }

}
