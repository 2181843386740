import { Injectable } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RightSidenavigationService {
    /**
     * Status der rechten side-navigation
     */

    private readonly rightSidenavOpenSubject = new BehaviorSubject<boolean>(false);
    private readonly rightSidenavModeSubject = new BehaviorSubject<MatDrawerMode>('side');

    readonly rightSidenavOpen$ = this.rightSidenavOpenSubject.asObservable();
    readonly rightSidenavMode$ = this.rightSidenavModeSubject.asObservable();

    /**
     * Gibt den Öffnungsstatus der rechten side-navigation zurück
     */
    get rightSidenavOpen(): boolean {
        return this.rightSidenavOpenSubject.value;
    }

    /**
     * Letzter Wert des rechten side-navigation-Modus
     */
    get rightSidenavMode(): MatDrawerMode {
        return this.rightSidenavModeSubject.value;
    }

    /**
     * Umschalten der rechten side-navigation
     */
    toggleRightSidenav() {
        this.rightSidenavOpenSubject.next(!this.rightSidenavOpenSubject.getValue());
    }

    /**
     * Öffnet die rechte side-navigation
     */
    openRightSidenav() {
        this.rightSidenavOpenSubject.next(true);
    }

    /**
     * Schließt die rechte side-navigation
     */
    closeRightSidenav() {
        this.rightSidenavOpenSubject.next(false);
    }
}
