/**
 * k5|Next - Kontaktmanagement v2
 * Öffentliche API von k5|Next Kontaktmanagement.
 *
 * OpenAPI spec version: v1
 * Contact: info@programmierfabrik.at
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Enum für die unterstützten Bildformate.
 */
export type Bildformat = 0 | 1 | 2 | 3 | 4;

export const Bildformat = {
    None: 0 as Bildformat,
    Jpeg: 1 as Bildformat,
    Png: 2 as Bildformat,
    Gif: 3 as Bildformat,
    Svg: 4 as Bildformat
};
