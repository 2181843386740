import { Component, Input } from '@angular/core';
import { AlertType } from '../../models/enums';
import { MatSnackBarRef } from '@angular/material/snack-bar';

/**
 * Komponente für die Darstellung eines Alerts mit flexiblen Inhalt
 */
@Component({
    selector: 'k5-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

    // Lösung für Enum-Vergleich in *ngIf-Direktive
    ALERT_TYPES = AlertType;

    @Input()
    titleMessage: string = 'Es ist ein Fehler aufgetreten.';

    @Input()
    alertType: AlertType = AlertType.Info;

    @Input()
    detailsAvailable: boolean = false;

    closeActionText: string = 'Schließen';
    showDetailsActionText: string = 'Details anzeigen';

    showDetails = false;

    constructor(public snackBarRef: MatSnackBarRef<AlertComponent>) {
    }

    /**
     * Toggelt die Error Details
     */
    toggleDetails(): void {
        this.showDetails = !this.showDetails;
        if (this.showDetails) {
            this.showDetailsActionText = "Details verbergen";
        }
        else {
            this.showDetailsActionText = "Details anzeigen";
        }
    }

    /**
     * Schließt die SnackBar
     */
    closeSnackbar(): void {
        this.snackBarRef.dismiss();
    }

}
