import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SimpleAlert } from '@core/models/simpleAlert';

@Component({
    selector: 'k5-simple-alert',
    templateUrl: './simple-alert.component.html'
})
export class SimpleAlertComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public simpleAlert: SimpleAlert) {}

    /**
     * Gibt an, ob Details vorhanden sind
     */
    get detailsAvailable(): boolean {
        return !!this.simpleAlert.detailMessage;
    }
}
