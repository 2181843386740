import { Pipe, PipeTransform } from '@angular/core';
import { ZustelladresseVorhanden } from '@shared/models/enums';

@Pipe({
    name: 'zustelladresseVorhanden'
})
export class ZustelladresseVorhandenPipe implements PipeTransform {
    /**
     * Übersetzt Enum-Werte des ZustelladresseVorhanden in eine textuelle Form.
     * @param value Zustelladresse
     */
    transform(value: ZustelladresseVorhanden): string {
        switch (value) {
            case ZustelladresseVorhanden.Ja:
                return 'ja';
            case ZustelladresseVorhanden.Nein:
                return 'nein';
            case ZustelladresseVorhanden.Unvollstaendig:
                return 'unvollständig';
        }
    }
}
