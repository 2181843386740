<div class="edit-section-container">
    <k5-section-header [title]="heading" [icon]="icon" data-cy="edit-section-header">
        <div class="flex">
            <!-- Aktion zum Editieren des Bereichs -->
            @if (isEditPencilVisible()) {
                <div class="ml-2">
                    <button
                        mat-icon-button
                        type="button"
                        aria-label="Bearbeiten"
                        [matTooltip]="heading + ' bearbeiten'"
                        id="editButton"
                        class="actionIcon"
                        (click)="switchToEditMode()"
                        data-cy="edit-section-edit-button"
                    >
                        <mat-icon fontSet="fa-duotone" fontIcon="fa-pencil" class="fg-primary text-base"></mat-icon>
                    </button>
                </div>
            }
            <!-- Aktion zum Hinzufügen -->
            @if (isAddButtonVisible()) {
                <div class="ml-2">
                    <button
                        mat-icon-button
                        type="button"
                        aria-label="Hinzufügen"
                        [matTooltip]="heading + ' hinzufügen'"
                        id="addButton"
                        class="actionIcon"
                        (click)="switchToAddMode()"
                        data-cy="edit-section-add-button"
                    >
                        <mat-icon
                            fontSet="fa-duotone"
                            fontIcon="fa-circle-plus"
                            class="fg-primary text-base"
                        ></mat-icon>
                    </button>
                </div>
            }

            @if (isCustomButtonVisible()) {
                <div class="ml-2">
                    <button
                        mat-icon-button
                        type="button"
                        aria-label="Aktion"
                        [matTooltip]="customIconTooltip"
                        id="customButton"
                        class="actionIcon"
                        (click)="triggerCustomEvent()"
                        data-cy="edit-section-custom-button"
                    >
                        <mat-icon
                            fontSet="fa-duotone"
                            [fontIcon]="customIcon()"
                            [ngClass]="customIconClass()"
                        ></mat-icon>
                    </button>
                </div>
            }

            <!-- Aktion zum Löschen -->
            @if (isDeleteButtonVisible()) {
                <div class="ml-2">
                    <button
                        mat-icon-button
                        type="button"
                        aria-label="Löschen"
                        [matTooltip]="heading + ' löschen'"
                        id="deleteButton"
                        class="actionIcon"
                        (click)="onDeleteClicked()"
                        data-cy="edit-section-delete-button"
                    >
                        <mat-icon fontSet="fa-duotone" fontIcon="fa-trash-alt" class="fg-danger text-base"></mat-icon>
                    </button>
                </div>
            }

            <span class="flex-grow"></span>
            <ng-content select="[k5-edit-section-header-addon]"></ng-content>
        </div>
    </k5-section-header>

    @if (isEditMode()) {
        <div class="p-4">
            <ng-content select="[k5-edit-section-write]"></ng-content>
        </div>
    } @else {
        <ng-content select="[k5-edit-section-read]"></ng-content>
    }
</div>
