/**
 * Konstanten für die Fehlerbehandlung
 */
export class ErrorHandlingConstants {

    //#region ProblemResponse Fehlertypen

    static readonly GENERIC_PROBLEM_TYPE = 'about:blank';
    static readonly BUSINESS_PROBLEM_TYPE = 'http://k5.at/business-problem';
    static readonly SECURITY_PROBLEM_TYPE = 'http://k5.at/security-problem';
    static readonly SUBSYSTEM_PROBLEM_TYPE = 'http://k5.at/sub-system-problem';
    static readonly TECHNICAL_PROBLEM_TYPE = 'http://k5.at/technical-problem';

    //#endregion

    //#region Fehlernachrichten

    static readonly DEFAULT_ERROR_MESSAGE = 'Es ist ein Fehler aufgetreten';
    static readonly RETRY_MESSAGE = 'Bitte versuchen Sie es später erneut';
    static readonly RESOURCE_NOT_FOUND = 'Die angeforderte Ressource wurde nicht gefunden';
    static readonly INTERNAL_SERVER_ERROR = 'Es ist ein interner Serverfehler aufgetreten';
    static readonly FORBIDDEN_ERROR = 'Der Zugriff wurde verweigert';
    static readonly SERVER_UNAVAILABLE_ERROR = 'Der Server konnte nicht erreicht werden';

    //#endregion
}
