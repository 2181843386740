import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
 * Component for displaying a FAB right bottom
 */
@Component({
    selector: 'k5-fab-right-bottom',
    templateUrl: './fab-right-bottom.component.html',
    styleUrls: ['./fab-right-bottom.component.scss']
})
export class FabRightBottomComponent {
    @Input()
    tooltip = '';

    @Input()
    icon: string | null = null;

    @Output()
    actionClick: EventEmitter<Event> = new EventEmitter<Event>();

    /**
     * Handles a click on the FAB
     * @param event ClickEvent
     */
    fabClicked(event: Event): void {
        this.actionClick.emit(event);
    }
}
