// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'k5-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnDestroy, OnInit, OnChanges {
    @Output()
    inputChanged: EventEmitter<string>;

    @Output()
    toggleOptions: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    searchInput: string;

    @Input()
    centerSearch: boolean = false;

    @Input()
    debounceTime: number = 0;

    @Input()
    detailActionTooltip: string = '';

    @Input()
    optionsShown: boolean;

    @Input()
    showOptionButton: boolean = false;

    subscriptions = new Subscription();

    searchFormControl: FormControl<string> = new FormControl();

    constructor() {
        this.inputChanged = new EventEmitter<string>();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Setzt den Input, welcher von außen kommt
     * @param changes changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchInput']) {
            this.searchFormControl.setValue(changes['searchInput'].currentValue);
        }
    }

    /**
     * Emittet den input Wert
     */
    ngOnInit(): void {
        this.subscriptions.add(
            this.searchFormControl.valueChanges
                .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
                .subscribe((input: string) => {
                    this.inputChanged.emit(input);
                })
        );
    }

    /**
     * Emmitiert ein Event, wenn die Optionen ein/ausgeblendet werden sollen
     */
    emitToggleOptions(): void {
        this.toggleOptions.emit(this.optionsShown);
    }

    /**
     * Ermöglicht es den Wert des Suchfeldes von außen zu setzen
     * @param value Neuer Wert des Suchfeldes
     * @remarks Der `inputChanged` EventEmitter wird nicht getriggert
     */
    setSearchValue(value: string): void {
        this.searchFormControl.setValue(value, { emitEvent: false });
    }
}
